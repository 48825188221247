import { translate } from 'vue-gettext';

const {
  pgettext: $pgettext,
  gettextInterpolate: $gettextInterpolate,
} = translate;
const requiredMsg = $pgettext('Validators', 'Required field');

class ValidatorFactory {
  static min(minLength) {
    return (v) => {
      if (v && v.length >= minLength) {
        return true;
      }

      return $gettextInterpolate(
        $pgettext('Validators', 'At least %{ minLength } symbols'),
        { minLength },
      );
    };
  }

  static required(v) {
    return !!v || requiredMsg;
  }

  static requiredArray = [v => (v || '').length > 0 || requiredMsg];

  static email(v) {
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (pattern.test(v)) {
      return true;
    }

    return $pgettext('Validators', 'Email address is invalid');
  }

  static getValidators(validators) {
    const collection = validators
      .reduce((acc, validator) => ({
        ...acc,
        [validator]: ValidatorFactory[validator],
      }), {});

    return collection;
  }

  static passwordConfirm(pw, pwConfirm) {
    if (pw === pwConfirm) {
      return true;
    }

    return $pgettext('Validators', 'Passwords do not match');
  }
}

const getValidators = (...validators) => {
  const validatorCollection = ValidatorFactory.getValidators(validators);

  return {
    validators: {
      ...validatorCollection,
    },
  };
};

export default getValidators;
