import Vue from 'vue';
import Shepherd from 'shepherd.js';

const shepherd = (__Vue__) => {
  __Vue__.prototype.$shepherd = (...args) => { // eslint-disable-line
    return new Shepherd.Tour(...args);
  };
};

Vue.use(shepherd);
