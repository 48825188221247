<template>
  <v-chip
    class="update-indicator"
    :disabled="refreshing"
    :loading="refreshing"
    @click="refresh"
    color="backgroundAccent"
    >

    <span v-if="isMobile" class="text-caption" id="update-text" v-translate="{ timer: $dayjs.duration(timeToUpdate / 10 / 60, 'minutes').humanize() }">
      Click to update Mestamaster (auto-update in %{ timer })
    </span>
    <span v-else class="text-caption" id="update-text" v-translate="{ timer: $dayjs.duration(timeToUpdate / 10 / 60, 'minutes').humanize() }">
      Update available - Click to update now (auto-update in %{ timer })
    </span>

    <v-progress-circular
          :value="timeToUpdate"
          size="20"
          class="caption ms-2"
          color="success"
        >
    </v-progress-circular>
  </v-chip>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    data: () => ({
      refreshing: false,
      timeToUpdate: 10000,
      timeToUpdateInterval: null,
    }),

    computed: {
      // TODO - isolate these as props so this component is
      // not dependent on the global state
      ...mapGetters({
        reg: 'sw/reg',
        isMobile: 'utils/isMobile',
      }),
    },

    mounted() {
      this.timeToUpdateInterval = setInterval(() => {
        if (this.timeToUpdate > 0) {
          this.timeToUpdate -= 10;
        } else {
          clearInterval(this.timeToUpdateInterval);
          this.refresh();
        }
      }, 1000);
    },

    methods: {
      refresh() {
        this.refreshing = true;

        if (this.reg.waiting && this.reg.waiting.postMessage) {
          /**
           * Reload the page only after the new service worker is activated.
           */
          this.reg.waiting.addEventListener('statechange', function onStateChange() {
            if (this.state === 'activated') window.location.reload();
          });

          /**
           * Starts to install the new service worker.
           */
          this.reg.waiting.postMessage({ type: 'SKIP_WAITING' });
        } else {
          /**
           * If this.reg.waiting is null/undefined, it's probably another
           * tab that has old state. The page can be reloaded safely.
           */
          window.location.reload();
        }
      },
    },
  };
</script>

<style lang="scss">
  .update-indicator {
    z-index: 5;
  }
</style>