import Vue from 'vue';
import { getEnv } from '../../utils/environment';

const initialState = () => ({
  showWorkloadSidePanel: false,
  isMobile: false,
});

const getters = {
  showWorkloadSidePanel: s => s.showWorkloadSidePanel,
  isMobile: state => state.isMobile,
  isDevEnv: () => getEnv('VUE_APP_ENV')=== 'development',
  isTestEnv: () => getEnv('VUE_APP_ENV')=== 'test',
  isDemoEnv: () => getEnv('VUE_APP_ENV')=== 'demo',
  isProductionEnv: () => getEnv('VUE_APP_ENV')=== 'production',
};

const actions = {
  toggleWorkloadSidePanel({ commit }, payload) {
    commit('TOGGLE_WORKLOAD_SIDE_PANEL', payload);
  },

  toggleIsMobile({ commit }, payload) {
    commit('TOGGLE_IS_MOBILE', payload);
  },
};

const mutations = {
  TOGGLE_WORKLOAD_SIDE_PANEL(state, payload) {
    Vue.set(state, 'showWorkloadSidePanel', payload);
  },

  TOGGLE_IS_MOBILE(state, payload) {
    Vue.set(state, 'isMobile', payload);
  },
};

export default {
  state: initialState(),
  getters,
  actions,
  mutations,
};
