<template>
  <v-card>
    <v-card-title>
      {{ title }}
    </v-card-title>

    <v-card-text
      v-html="bodyText"
    />

    <v-card-actions>
      <v-btn
        block
        color="primary"
        @click="() => onConfirm()"
      >
        {{ confirmText }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    computed: {
      ...mapGetters({
        dialogProps: 'dialog/dialogProps',
      }),

      confirmText() {
        return this.dialogProps.confirmText;
      },

      bodyText() {
        return this.dialogProps.bodyText;
      },

      title() {
        return this.dialogProps.title;
      },

      onConfirm() {
        return this.dialogProps.onConfirm;
      },
    },
  };
</script>
