<template>
  <v-card
    tile
  >
    <v-card-title
      class="d-flex justify-space-between backgroundAccent"
    >
      <div
        v-translate
        translate-context="MCheckupDialog component content"
      >
        Check up on task
      </div>

      <v-btn
        icon
        :disabled="$wait.is('updating todo')"
        @click="closeDialog"
      >
        <v-icon>
          close
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text
      class="pt-4 px-0"
    >
      <v-subheader>
        <span
          v-translate="{
            address: todo.address,
            task: todo.taskName,
            dotSeparator: '&#x2022;'
          }"
          translate-context="MCheckupDialog component content"
          render-html="true"
        >
          You are about to check up on task %{ address } %{ dotSeparator } %{ task }
        </span>
      </v-subheader>

      <div
        v-if="$wait.is('loading checkups')"
        class="d-flex justify-center mt-4"
      >
        <v-progress-circular
          indeterminate
          :width="2"
          :size="20"
        />
      </div>

      <div
        v-else
      >
        <v-list
          dense
          two-line
          class="pb-0"
        >
          <v-list-item
            v-for="(checkup, i) in checkups"
            :key="i"
            :style="{
              pointerEvents: $wait.is(`updating checkup - ${checkup.checkupItemId}`)
                ? 'none' : 'auto',
              opacity: $wait.is(`updating checkup - ${checkup.checkupItemId}`) ? 0.5 : 1
            }"
            style="border-bottom: 1px solid rgba(0, 0, 0, 0.1);"
            @click="() => onToggle(checkup)"
          >
            <div
              v-if="$wait.is(`updating checkup - ${checkup.checkupItemId}`)"
              :style="{
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
              }"
              class="d-flex align-center justify-end mr-2"
            >
              <v-progress-circular
                indeterminate
                :width="2"
                :size="16"
              />
            </div>

            <v-list-item-action
              class="my-0 py-0 mr-4"
            >
              <v-checkbox
                :input-value="!!checkup.completedAt"
                :disabled="$wait.is(`updating checkup - ${checkup.checkupItemId}`)"
              />
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title
                class="d-flex justify-space-between align-center"
                style="white-space: normal;"
              >
                <div>
                  {{ checkup.title }}
                </div>

                <div
                  v-if="checkup.completedAt"
                  class="caption-xs text-right"
                  style="min-width: 100px;"
                >
                  <div
                    v-translate
                    translate-context="MCheckupDialog component content"
                    class="text-uppercase"
                  >
                    Checked up by
                  </div>
                  <div
                    class="text--secondary"
                  >
                    {{ authorFullName(checkup.authorId) }}
                    <br>
                    {{ $dayjs(checkup.completedAt).format('DD.MM.YYYY @ HH:mm') }}
                  </div>
                </div>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
    </v-card-text>

    <v-card-actions
      class="backgroundAccent lighten-2"
    >
      <v-spacer />

      <v-btn
        :loading="$wait.is('updating todo')"
        :disabled="readyButtonDisabled"
        :dark="darkReadyButton"
        color="secondary"
        class="px-8"
        @click="onComplete"
      >
        {{ readyButtonText }}

        <v-icon
          v-if="checkupsReady"
          right
          small
        >
          thumb_up
        </v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import { mapWaitingActions } from 'vue-wait';

  export default {
    computed: {
      ...mapGetters({
        dialogProps: 'dialog/dialogProps',
        checkups: 'project/checkups/checkups',
        userFullName: 'users/userFullName',
      }),

      todo() {
        return this.dialogProps.todo;
      },

      checkupsReady() {
        return this.checkups.every(c => c.completedAt !== null);
      },

      readyButtonDisabled() {
        return !this.checkupsReady
          || this.$wait.is('updating todo')
          || this.$wait.is('loading checkups');
      },

      darkReadyButton() {
        return this.checkupsReady
          && !this.$wait.is('updating todo')
          && !this.$wait.is('loading checkups');
      },

      readyButtonText() {
        if (this.checkupsReady) {
          return this.$pgettext('MCheckupDialog component content', 'Done');
        }

        return this.$pgettext('MCheckupDialog component content', 'Finish checkup first');
      },
    },

    mounted() {
      this.loadCheckups({
        todoId: this.todo.id,
        taskId: this.todo.taskId,
      });
    },

    methods: {
      ...mapActions({
        closeDialog: 'dialog/closeDialog',
      }),

      ...mapWaitingActions('project/checkups', {
        loadCheckups: 'loading checkups',
        completeCheckup: 'processing checkup',
        uncompleteCheckup: 'processing checkup',
      }),

      ...mapWaitingActions('project/todos', {
        completeAssignment: 'updating todo',
      }),

      async onToggle(checkup) {
        this.$wait.start(`updating checkup - ${checkup.checkupItemId}`);

        if (checkup.completedAt) {
          await this.uncompleteCheckup({
            checkupEntryId: checkup.checkupEntryId,
          });
        } else {
          await this.completeCheckup({
            checkupItemId: checkup.checkupItemId,
            todoId: this.todo.id,
          });
        }

        this.$wait.end(`updating checkup - ${checkup.checkupItemId}`);
      },

      async onComplete() {
        await this.completeAssignment({
          id: this.todo.id,
        });

        this.closeDialog();
      },

      authorFullName(userId) {
        return this.userFullName(userId);
      },
    },
  };
</script>
