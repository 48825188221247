<template>
  <v-card
    tile
    class="backgroundAccent lighten-4 d-flex flex-column"
  >
    <div
      class="text-center pa-2 white elevation-1"
    >
      <v-icon
        color="error"
      >
        warning
      </v-icon>

      <p
        v-translate
        translate-context="MInterruptTodoDialog component title"
        class="mt-1 mb-0 subtitle-1 error--text"
      >
        Interrupt task?
      </p>
    </div>

    <v-card-text
      class="pt-2"
    >
      <v-subheader
        v-translate
        translate-context="MInterruptTodoDialog component content"
        class="pl-0"
      >
        Choose cause for interruption
      </v-subheader>

      <m-loader-circular
        v-if="$wait.is('loading reasons')"
        :size="18"
        :width="2"
        text=""
        color="backgroundAccent"
        class="pl-4"
      />

      <template
        v-else
      >
        <v-radio-group
          v-model="selectedInterruptionReason"
          class="mt-0"
          hide-details
          color="success"
        >
          <v-radio
            v-for="(reason, i) in interruptionReasons"
            :key="i"
            :value="reason.key"
            class="mb-1"
            color="primary"
          >
            <template
              v-slot:label
            >
              <span
                class="caption"
              >
                {{ reason.humanReadableReason }}
              </span>
            </template>
          </v-radio>
        </v-radio-group>

        <v-textarea
          v-model="comment"
          outlined
          :label="commentPlaceholder"
          persistent-hint
          auto-grow
          rows="1"
          class="mt-4"
          :hint="commentHint"
        />
      </template>
    </v-card-text>

    <v-spacer />

    <v-card-actions
      class="white elevation-6"
    >
      <v-spacer />

      <v-btn
        :disabled="$wait.is('creating interruption')"
        text
        class="px-10"
        @click="closeDialog"
      >
        <span
          v-translate
          translate-context="MInterruptTodoDialog component close button"
        >
          Close
        </span>
      </v-btn>

      <v-btn
        :disabled="$wait.is('creating interruption') || !selectedInterruptionReason"
        :loading="$wait.is('creating interruption')"
        color="error"
        class="px-10"
        @click="confirm"
      >
        <span
          v-translate
          translate-context="MInterruptTodoDialog component interrupt button"
        >
          Interrupt
        </span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import { mapWaitingActions } from 'vue-wait';

  export default {
    data: () => ({
      selectedInterruptionReason: null,
      comment: null,
    }),

    computed: {
      ...mapGetters({
        dialogProps: 'dialog/dialogProps',
        interruptionReasons: 'project/interruptions/interruptionReasons',
      }),

      todo() {
        return this.dialogProps.todo;
      },

      onClose() {
        return this.dialogProps.onClose;
      },

      commentPlaceholder() {
        return this.$pgettext('MInterruptTodoDialog component comment placeholder', 'Leave a comment (optional)');
      },

      commentHint() {
        return this.$pgettext('MInterruptTodoDialog component comment hint', 'A comment will aid the foreman handle the interruption faster');
      },
    },

    created() {
      this.loadInterruptionReasons();
    },

    methods: {
      ...mapActions({
        closeDialog: 'dialog/closeDialog',
        interruptAssignment: 'project/todos/interruptAssignment',
      }),

      ...mapWaitingActions('project/interruptions', {
        loadInterruptionReasons: 'loading reasons',
      }),

      async confirm() {
        this.$wait.start('creating interruption');

        const params = {
          id: this.todo.id,
          payload: {
            todo: {
              interruption: {
                comment: this.comment,
                reason: this.selectedInterruptionReason,
              },
            },
          },
          prevState: this.todo.state,
        };

        await this.interruptAssignment(params);

        if (this.onClose) await this.onClose();

        this.closeDialog();
        this.$wait.end('creating interruption');
      },
    },
  };
</script>
