import Vue from 'vue';
import { translate } from 'vue-gettext';
import {
  appendItemToArraySortByKey,
} from '@/vuex/utils/helpers';
import api from '../../utils/api';

const { pgettext: $pgettext } = translate;

const initialState = () => ({
  tasks: [],
});

const getters = {
  tasks: state => state.tasks,
  taskById: (_, allGetters) => taskId => allGetters.tasks.find(t => t.id === taskId),
};

const actions = {
  async loadTasks({ commit }, { passageId } = { passageId: null }) {
    try {
      let url = `projects/${this.$projectId}/tasks?`;
      if (passageId) url += `passage_id=${passageId}`;
      const r = await api.get(url);

      commit('SET_TASKS', r);
    } catch (e) {}
  },

  async createTask({ commit, dispatch }, payload) {
    try {
      const r = await api.post(`projects/${this.$projectId}/tasks`, payload);

      commit('ADD_TO_TASKS', r);

      await dispatch('snackbar/addNotification', {
        msg: $pgettext('Snackbar notification', 'Task saved'),
        type: 'success',
      }, { root: true });
    } catch (e) {
      await dispatch('snackbar/addNotification', {
        msg: $pgettext('Snackbar notification', 'Saving failed'),
        type: 'error',
      }, { root: true });
    }
  },

  async updateTask({ commit, dispatch }, { id, payload, showNotification = false }) {
    try {
      const r = await api.update(`projects/${this.$projectId}/tasks/${id}`, payload);

      commit('UPDATE_TASK', r);

      if (showNotification) {
        await dispatch('snackbar/addNotification', {
          msg: $pgettext('Snackbar notification', 'Task saved'),
          type: 'success',
        }, { root: true });
      }
    } catch (e) {
      await dispatch('snackbar/addNotification', {
        msg: $pgettext('Snackbar notification', 'Saving failed'),
        type: 'error',
      }, { root: true });
    }
  },

  async batchUpdateTasks({ commit, dispatch }, payload) {
    try {
      const r = await api.post(`projects/${this.$projectId}/tasks/batch_update`, payload);

      commit('UPDATE_TASKS', r);
    } catch (e) {
      await dispatch('snackbar/addNotification', {
        msg: $pgettext('Snackbar notification', 'Saving failed'),
        type: 'error',
      }, { root: true });
    }
  },

  async destroyTask({ commit, dispatch }, { id }) {
    try {
      const r = await api.delete(`projects/${this.$projectId}/tasks/${id}`);

      commit('DESTROY_TASK', r);

      await dispatch('snackbar/addNotification', {
        msg: $pgettext('Snackbar notification', 'Task deleted'),
        type: 'success',
      }, { root: true });
    } catch (e) {}
  },

  async recalculateTasksYPositions({ commit, dispatch }) {
    try {
      const r = await api.post(`projects/${this.$projectId}/tasks/recalculate_y_positions`);

      commit('UPDATE_TASKS', r);
    } catch (e) {
      await dispatch('snackbar/addNotification', {
        msg: $pgettext('Snackbar notification', 'Saving failed'),
        type: 'error',
      }, { root: true });
    }
  },
};

const mutations = {
  SET_TASKS(state, { data }) {
    Vue.set(state, 'tasks', data);
  },

  ADD_TO_TASKS(state, { data }) {
    const tasks = appendItemToArraySortByKey({
      inputArray: state.tasks,
      item: data,
      key: 'order',
    });

    Vue.set(state, 'tasks', tasks);
  },

  UPDATE_TASK(state, { data }) {
    const tasks = state.tasks.map(t => (
      t.id === data.id ? data : t
    ));

    Vue.set(state, 'tasks', tasks);
  },

  UPDATE_TASKS(state, { data: newTasks }) {
    newTasks.forEach((newTask) => {
      const i = state.tasks.findIndex(t => t.id === newTask.id);

      if (i < 0) return;

      Vue.set(state.tasks, i, newTask);
    });
  },

  DESTROY_TASK(state, { data }) {
    const index = state.tasks.findIndex(t => t.id === data.id);

    if (index < 0) return;

    state.tasks.splice(index, 1);
  },
};

export default {
  state: initialState(),
  getters,
  actions,
  mutations,
};
