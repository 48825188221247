import Vue from 'vue';
import Vuex from 'vuex';
import mixpanel from 'mixpanel-browser';
import { getEnv } from '../utils/environment';
class MixpanelConfig {
  static getConfig() {
   return {
     api_host: 'https://api-eu.mixpanel.com',
     secure_cookie: true,
   };
  }

  static getToken() {
    const isProduction = getEnv('VUE_APP_ENV') === 'production';

    if (isProduction) return '578495efc1a1a341d9cbfff8e040d63d';
    return '9a8c3dceaca8e651a10d9170d3cbc19e';
  }
}

class MixPanelAbstract {
  constructor(mixPanel) {
    if (new.target === MixPanelAbstract) {
      throw new TypeError('Cannot construct MixPanelAbstract instances directly');
    }

    /**
     * Checks that the child instance implements all the required methods.
     */
    const proto = Object.getPrototypeOf(this);
    const superProto = MixPanelAbstract.prototype;
    const missing = Object.getOwnPropertyNames(superProto).find(name => (
      typeof superProto[name] === 'function' && !proto.hasOwnProperty(name) // eslint-disable-line
    )); if (missing) throw new TypeError(`${this.constructor.name} needs to implement ${missing}`);

    this.mixpanel = mixPanel;
  }

  set mixpanel(instance) {
    this._mixpanel = instance;
  }

  get mixpanel() {
    return this._mixpanel;
  }

  reset() {
    this.mixpanel.reset();
    throw new TypeError('Do not call abstract method reset from child.');
  }

  trackEvent(eventName, eventParams = {}) { // eslint-disable-line
    throw new TypeError('Do not call abstract method trackEvent from child.');
  }

  identifyUser(userId) { // eslint-disable-line
    throw new TypeError('Do not call abstract method identifyUser from child.');
  }
}

class MixPanelNoOpTracker extends MixPanelAbstract {
  reset() {} // eslint-disable-line
  trackEvent(eventName, eventParams = {}) {} // eslint-disable-line
  identifyUser(userId) {} // eslint-disable-line
}

class MixPanelTracker extends MixPanelAbstract {
  constructor() {
    mixpanel.init(MixpanelConfig.getToken(), MixpanelConfig.getConfig());
    super(mixpanel);
  }

  reset() {
    this.mixpanel.reset();
  }

  trackEvent(eventName, eventParams = {}) {
    this.mixpanel.track(eventName, eventParams);
  }

  identifyUser(userId) {
    this.mixpanel.identify(userId);
  }
}

class MixPanelFactory {
  set tracker(tracker) {
    this._tracker = tracker;
  }

  get tracker() {
    return this._tracker;
  }

  get initialized() {
    return !!this.tracker;
  }

  /**
   * If there's a previous tracker initialized, reset
   * its state to start things with a clean slate.
   */
  reset() {
    if (this.initialized) this.tracker.reset();
  }

  /**
   * Allows using this.$mixpanel.[method] calls
   * inside Vue components and Vuex store.
   */
  setAsVuePlugin() {
    Vue.prototype.$mixpanel = this.tracker;
    Vuex.Store.prototype.$mixpanel = this.tracker;
  }

  optIn(userId) {
    this.reset();

    this.tracker = new MixPanelTracker();
    this.tracker.identifyUser(userId);
    this.setAsVuePlugin();
  }

  optOut() {
    this.reset();

    this.tracker = new MixPanelNoOpTracker();
    this.setAsVuePlugin();
  }
}

let factory;

export default (trackingConsent = false, userId = null) => {
  if (!factory || factory.initialized) {
    factory = new MixPanelFactory();
  }

  if (trackingConsent) {
    factory.optIn(userId);
  } else {
    factory.optOut();
  }
};
