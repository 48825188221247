import Vue from 'vue';
import { translate } from 'vue-gettext';
import {
  appendItemToArraySortByKey,
} from '@/vuex/utils/helpers';
import api from '../../utils/api';

const { pgettext: $pgettext } = translate;

const initialState = () => ({
  teams: [],
});

const getters = {
  teams: state => state.teams,
  teamById: (_, allGetters) => teamId => allGetters.teams.find(t => t.id === teamId),
};

const actions = {
  async loadTeams({ commit }) {
    try {
      const r = await api.get(`projects/${this.$projectId}/teams`);

      commit('SET_TEAMS', r);
    } catch (e) {}
  },

  async createTeam({ commit, dispatch, getters: allGetters }, payload) {
    let nextOrder = 0;

    if (allGetters.teams.length > 0) {
      nextOrder = allGetters
        .teams
        .reduce((maxOrder, team) => Math.max(maxOrder, team.order), 0) + 1;
    }

    const params = {
      team: {
        ...payload.team,
        order: nextOrder,
      },
    };

    try {
      const r = await api.post(`projects/${this.$projectId}/teams`, params);

      commit('ADD_TO_TEAMS', r);

      await dispatch('snackbar/addNotification', {
        msg: $pgettext('Snackbar notification', 'Team saved'),
        type: 'success',
      }, { root: true });
    } catch (e) {
      await dispatch('snackbar/addNotification', {
        msg: $pgettext('Snackbar notification', 'Saving failed'),
        type: 'error',
      }, { root: true });
    }
  },

  async updateTeam({ commit, dispatch }, { id, payload }) {
    try {
      const r = await api.update(`projects/${this.$projectId}/teams/${id}`, payload);

      commit('UPDATE_TEAMS', r);

      await dispatch('snackbar/addNotification', {
        msg: $pgettext('Snackbar notification', 'Team saved'),
        type: 'success',
      }, { root: true });
    } catch (e) {
      await dispatch('snackbar/addNotification', {
        msg: $pgettext('Snackbar notification', 'Saving failed'),
        type: 'error',
      }, { root: true });
    }
  },

  async batchUpdateTeams({ commit, dispatch }, payload) {
    try {
      const r = await api.post(`projects/${this.$projectId}/teams/batch_update`, payload);

      commit('UPDATE_TEAMS', r);
    } catch (e) {
      await dispatch('snackbar/addNotification', {
        msg: $pgettext('Snackbar notification', 'Saving failed'),
        type: 'error',
      }, { root: true });
    }
  },

  async destroyTeam({ commit, dispatch }, { id }) {
    try {
      const r = await api.delete(`projects/${this.$projectId}/teams/${id}`);

      commit('DESTROY_TEAM', r);

      await dispatch('snackbar/addNotification', {
        msg: $pgettext('Snackbar notification', 'Team deleted'),
        type: 'success',
      }, { root: true });
    } catch (e) {}
  },
};

const mutations = {
  SET_TEAMS(state, { data }) {
    Vue.set(state, 'teams', data);
  },

  ADD_TO_TEAMS(state, { data }) {
    const teams = appendItemToArraySortByKey({
      inputArray: state.teams,
      item: data,
      key: 'order',
    });

    Vue.set(state, 'teams', teams);
  },

  UPDATE_TEAMS(state, { data: newTeams }) {
    if (!Array.isArray(newTeams)) newTeams = [newTeams]; // eslint-disable-line

    newTeams.forEach((newTeam) => {
      const i = state.teams.findIndex(t => t.id === newTeam.id);
      if (i < 0) return;
      Vue.set(state.teams, i, newTeam);
    });
  },

  DESTROY_TEAM(state, { data }) {
    const index = state.teams.findIndex(t => t.id === data.id);

    if (index < 0) return;

    state.teams.splice(index, 1);
  },
};

export default {
  state: initialState(),
  getters,
  actions,
  mutations,
};
