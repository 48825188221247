import trackWithMixPanel from '@/lib/mixpanel';

const initStoreWatchers = (store) => {
  /**
   * Sets up Mixpanel tracker
   */
  store.watch(
    getters => ({
      user: getters.currentUser.data,
    }),

    ({ user }) => {
      trackWithMixPanel(user.trackingConsent, user.id);
    },
  );
};

export default initStoreWatchers;
