<template>
  <v-card
    :class="{
      'pa-1': !$vuetify.breakpoint.smAndUp,
      'pa-4': $vuetify.breakpoint.smAndUp,
    }"
  >
    <v-layout
      row
      wrap
      justify-center
      align-center
      class="text-center pa-2"
    >
      <v-flex
        xs12
        d-flex
        justify-center
      >
        <v-icon
          :color="iconColor"
          size="120"
          class="pt-2"
        >
          {{ icon }}
        </v-icon>
      </v-flex>
      <v-flex
        xs12
        d-flex
        justify-center
      >
        <span class="headline my-4">
          {{ prompt }}
        </span>
      </v-flex>
      <v-flex
        xs12
        d-flex
        justify-center
      >
        <component
          :is="bodyComponent"
        />
      </v-flex>
    </v-layout>
    <v-card-actions
      :class="bodyComponent ? 'mt-4' : ''"
      class="d-flex justify-center"
    >
      <v-btn
        :disabled="loading"
        text
        large
        @click="_cancel"
      >
        {{ cancelButtonText }}
      </v-btn>
      <v-btn
        :disabled="loading"
        :loading="loading"
        :color="iconColor"
        large
        @click="_confirm"
      >
        {{ confirmButtonText }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import warnings from './warnings';

  export default {
    components: {
      ...warnings,
    },

    data: () => ({
      loading: false,
    }),

    computed: {
      ...mapGetters({
        dialogProps: 'dialog/dialogProps',
      }),

      bodyComponent() {
        return this.dialogProps.bodyComponent || '';
      },

      confirmButtonText() {
        return this.dialogProps.confirmButtonText || this.$pgettext('MConfirmBaseDialog component confirm button', 'Yes');
      },

      cancelButtonText() {
        return this.dialogProps.cancelButtonText || this.$pgettext('MConfirmBaseDialog component cancel button', 'Cancel');
      },

      cancel() {
        return this.dialogProps.cancel;
      },

      confirm() {
        return this.dialogProps.confirm;
      },

      prompt() {
        return this.dialogProps.prompt || this.$pgettext('MConfirmBaseDialog component confirm button prompt', 'Are you sure?');
      },

      icon() {
        return this.dialogProps.icon || 'warning';
      },

      iconColor() {
        return this.dialogProps.iconColor || 'error';
      },
    },

    methods: {
      ...mapActions({
        closeDialog: 'dialog/closeDialog',
      }),

      _cancel() {
        if (this.cancel) this.cancel();

        this.closeDialog();
      },

      async _confirm() {
        this.loading = true;

        if (this.confirm) {
          await this.confirm();
        }

        this.closeDialog();

        this.loading = false;
      },
    },
  };
</script>
