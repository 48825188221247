<template>
  <v-card>
    <v-card-title>
      <span
        v-translate
        translate-context="MCreateProjectDialog component title"
      >
        Create project
      </span>
    </v-card-title>

    <v-form
      ref="form"
      v-model="formValid"
      @submit.prevent="submit"
    >
      <v-card-text>
        <v-text-field
          v-model="project.pid"
          :label="projectNamePlaceholder"
          filled
          validate-on-blur
          :rules="[validators.required]"
        />

        <v-text-field
          ref="address"
          v-model="project.address"
          filled
          :label="projectAddressPlaceholder"
          validate-on-blur
          :rules="[validators.required]"
        />
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn
          :disabled="$wait.is('creating project')"
          text
          color="error"
          @click="closeDialog"
        >
          <span
            v-translate
            translate-context="MCreateProjectDialog component cancel button"
          >
            Cancel
          </span>
        </v-btn>

        <v-btn
          :disabled="$wait.is('creating project')"
          :loading="$wait.is('creating project')"
          type="submit"
          color="success"
        >
          <span
            v-translate
            translate-context="MCreateProjectDialog component create button"
          >
            Create project
          </span>
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import { mapWaitingActions } from 'vue-wait';
  import getValidators from '@/mixins/validators';

  export default {
    data: () => ({
      formValid: false,
      project: {
        pid: '',
        address: '',
      },
      ...getValidators('required'),
    }),

    computed: {
      ...mapGetters({
        dialogProps: 'dialog/dialogProps',
      }),

      projectNamePlaceholder() {
        return this.$pgettext('MCreateProjectDialog input placeholder', 'Project name or identifier');
      },

      projectAddressPlaceholder() {
        return this.$pgettext('MCreateProjectDialog input placeholder', 'Address');
      },

      companyId() {
        return this.dialogProps.companyId;
      },
    },

    methods: {
      ...mapActions({
        closeDialog: 'dialog/closeDialog',
      }),

      ...mapWaitingActions('project/project', {
        createProject: 'creating project',
      }),

      async submit() {
        await this.$refs.form.validate();

        if (!this.formValid) return;

        const params = {
          companyId: this.companyId,
          payload: {
            project: this.project,
          },
        };

        const project = await this.createProject(params);

        if (!project) return;

        this.closeDialog();

        this.$router.push({
          name: 'project-info',
          params: {
            projectId: project.id,
            versionId: 1,
          },
        });
      },
    },
  };
</script>
