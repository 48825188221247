import Vue from 'vue';
import api from '../../utils/api';

const initialState = () => ({
  notificationSubscriptions: [],
});

const getters = {
  notificationSubscriptions: state => state.notificationSubscriptions,
};

const actions = {
  async loadNotificationSubscriptions({ commit }) {
    try {
      const r = await api.get(`users/${this.$currentUser.id}/notification_subscriptions`);

      commit('SET_NOTIFICATION_SUBSCRIPTIONS', r);
    } catch (e) {} // eslint-disable-line
  },

  async createNotificationSubscription({ commit }, { payload }) {
    try {
      const r = await api.post(`users/${this.$currentUser.id}/notification_subscriptions`, payload);

      commit('ADD_TO_NOTIFICATION_SUBSCRIPTIONS', r);
    } catch (e) {} // eslint-disable-line
  },

  async destroyNotificationSubscription({ commit }, { id }) {
    try {
      const r = await api.delete(`users/${this.$currentUser.id}/notification_subscriptions/${id}`);

      commit('DESTROY_NOTIFICATION_SUBSCRIPTION', r);
    } catch (e) {} // eslint-disable-line
  },
};

const mutations = {
  SET_NOTIFICATION_SUBSCRIPTIONS(state, { data }) {
    Vue.set(state, 'notificationSubscriptions', data);
  },

  ADD_TO_NOTIFICATION_SUBSCRIPTIONS(state, { data }) {
    state.notificationSubscriptions.push(data);
  },

  DESTROY_NOTIFICATION_SUBSCRIPTION(state, { data }) {
    const index = state.notificationSubscriptions.findIndex(t => t.id === data.id);

    if (index < 0) return;

    state.notificationSubscriptions.splice(index, 1);
  },
};

export default {
  state: initialState(),
  getters,
  actions,
  mutations,
};
