import 'whatwg-fetch'; // Polyfill for fetch
import Vue from 'vue';
import VueWait from 'vue-wait';
import './plugins';
import './directives';
import App from './App.vue';
import router from './router';
import store from './vuex';
import init from './initializers';
import vuetify from './plugins/vuetify';
import {
  getCurrentPage,
  saveCurrentPage,
} from './vuex/utils/localStorage';
// Monitoring
import sentry from './plugins/sentry';
// Fonts and icons for offline use
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import 'material-design-icons-iconfont/dist/material-design-icons.css';

Vue.config.productionTip = false;

router.beforeEach(async (to, from, next) => {
  document.title = to.matched
    .filter(r => r.meta.documentTitle)
    .reduce((acc, r) => {
      let title = '';
      if (typeof r.meta.documentTitle === 'function') {
        title = r.meta.documentTitle();
      } else {
        title = r.meta.documentTitle;
      }
      return `${title} | ${acc}`;
    }, 'Mestamaster');

  const {
    isLoginPage,
    rememberLocation
  } = to.meta;

  let authed = store.getters['currentUser/authenticated'];
  const requiresAuth = !to.matched.every(route => route.meta.requiresAuth === false);

  /**
   * Attempt to auth only if we have to.
   */
  if (!authed && (requiresAuth || isLoginPage)) {
    authed = await store.dispatch('currentUser/auth');
  }

  /**
   * (1) If authed and not on a login page, continue normally.
   * (2) If not authed but does not require auth, continue normally.
   */
  if ((authed && !isLoginPage) || (!authed && !requiresAuth)) {
    if(rememberLocation) {
      // Remember this location when we return in an authenticated state.
      saveCurrentPage(to.path);
    }
    next();

    /**
     * If authed and on a login page, redirect to a last saved page or default.
     */
  } else if (authed && isLoginPage) {
    const path = getCurrentPage() || '/projects';
    saveCurrentPage(path);
    next({ path });

    /**
     * Otherwise, redirect to a login page and allow redirect after a successful auth.
     */
  } else {
    await store.dispatch('currentUser/logout');
    next({
      name: 'login',
      query: {
        redirect: to.fullPath,
      },
    });
  }
  // If we have a route with a project ID param, let's set it.
  if (to.params.projectId) {
    await store.dispatch('project/project/setCurrentProjectId', { projectId: to.params.projectId });
  }
});

/**
 * config.json was originally loaded in html.index' head. However, browsers' HTTP cache could
 * serve the app bundle before loading the config.json which caused failures in HTTP requests.
 *
 * This approach loads the config.json before the app is mounted so we can guarantee the config
 * is available when needed.
 *
 * @returns {Promise<any>}
 */
const loadConfig = () => fetch('/config.json')
  .then(response => response.json())
  .then((data) => { window.config = data; });

loadConfig().then(() => {
  /**
   * Inits here
   */
  init({ store });
  sentry.init();

  /**
   * Mount the app
   */
  new Vue({
    router,
    store,
    vuetify,
    wait: new VueWait({
      useVuex: true,
    }),
    render: h => h(App),
  }).$mount('#app');
});
