<template>
  <v-card
    tile
    class="backgroundAccent lighten-4 d-flex flex-column"
  >
    <div
      class="text-center pa-2 white elevation-1"
    >
      <v-icon
        color="warning"
      >
        not_interested
      </v-icon>

      <p
        v-translate
        translate-context="MSkipTodoDialog component title"
        class="mt-1 mb-0 subtitle-1 warning--text"
      >
        Task won't be carried out
      </p>
    </div>

    <v-card-text
      class="pt-4"
    >
      <p
        v-translate="{
          address: todo.address.trim(),
          task: todo.taskName.trim(),
          dotSeparator: '&#x2022;',
          br: '<br>'
        }"
        translate-context="MSkipTodoDialog component content"
        render-html="true"
        class="py-4"
      >
        This action will set the status for task
        <code>%{ address } %{ dotSeparator } %{ task }</code>
        as <span class="px-1 m-state-skipped">Skipped</span>.
        %{ br }
        %{ br }
        You can also leave a comment that will be visible to other users.
        Doing so will help keep your worksite up to date on the status of its tasks.
      </p>

      <v-textarea
        v-model="commentOnSkip"
        outlined
        :label="commentPlaceholder"
        persistent-hint
        auto-grow
        rows="1"
        class="mt-4"
        :hint="commentHint"
      />
    </v-card-text>

    <v-spacer />

    <v-card-actions
      class="white elevation-6"
    >
      <v-spacer />

      <v-btn
        :disabled="$wait.is('skipping')"
        text
        class="px-10"
        @click="closeDialog"
      >
        <span
          v-translate
          translate-context="MSkipTodoDialog component close button"
        >
          Close
        </span>
      </v-btn>

      <v-btn
        :disabled="$wait.is('skipping')"
        :loading="$wait.is('skipping')"
        color="warning"
        class="px-10"
        @click="confirm"
      >
        <span
          v-translate
          translate-context="MSkipTodoDialog component save button"
        >
          Save
        </span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';

  export default {
    data: () => ({
      commentOnSkip: null,
    }),

    computed: {
      ...mapGetters({
        dialogProps: 'dialog/dialogProps',
      }),

      todo() {
        return this.dialogProps.todo;
      },

      onClose() {
        return this.dialogProps.onClose;
      },

      commentPlaceholder() {
        return this.$pgettext('MSkipTodoDialog component comment placeholder', 'Leave a comment (optional)');
      },

      commentHint() {
        return this.$pgettext('MSkipTodoDialog component comment hint', 'A comment will help other users understand the status of this task');
      },
    },

    methods: {
      ...mapActions({
        closeDialog: 'dialog/closeDialog',
        skipAssignment: 'project/todos/skipAssignment',
      }),

      async confirm() {
        this.$wait.start('skipping');

        await this.skipAssignment({
          id: this.todo.id,
          payload: {
            todo: {
              commentOnSkip: this.commentOnSkip,
            },
          },
        });

        if (this.onClose) await this.onClose();

        this.closeDialog();
        this.$wait.end('skipping');
      },
    },
  };
</script>
