import Vue from 'vue';
import api from '../../utils/api';
import apiV3 from '../../utils/apiV3';

const initialState = () => ({
  todos: [],
  showDueTodos: false,
});

const getters = {
  todos: state => state.todos,
  showDueTodos: state => state.showDueTodos,
  todosByTakt: (state) => {
    const grouped = state.todos.reduce((acc, todo) => {
      if (!acc[todo.takt]) acc[todo.takt] = [];

      acc[todo.takt].push(todo);

      return acc;
    }, {});

    return grouped;
  },
  todosBySpace: (state) => {
    const grouped = state.todos.reduce((acc, todo) => {
      if (!acc[todo.spaceId]) acc[todo.spaceId] = [];

      acc[todo.spaceId].push(todo);

      return acc;
    }, {});

    return grouped;
  },
  todosByTaktWagon: (state) => {
    const grouped = state.todos.reduce((acc, todo) => {
      if (!acc[todo.taktWagonId]) acc[todo.taktWagonId] = [];

      acc[todo.taktWagonId].push(todo);

      return acc;
    }, {});

    return grouped;
  },
};

const actions = {
  async loadTodos({ commit }, params = {}) {
    if (!params.columns) {
      /**
       * These are default todos columns. Filtering out rest of
       * the columns may reduce payload size significantly.
       * @type {string[]}
       */
      params.columns = [ // eslint-disable-line
        'id',
        'activeInterruptionComment',
        'activeInterruptionHumanReadableReason',
        'address',
        'comment',
        'commentOnSkip',
        'currentOwnerId',
        'dependingTodo',
        'estimatedDuration',
        'hasCheckupItems',
        'humanReadableState',
        'requiresSupervision',
        'spaceId',
        'startsAt',
        'state',
        'takt',
        'taskId',
        'taskName',
        'teamId',
        'teamName',
        'waitingDuration',
        'taktWagonId'
      ];
    }

    try {
      const r = await api.get(`projects/${this.$projectId}/todos`, params);
      commit('SET_TODOS', r);
    } catch (e) {}
  },

  setShowDueTodos({ commit }, payload) {
    commit('SET_SHOW_DUE_TODOS', payload);
  },

  async updateTodo({ commit }, { id, payload }) {
    try {
      const r = await api.update(`projects/${this.$projectId}/todos/${id}`, payload);

      commit('UPDATE_TODO', r);
    } catch (e) {}
  },

  async startAssignment({ commit }, { id }) {
    try {
      const r = await api.update(`projects/${this.$projectId}/todos/${id}/start`);

      commit('UPDATE_TODO', r);

      this.$gtm.trackTodoStateChange({
        from: 'enabled',
        to: 'started',
      });
    } catch (e) {}
  },

  async cancelAssignment({ commit }, { id }) {
    try {
      const r = await api.update(`projects/${this.$projectId}/todos/${id}/cancel`);

      commit('UPDATE_TODO', r);

      this.$gtm.trackTodoStateChange({
        from: 'started',
        to: 'enabled',
      });
    } catch (e) {}
  },

  async completeAssignment({ commit }, { id }) {
    try {
      const r = await api.update(`projects/${this.$projectId}/todos/${id}/complete`);

      commit('UPDATE_TODO', r);

      this.$gtm.trackTodoStateChange({
        from: 'started',
        to: 'completed',
      });
    } catch (e) {}
  },

  async continueAssignment({ commit }, { id }) {
    try {
      const r = await api.update(`projects/${this.$projectId}/todos/${id}/continue`);

      commit('UPDATE_TODO', r);

      this.$gtm.trackTodoStateChange({
        from: 'completed',
        to: 'started',
      });
    } catch (e) {}
  },

  async skipAssignment({ commit }, { id, payload }) {
    try {
      const r = await api.update(`projects/${this.$projectId}/todos/${id}/skip`, payload);

      commit('UPDATE_TODO', r);

      this.$gtm.trackTodoStateChange({
        from: 'enabled',
        to: 'skipped',
      });
    } catch (e) {}
  },

  async unskipAssignment({ commit }, { id }) {
    try {
      const r = await api.update(`projects/${this.$projectId}/todos/${id}/unskip`);

      commit('UPDATE_TODO', r);

      this.$gtm.trackTodoStateChange({
        from: 'skipped',
        to: 'enabled',
      });
    } catch (e) {}
  },

  async interruptAssignment({ commit }, { id, payload, prevState }) {
    try {
      const r = await api.update(`projects/${this.$projectId}/todos/${id}/interrupt`, payload);

      commit('UPDATE_TODO', r);

      this.$gtm.trackTodoStateChange({
        from: prevState,
        to: 'interrupted',
      });
    } catch (e) {}
  },

  async resolveAssignment({ commit }, { id }) {
    try {
      const r = await api.update(`projects/${this.$projectId}/todos/${id}/resolve`);

      commit('UPDATE_TODO', r);

      this.$gtm.trackTodoStateChange({
        from: 'interrupted',
        to: 'resolved',
      });
    } catch (e) {}
  },

  async destroyAssignment({ commit }, { id, state }) {
    try {
      const r = await api.delete(`projects/${this.$projectId}/todos/${id}`);

      commit('DESTROY_TODO', r);

      this.$gtm.trackTodoStateChange({
        from: state,
        to: 'deleted',
      });
    } catch (e) {}
  },

  async loadTodoComments({ commit }, {todoId}) {
    try {
      const r = await apiV3.get(`todos/${todoId}/comments`);
      commit('ADD_TODO_COMMENTS', {todoId: todoId, data: r.data});
    } catch (e) {}
  },

  async commentTodo({ commit }, { todoId, comment }) {
    try {
      const r = await apiV3.post(`todos/${todoId}/comments`);
      commit('ADD_TODO_COMMENTS', {todoId: id, data: r.data});
    } catch (e) {}
  },
};

const mutations = {
  UPDATE_TODO(state, { data }) {
    const i = state.todos.findIndex(t => t.id === data.id);

    if (i < 0) return;

    Vue.set(state.todos, i, data);
  },

  DESTROY_TODO(state, { data }) {
    const index = state.todos.findIndex(t => t.id === data.id);

    if (index < 0) return;

    state.todos.splice(index, 1);
  },

  SET_TODOS(state, { data }) {
    Vue.set(state, 'todos', data);
  },

  SET_SHOW_DUE_TODOS(state, payload) {
    Vue.set(state, 'showDueTodos', payload);
  },

  ADD_TODO_COMMENTS(state, {todoId, data}) {
    const i = state.todos.findIndex(t => t.id === todoId);
    if (i < 0) return;
    const updatedTodo = state.todos[i]
    if (updatedTodo.comments === undefined) {
      updatedTodo.comments = []
    }
    // updatedTodo.comments.push(...data)
    updatedTodo.comments = data
    Vue.set(state.todos, i, updatedTodo);
  }
};

export default {
  state: initialState(),
  getters,
  actions,
  mutations,
};
