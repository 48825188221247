import { register } from 'register-service-worker';
import initStoreWatchers from './storeWatchers';

const SWHelper = {
  async getWaitingWorker() {
    const registrations = await navigator?.serviceWorker?.getRegistrations() || [];
    const registrationWithWaiting = registrations.find(reg => reg.waiting);
    return registrationWithWaiting?.waiting;
  },

  async skipWaiting() {
    return (await SWHelper.getWaitingWorker())?.postMessage({ type: 'SKIP_WAITING' });
  },
};

const registerSW = ({ store }) => {
  const sw = process.env.NODE_ENV === 'production'
    ? 'service-worker.js' : 'localhost-sw.js';

  // Post skip waiting if we're about to refresh
  window.addEventListener('beforeunload', async () => {
    if (SWHelper.getWaitingWorker()) {
      await SWHelper.skipWaiting();
    }
  });

  register(`${process.env.BASE_URL}${sw}`, {
    async registered(reg) {
      await store.dispatch('sw/registered', { reg });

      /**
       * Checks if there are new deployments. Especially useful for
       * devices that are not reloaded so often, i.e. raspberries.
       */
      const oneHourInMillis = 3600000;
      setInterval(() => {
        console.debug("Checking for updates")
        reg.update();
      }, oneHourInMillis);
    },

    async updated(reg) {
      // New version is available and waiting.
      await store.dispatch('sw/updated', { reg });
    },
  
    async ready (reg) {
      await store.dispatch('sw/ready', { reg });
    }
  });
};

const deferAddToHomescreen = ({ store }) => {
  store.dispatch('ahs/listenToBeforeInstallPromptEvent');
};

const registerOfflineListeners = ({ store }) => {
  window.addEventListener('offline', () => {
    store.dispatch('sw/setOffline', { isOffline: true });
  });

  window.addEventListener('online', () => {
    /**
     * Re-fetch data by doing a full page reload. No need to
     * set the isOffline to the Vuex state because it defaults
     * to false.
     */
    window.location.reload();
  });

  /**
   * Event listener for offline means that the device is actually offline.
   * However, event listener for online cannot be always trusted as in some
   * cases it may return falsely true:
   *
   *  1) The device can connect to a wifi box but cannot connect any further
   *     => navigator.onLine returns true
   *
   *  2) Service worker returns the page from cache
   *     => navigator.onLine returns true
   *
   * To figure out an actual internet connection, we'd need to do an actual
   * HTTP request. Easiest to do it to the frontend server.
   *
   * Improvement suggestion:
   *
   *  1) we could also do a health check to the backend server to make sure
   *     it is alive as well.
   *  2) Do a health check periodically, e.g. every 60 seconds.
   *
   * Known issue:
   *
   *  When hard reloading (Chrome) in offline, and then restoring the internet
   *  connection, the online event is not fired. Not sure what we can do about it?
   */
  const healthCheck = async () => {
    let isOffline = true;

    if (window.navigator.onLine) {
      const url = new URL(window.location.origin);

      // Random value to prevent cached responses
      const randomString = Math.random().toString(36).substring(2, 15);
      url.searchParams.set('rand', randomString);

      try {
        await fetch(url.toString(), { method: 'HEAD' });
        isOffline = false;
      } catch {}
    }

    store.dispatch('sw/setOffline', { isOffline });
  };

  healthCheck(); // No need to await, just let it do its thing
};

export default ({ store }) => {
  registerSW({ store });
  deferAddToHomescreen(({ store }));
  initStoreWatchers(store);
  registerOfflineListeners({ store });
};
