<template>
  <div
    class="d-flex flex-column justify-center align-center mt-12"
  >
    <v-progress-circular
      :size="size"
      :width="width"
      :color="color"
      :indeterminate="indeterminate"
    />
    <span
      v-if="computedText"
      :class="`${textClasses} ${textColor}`"
    >
      {{ computedText }}
    </span>
  </div>
</template>

<script>
  export default {
    props: {
      size: {
        type: Number,
        default: 48,
      },
      width: {
        type: Number,
        default: 3,
      },
      indeterminate: {
        type: Boolean,
        default: true,
      },
      text: {
        type: String,
        default: null,
      },
      color: {
        type: String,
        default: null,
      },
      textColor: {
        type: String,
        default: null,
      },
      textClasses: {
        type: String,
        default: 'mt-6',
      },
    },

    computed: {
      computedText() {
        if (this.text === null) {
          return this.$pgettext('MLoaderCircular component default text', 'Loading content...');
        }

        return this.text;
      },
    },
  };
</script>
