import en from 'vee-validate/dist/locale/en';

/**
 * TODO: These should be translated too
 */

export default {
  messages: en.messages,
  custom: {
    email: {
      email: 'Sähköposti ei ole kelvollinen.',
    },
    password: {
      min: (e, n) => `Salasanan tulee olla vähintään ${n[0]} merkki${n[0] === 1 ? '' : 'ä'}.`,
    },
    estimatedDuration: {
      min_value: (e, n) => `Arvioidun työajan tulee olla vähintään ${n[0]} tunti${n[0] === 1 ? '' : 'a'}.`,
    },
    waitingDuration: {
      min_value: (e, n) => `Odotusajan tulee olla vähintään ${n[0]} tunti${n[0] === 1 ? '' : 'a'}.`,
    },
    taktTasks: {
      min_length: (e, n) => `Lisää vähintään ${n[0]} työtehtävä${n[0] === 1 ? '' : 'ä'}.`,
    },
    taktGroup: {
      min_length: (e, n) => `Lisää vähintään ${n[0]} työpaketti${n[0] === 1 ? '' : 'a'}.`,
    },
    teams: {
      min_length: (e, n) => `Lisää vähintään ${n[0]} työryhmä${n[0] === 1 ? '' : 'ä'}.`,
    },
    todos: {
      min_length: (e, n) => `Lisää vähintään ${n[0]} työtehtävä${n[0] === 1 ? '' : 'ä'}.`,
    },
    tacts: {
      min_length: (e, n) => `Lisää vähintään ${n[0]} työpaketti${n[0] === 1 ? '' : 'a'}.`,
    },
    tactGroups: {
      min_length: (e, n) => `Lisää vähintään ${n[0]} läpivienti${n[0] === 1 ? '' : 'ä'}.`,
    },
    'project.tactDuration': {
      min_value: (e, n) => `Tahdin pituuden on oltava vähintään ${n[0]} päiv${n[0] === 1 ? 'ä' : 'ää'}.`,
    },
  },
  attributes: {
    email: 'Sähköposti',
    password: 'Salasana',
    taktName: 'Työpaketin nimi',
    zip: 'Postinumero',

    'project.pid': 'Projektin nimi',
    'project.address': 'Osoite',
    'project.city': 'Kaupunki',

    taktGroup: 'Läpivienti',
    teamName: 'Työryhmän nimi',

    'tact.name': 'Työpaketin nimi',
    'tact.todos': 'Työpaketin tehtävät',

    'todo.estimatedDuration': 'Arvioitu työaika',
    'todo.waitingDuration': 'Odotusaika',
    'todo.teams': 'Työryhmä',
    'todo.name': 'Työtehtävän nimi',

    'task.estimatedDuration': 'Arvioitu työaika',
    'task.waitingDuration': 'Odotusaika',
    'task.name': 'Työtehtävän nimi',

    'tactGroup.tacts': 'Läpiviennin työpaketit',
    'tactGroup.spaces': 'Läpiviennin mestat',

    'user.firstName': 'Etunimi',
    'user.lastName': 'Sukunimi',
    'user.email': 'Sähköposti',
    'user.role': 'Rooli',
    'user.password': 'Salasana',
    'user.passwordConfirmation': 'Salasanan vahvistus',

    'gdpr.gdprConsent': 'Tietosuojaseloste',

    'team.name': 'Työryhmän nimi',

    'space.address': 'Mestan nimi',
    'space.order': 'Järjestysnumero',

    'interruption.interruptionReason': 'Keskeytyksen syy',

    'project.tactDuration': 'Tahdin pituus',

    'workPackage.name': 'Työpaketin nimi',

    'passage.name': 'Läpimenon nimi',
  },
};
