<template>
  <v-card
    tile
  >
    <v-card-title
      class="d-flex justify-space-between backgroundAccent"
    >
      <div>
        <span>
          <span
            v-translate
            translate-context="MProjectMissingDialog title"
          >
            Project missing?
          </span>
        </span>
      </div>

      <v-btn
        icon
        @click="closeDialog"
      >
        <v-icon>
          close
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text
      class="pt-4"
    >
      <div
        v-if="$currentUser.role === 'admin'"
      >
        <span
          v-translate
          translate-context="MProjectMissingDialog when role is admin"
        >
          As admin, you can see all companies and projects. If something is missing,
          call Mestamaster customer support.
        </span>
      </div>

      <div
        v-else-if="$currentUser.role === 'manager'"
      >
        <span
          v-translate
          translate-context="MProjectMissingDialog when role is manager"
        >
          As manager, you can see all projects of the companies you work for.
          If something is missing, call Mestamaster customer support.
        </span>
      </div>

      <div
        v-else
      >
        <span
          v-translate
          translate-context="MProjectMissingDialog when role is non-admin, non-manager"
        >
          You can see only the projects you have been added to. If something is missing,
          talk with your worksite manager, or call Mestamaster customer support.
        </span>
      </div>
    </v-card-text>

    <v-card-actions
      class="backgroundAccent lighten-2"
    >
      <v-spacer />

      <v-btn
        color="secondary"
        class="px-8"
        dark
        @click="closeDialog"
      >
        <span
          v-translate
          translate-context="MProjectMissingDialog acknowledge button"
        >
          Okay
        </span>

        <v-icon
          right
          small
        >
          thumb_up
        </v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import { mapActions } from 'vuex';

  export default {
    methods: {
      ...mapActions({
        closeDialog: 'dialog/closeDialog',
      }),
    },
  };
</script>
