import Vue from 'vue';
import api from '../../utils/api';

const initialState = () => ({
  checkupTemplates: [],
});

const getters = {
  checkupTemplates: s => s.checkupTemplates,
};

const actions = {
  async loadCheckupTemplates({ commit }) {
    try {
      const r = await api.get(`projects/${this.$projectId}/checkup_templates`);
      commit('SET_CHECKUP_TEMPLATES', r);
    } catch (e) {}
  },

  async createCheckupTemplate({ commit }, payload) {
    try {
      const r = await api.post(`projects/${this.$projectId}/checkup_templates`, payload);
      commit('ADD_TO_CHECKUP_TEMPLATES', r);
    } catch (e) {}
  },

  async updateCheckupTemplate({ commit }, { id, payload }) {
    try {
      const r = await api.update(`projects/${this.$projectId}/checkup_templates/${id}`, payload);
      commit('UPDATE_CHECKUP_TEMPLATE', r);
    } catch (e) {}
  },

  async destroyCheckupTemplate({ commit }, { id }) {
    try {
      const r = await api.delete(`projects/${this.$projectId}/checkup_templates/${id}`);
      commit('DESTROY_CHECKUP_TEMPLATE', r);
    } catch (e) {}
  },
};

const mutations = {
  SET_CHECKUP_TEMPLATES(state, { data }) {
    Vue.set(state, 'checkupTemplates', data);
  },

  ADD_TO_CHECKUP_TEMPLATES(state, { data }) {
    state.checkupTemplates.push(data);
  },

  UPDATE_CHECKUP_TEMPLATE(state, { data }) {
    const i = state.checkupTemplates.findIndex(t => t.id === data.id);
    if (i < 0) return;
    Vue.set(state.checkupTemplates, i, data);
  },

  DESTROY_CHECKUP_TEMPLATE(state, { data }) {
    const index = state.checkupTemplates.findIndex(sc => sc.id === data.id);
    if (index < 0) return;
    state.checkupTemplates.splice(index, 1);
  },
};

export default {
  state: initialState(),
  getters,
  actions,
  mutations,
};
