import Vue from 'vue';

export default class Gtm {
  constructor() {
    this.setDataLayer();
  }

  setDataLayer() {
    const dl = window.dataLayer || [];

    window.dataLayer = dl;
    this.__dataLayer__ = dl;
  }

  get dataLayer() {
    return this.__dataLayer__;
  }

  get currentUserId() { // eslint-disable-line
    return Vue.prototype.$currentUser.id;
  }

  get currentProjectId() { // eslint-disable-line
    return Vue.prototype.$projectId;
  }
  // enabled() {
  //   return pluginConfig.enabled
  // }
  //
  // enable(val) {
  //   pluginConfig.enabled = val
  //
  //   if (inBrowser && !!val && !hasScript() && pluginConfig.loadScript) {
  //     loadScript(pluginConfig.id)
  //   }
  // }
  //
  // debugEnabled() {
  //   return pluginConfig.debug
  // }
  //
  // debug(val) {
  //   pluginConfig.debug = val
  // }
  //
  // dataLayer() {
  //   if (inBrowser && pluginConfig.enabled) {
  //     return (window.dataLayer = window.dataLayer || []);
  //   }
  //   return false;
  // }

  // trackView(screenName, path) {
  //   if (inBrowser && pluginConfig.enabled) {
  //     logDebug('Dispatching TrackView', { screenName, path })
  //
  //     let dataLayer = (window.dataLayer = window.dataLayer || [])
  //     dataLayer.push({
  //       event: 'content-view',
  //       'content-name': path,
  //       'content-view-name': screenName
  //     })
  //   }
  // }

  trackUser() {
    const params = {
      userId: this.currentUserId,
    };

    this.dataLayer.push(params);
  }

  trackTodoStateChange({
    from,
    to,
  }) {
    const params = {
      event: 'todo-state-change',
      projectId: this.currentProjectId,
      userId: this.currentUserId,
      from,
      to,
    };

    this.dataLayer.push(params);
  }

  trackEvent({
               event = null,
               category = null,
               action = null,
               label = null,
               value = null,
  } = {}) {
    const params = {
      event: event || 'interaction',
      category,
      action,
      label,
      value,
    };

    this.dataLayer.push(params);
  }
}
