import Vue from 'vue';
import api from '../../utils/api';

const initialState = () => ({
  progressBySpaces: [],
  meta: {
    longestPassageCount: 0,
  },
});

const getters = {
  progressBySpaces: state => state.progressBySpaces,
  meta: state => state.meta,
  longestPassageCount: (_, allGetters) => allGetters.meta.longestPassageCount,
};

const actions = {
  loadProgressBySpaces({ commit }) {
    return api.get(`projects/${this.$projectId}/progress_by_spaces`)
      .then(response => commit('SET_PROGRESS_BY_SPACES', response))
      .catch(() => {});
  },
};

const mutations = {
  SET_PROGRESS_BY_SPACES(state, { data, meta }) {
    Vue.set(state, 'progressBySpaces', data);
    Vue.set(state, 'meta', meta);
  },
};

export default {
  state: initialState(),
  getters,
  actions,
  mutations,
};
