import Vue from 'vue';
import { translate } from 'vue-gettext';
import {
  appendItemToArraySortByKey,
  updateItemsInArraySortByKey,
} from '@/vuex/utils/helpers';
import api from '../../utils/api';

const { pgettext: $pgettext } = translate;

const initialState = () => ({
  spaces: [],
});

const getters = {
  spaces: state => state.spaces,
  spaceById: (_, allGetters) => spaceId => allGetters.spaces.find(s => s.id === spaceId),
};

const actions = {
  async loadSpaces({ commit }, { includes = [] } = { includes: [] }) {
    let endpoint = `projects/${this.$projectId}/spaces`;

    if (includes.length > 0) {
      const arr = includes.join(',');

      endpoint += `?includes=${arr}`;
    }

    try {
      const r = await api.get(endpoint);

      commit('SET_SPACES', r);
    } catch (e) {}
  },

  async createSpace({ commit, getters: allGetters, dispatch }, { payload, includes = [] }) {
    let nextOrder = 0;

    if (allGetters.spaces.length > 0) {
      nextOrder = allGetters.spaces[allGetters.spaces.length - 1].order + 1;
    }

    const params = {
      space: {
        ...payload.space,
        order: nextOrder,
      },
    };

    let endpoint = `projects/${this.$projectId}/spaces`;

    if (includes.length > 0) {
      const arr = includes.join(',');
      endpoint += `?includes=${arr}`;
    }

    try {
      const r = await api.post(endpoint, params);

      commit('ADD_TO_SPACES', r);

      await dispatch('snackbar/addNotification', {
        msg: $pgettext('Snackbar notification', 'Space saved'),
        type: 'success',
      }, { root: true });
    } catch (e) {
      await dispatch('snackbar/addNotification', {
        msg: $pgettext('Snackbar notification', 'Saving failed'),
        type: 'error',
      }, { root: true });
    }
  },

  async updateSpace(
    { commit, dispatch },
    {
      id,
      payload,
      includes = [],
      showNotification = true,
    },
  ) {
    let endpoint = `projects/${this.$projectId}/spaces/${id}`;

    if (includes.length > 0) {
      const arr = includes.join(',');
      endpoint += `?includes=${arr}`;
    }

    try {
      const r = await api.update(endpoint, payload);

      commit('UPDATE_SPACES', r);

      if (showNotification) {
        await dispatch('snackbar/addNotification', {
          msg: $pgettext('Snackbar notification', 'Space saved'),
          type: 'success',
        }, { root: true });
      }

      // The takt structure might have changed, so let's reload it
      dispatch('project/tacts/loadTacts', null, { root: true });
    } catch (e) {
      await dispatch('snackbar/addNotification', {
        msg: $pgettext('Snackbar notification', 'Saving failed'),
        type: 'error',
      }, { root: true });
    }
  },

  async batchUpdateSpaces({ commit, dispatch }, payload) {
    try {
      const r = await api.post(`projects/${this.$projectId}/spaces/batch_update`, payload);
      commit('UPDATE_SPACES', r);
    } catch (e) {
      await dispatch('snackbar/addNotification', {
        msg: $pgettext('Snackbar notification', 'Saving failed'),
        type: 'error',
      }, { root: true });
    }
  },

  async destroySpace({ dispatch }, { id }) {
    try {
      await api.delete(`projects/${this.$projectId}/spaces/${id}`);

      await Promise.all([
        dispatch('loadSpaces'), // Load all spaces on destroy since orders may be changed
        dispatch('snackbar/addNotification', {
          msg: $pgettext('Snackbar notification', 'Space deleted'),
          type: 'success',
        }, { root: true }),
      ]);
    } catch (e) {}
  },
};

const mutations = {
  SET_SPACES(state, { data }) {
    Vue.set(state, 'spaces', data);
  },

  ADD_TO_SPACES(state, { data }) {
    const spaces = appendItemToArraySortByKey({
      inputArray: state.spaces,
      item: data,
      key: 'order',
    });

    Vue.set(state, 'spaces', spaces);
  },

  UPDATE_SPACES(state, { data }) {
    let updatedItems = data;

    /**
     * Allows passing either object or array
     */
    if (!Array.isArray(data)) {
      updatedItems = [data];
    }

    const spaces = updateItemsInArraySortByKey({
      inputArray: state.spaces,
      updatedItems,
      key: 'order',
    });

    Vue.set(state, 'spaces', spaces);
  },
};

export default {
  state: initialState(),
  getters,
  actions,
  mutations,
};
