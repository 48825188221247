import Vue from 'vue';
import apiV3 from '../../utils/apiV3';

const initialState = () => ({
  taktTrains: {},
  taktWagons: {},
});

const getters = {
  taktWagons: state => Object.values(state.taktWagons),
  taktTrains: state => Object.values(state.taktTrains),
  taktTrainsBySpaceId: (state) => {
    const grouped = Object.values(state.taktTrains).reduce((acc, train) => {
      if (!acc[train.spaceId]) acc[train.spaceId] = [];
      acc[train.spaceId].push(train);
      return acc;
    }, {});
    return grouped;
  },
};

const actions = {

  async loadTaktWagons({ commit }) {
    try {
      commit('RESET_TAKT_WAGONS');
      let lastPage = false
      let currentPage = 1
      while (!lastPage) {
        const r = await apiV3.get(`projects/${this.$projectId}/takt/takt_wagons?page=${currentPage}`);
        commit('ADD_TAKT_WAGONS', r);
        lastPage = r.meta.pagination.page === r.meta.pagination.last
        currentPage = r.meta.pagination.next
      }
    } catch (e) {
      console.error(e)
    }
  },

  async loadTaktTrains({ commit }) {
    try {
      commit('RESET_TAKT_TRAINS');
      let lastPage = false
      let currentPage = 1
      while (!lastPage) {
        const r = await apiV3.get(`projects/${this.$projectId}/takt/takt_trains?page=${currentPage}`);
        commit('ADD_TAKT_TRAINS', r);
        lastPage = r.meta.pagination.page === r.meta.pagination.last
        currentPage = r.meta.pagination.next
      }
    } catch (e) {
      console.error(e)
    }
  },
};

const mutations = {
  RESET_TAKT_WAGONS(state) {
    Vue.set(state, 'taktWagons', {});
  },

  ADD_TAKT_WAGONS(state, { data }) {
    var newState = data.reduce((acc, wagon) => {
      acc[wagon.id] = wagon
      return acc
    }, {...state.taktWagons})
    Vue.set(state, 'taktWagons', newState);
  },

  RESET_TAKT_TRAINS(state) {
    Vue.set(state, 'taktTrains', {});
  },

  ADD_TAKT_TRAINS(state, { data }) {
    var newState = data.reduce((acc, train) => {
      acc[train.id] = train
      return acc
    }, {...state.taktTrains})
    Vue.set(state, 'taktTrains', newState);
  },
};

export default {
  state: initialState(),
  getters,
  actions,
  mutations,
};
