import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import { UserVuetifyPreset } from "vuetify";
import en from 'vuetify/es5/locale/en';
import fi from 'vuetify/es5/locale/fi';
import { currentLanguage } from '@/plugins/i18n';
import vuetifyTheme from '../initializers/vuetifyTheme';

// Custom components
import MSnackbar from '../components/MSnackbar';
import MLoaderCircular from '../components/loaders/MLoaderCircular';
import MTip from '../components/MTip';
import MBtnHelp from '../components/MBtnHelp';
import MTipNote from '@/components/MTipNote';
import MTooltip from '@/components/MTooltip';

Vue.component('m-loader-circular', MLoaderCircular);
Vue.component('m-snackbar', MSnackbar);
Vue.component('m-tip', MTip);
Vue.component('m-btn-help', MBtnHelp);
Vue.component('m-tip-note', MTipNote);
Vue.component('m-tooltip', MTooltip);

Vue.use(Vuetify);


export const options = {
  theme: vuetifyTheme,
  icons: {
    iconfont: 'md',
  },
  lang: {
    locales: { fi, en },
    current: currentLanguage().locale,
  },
  breakpoint: {
    mobileBreakpoint: 768,
  },
}

export default new Vuetify(options);
