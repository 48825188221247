<template>
  <v-card>
    <v-card-title>
      <span
        v-translate
        translate-context="MCreateCompanyDialog component title"
      >
        Create company
      </span>
    </v-card-title>

    <v-form
      ref="companyForm"
      v-model="formValid"
      @submit.prevent="submit"
    >
      <v-card-text>
        <v-text-field
          v-model="company.name"
          :label="companyNamePlaceholder"
          filled
          :rules="[validators.required]"
        />
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn
          :disabled="$wait.is('creating company')"
          text
          color="error"
          @click="closeDialog"
        >
          <span
            v-translate
            translate-context="MCreateCompanyDialog component cancel button"
          >
            Cancel
          </span>
        </v-btn>

        <v-btn
          :disabled="$wait.is('creating company')"
          :loading="$wait.is('creating company')"
          type="submit"
          color="success"
        >
          <span
            v-translate
            translate-context="MCreateCompanyDialog component create button"
          >
            Create company
          </span>
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import { mapWaitingActions } from 'vue-wait';
  import getValidators from '@/mixins/validators';

  export default {
    data: () => ({
      formValid: false,
      company: {
        name: '',
      },
      ...getValidators('required'),
    }),

    computed: {
      ...mapGetters({
        dialogProps: 'dialog/dialogProps',
      }),

      companyNamePlaceholder() {
        return this.$pgettext('MCreateCompanyDialog input placeholder', 'Company name');
      },
    },

    methods: {
      ...mapActions({
        closeDialog: 'dialog/closeDialog',
      }),

      ...mapWaitingActions('companies', {
        createCompany: 'creating company',
      }),

      async submit() {
        await this.$refs.companyForm.validate();

        if (!this.formValid) return;

        const params = {
          payload: {
            company: this.company,
          },
        };

        await this.createCompany(params);

        this.closeDialog();
      },
    },
  };
</script>
