import Vue from 'vue';
import api from '../../utils/api';

const initialState = () => ({
  teams: [],
  selectedTeamIds: [],
});

const getters = {
  teams: state => state.teams,
  selectedTeamIds: state => state.selectedTeamIds,
  selectedTeams: (state) => {
    if (state.selectedTeamIds.length <= 0) return state.teams;
    return state.teams.filter(t => state.selectedTeamIds.includes(t.id));
  },
  teamsWithUsers: (s, g, allGetters) => {
    const { teams } = allGetters['project/teams'];
    const { teamsUsers } = allGetters['project/teamsUsers'];
    const { users } = allGetters.users;

    /**
     * If users are already loaded for something else, they can be
     * grouped by the ID, i.e. it's an object of objects. Let's do
     * some light error management here, and return an empty array
     * for those cases.
     *
     * It will fix itself when a new set of users are reloaded,
     * and this getter gets called again.
     */
    if (!Array.isArray(users)) return [];

    const result = teams.map((team) => {
      const userIds = teamsUsers
        .filter(tu => tu.teamId === team.id)
        .map(tu => tu.userId);

      const usersOfTheTeam = users.filter(u => userIds.includes(u.id));

      return {
        ...team,
        usersCount: usersOfTheTeam.length,
        users: usersOfTheTeam,
      };
    });

    return result;
  },
};

const actions = {
  loadTeams({ commit }, { projectId } = { projectId: this.$projectId }) {
    return api.get(`projects/${(projectId)}/teams`)
      .then(response => commit('SET_TEAMS', response))
      .catch(() => {});
  },

  setSelectedTeamIds({ commit }, payload) {
    commit('SET_SELECTED_TEAM_IDS', payload);
  },
};

const mutations = {
  SET_TEAMS(state, { data }) {
    Vue.set(state, 'teams', data);
  },

  SET_SELECTED_TEAM_IDS(state, payload) {
    Vue.set(state, 'selectedTeamIds', payload);
  },
};

export default {
  state: initialState(),
  getters,
  actions,
  mutations,
};
