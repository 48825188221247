const getDefaultConfig = () => ({
  enabled: true,
  debug: false,
  loadScript: true,
  commands: {},
});

let config = { // eslint-disable-line
  ...getDefaultConfig(),
};

export function mergeConfig(additionalConfig) {
  config = {
    ...config,
    ...additionalConfig,
  };

  return config;
}

const getConfig = () => config;

export default getConfig;
