var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',_vm._g(_vm._b({attrs:{"mini-variant":_vm.miniVariant,"value":_vm.drawer,"app":"","touchless":"","color":"backgroundAccent"}},'v-navigation-drawer',_vm.$attrs,false),_vm.$listeners),[_c('v-list',{staticClass:"py-0",attrs:{"dense":""}},[_c('m-project-selector-menu',{attrs:{"project-id":_vm.projectId,"projects":_vm.projects,"show-tooltip":_vm.miniVariant}}),(_vm.projectId)?_vm._l((_vm.navItems),function(navItem,i){return _c('span',{key:i},[(navItem.children().length > 0)?[(navItem.title)?_c('v-subheader',[_vm._v(" "+_vm._s(navItem.title)+" ")]):_vm._e(),_vm._l((navItem.children()),function(item,k){return [_c('v-tooltip',{key:(i + "-" + k),attrs:{"top":"","disabled":!_vm.miniVariant},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"to":item.to,"exact":item.to.exact,"disabled":item.disabled}},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v(" "+_vm._s(item.icon)+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.title)+" ")]),(item.subtitle)?_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(item.subtitle)+" ")]):_vm._e()],1)],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.title)+" ")])])]})]:_vm._e()],2)}):_c('div',[_c('v-subheader',{staticClass:"mt-2"},[_c('span',{directives:[{name:"translate",rawName:"v-translate"}],attrs:{"translate-context":"MNavigationDrawer subtitle"}},[_vm._v(" Management ")])]),_c('v-list-item',{attrs:{"exact":"","to":{
          name: 'projects-list',
        }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(" face ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('span',{directives:[{name:"translate",rawName:"v-translate"}],attrs:{"translate-context":"MNavigationDrawer item title"}},[_vm._v(" Projects ")])])],1)],1),(_vm.$currentUser.isAdmin)?_c('v-list-item',{attrs:{"exact":"","to":{
          name: 'admin-companies',
        }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(" business ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('span',{directives:[{name:"translate",rawName:"v-translate"}],attrs:{"translate-context":"MNavigationDrawer item title"}},[_vm._v(" Companies ")])])],1)],1):_vm._e(),(_vm.$currentUser.enabledFeatures.projectSuccess)?[_c('v-subheader',{staticClass:"mt-2"},[_c('span',{directives:[{name:"translate",rawName:"v-translate"}],attrs:{"translate-context":"MNavigationDrawer subtitle"}},[_vm._v(" Analytics ")])]),_c('v-list-item',{attrs:{"exact":"","two-line":"","to":{
            name: 'customer-success',
          }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(" insights ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('span',{directives:[{name:"translate",rawName:"v-translate"}],attrs:{"translate-context":"MNavigationDrawer link title"}},[_vm._v(" Projects' analytics ")])]),_c('v-list-item-subtitle',[_c('span',{directives:[{name:"translate",rawName:"v-translate"}],attrs:{"translate-context":"MNavigationDrawer link subtitle"}},[_vm._v(" How projects are doing? ")])])],1)],1)]:_vm._e()],2)],2),_c('template',{slot:"append"},[_c('v-divider'),_c('v-list-item',{attrs:{"exact":"","target":"_blank","href":_vm.$pgettext('MNavigationDrawer Google Forms feedback link', 'https://docs.google.com/forms/d/e/1FAIpQLSduaE3QUw7-pc8woREZ1Am3u69ncf69CeLosaft4mZmE3iZvw/viewform')}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(" feedback ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('span',{directives:[{name:"translate",rawName:"v-translate"}],attrs:{"translate-context":"MNavigationDrawer link title to feedback button"}},[_vm._v(" Send feedback ")])]),_c('v-list-item-subtitle',[_c('span',{directives:[{name:"translate",rawName:"v-translate"}],attrs:{"translate-context":"MNavigationDrawer link subtitle to feedback button"}},[_vm._v(" Tell us how to improve! ")])])],1)],1),_c('div',[_c('v-divider'),_c('v-menu',{attrs:{"offset-y":"","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"py-2",attrs:{"width":"100%","height":"100%","text":"","tile":""}},'v-btn',attrs,false),on),[_c('v-badge',{attrs:{"value":_vm.unseenLogs.length > 0
              && _vm.currentUser.permissions.includes('write_notification_logs'),"top":"","right":"","color":"deep-orange","content":_vm.unseenLogs.length,"offset-x":"12","offset-y":"12"}},[_c('v-icon',[_vm._v(" face ")])],1),_c('div',{staticClass:"ml-4 mr-3 text-left"},[_c('div',[_vm._v(" "+_vm._s(_vm.currentUser.firstName)+" ")]),_c('div',{staticClass:"caption-xs backgroundAccent--text"},[_vm._v(" "+_vm._s(_vm.currentUser.humanReadableRole)+" ")])]),_c('v-icon',[_vm._v(" keyboard_arrow_up ")])],1)]}}])},[_c('v-list',{staticClass:"py-4",attrs:{"dense":""}},[_c('v-list-item',{attrs:{"to":{
            name: 'user-settings',
          }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(" face ")])],1),_c('v-list-item-content',[_c('v-list-item-title',{directives:[{name:"translate",rawName:"v-translate"}],attrs:{"translate-context":"Navigation drawer item title"}},[_vm._v(" Account ")])],1)],1),(_vm.currentUser.permissions.includes('write_notification_logs'))?_c('v-list-item',{attrs:{"to":{
            name: 'own-notifications',
          }}},[_c('v-list-item-icon',[_c('v-badge',{attrs:{"value":_vm.unseenLogs.length > 0,"top":"","right":"","color":"deep-orange","content":_vm.unseenLogs.length,"offset-x":"12","offset-y":"12"}},[_c('v-icon',[_vm._v(" announcement ")])],1)],1),_c('v-list-item-content',[_c('v-list-item-title',{directives:[{name:"translate",rawName:"v-translate"}],attrs:{"translate-context":"Navigation drawer item title"}},[_vm._v(" My notifications ")])],1)],1):_vm._e(),_c('v-divider',{staticClass:"my-4"}),_c('v-list-item',{attrs:{"disabled":_vm.$wait.is('logging out'),"color":"error"},on:{"click":_vm.onLogout}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" logout ")])],1),_c('v-list-item-content',{staticClass:"error--text"},[_c('v-list-item-title',{directives:[{name:"translate",rawName:"v-translate"}],attrs:{"translate-context":"Navigation drawer item title"}},[_vm._v(" Sign out ")])],1)],1)],1)],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }