<template>
  <v-card
    tile
  >
    <v-card-title
      class="backgroundAccent lighten-4"
    >
      <span
        v-translate
        translate-context="MRequestInformationDestroyDialog component title"
      >
        Would you like to delete your data?
      </span>

      <v-spacer />

      <v-btn
        :disabled="$wait.is('destroying user')"
        icon
        small
        @click="closeDialog"
      >
        <v-icon small>
          close
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text
      v-translate="{ br: '<br>' }"
      translate-context="MRequestInformationDestroyDialog component content"
      render-html="true"
      class="pt-4"
    >
      In compliance with the EU General Data Protection Regulation (GDPR),
      you have the right to delete your personal data.
      %{ br }%{ br }
      Please discuss with your foreman on alternative ways to direct your work
      if you decide delete your personal data.
      %{ br }%{ br }
      This action cannot be undone.
    </v-card-text>

    <v-card-actions
      class="backgroundAccent lighten-4"
    >
      <v-spacer />

      <v-btn
        :disabled="$wait.is('destroying user')"
        text
        @click="closeDialog"
      >
        <span v-translate>Close</span>
      </v-btn>

      <v-btn
        :disabled="$wait.is('destroying user')"
        :loading="$wait.is('destroying user')"
        color="error"
        class="px-4"
        @click="confirm"
      >
        <span
          v-translate
          translate-context="MRequestInformationDestroyDialog component delete personal data button"
        >
          Delete personal data
        </span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import { mapWaitingActions } from 'vue-wait';

  export default {
    computed: {
      ...mapGetters({
      }),
    },

    methods: {
      ...mapActions({
        openDialog: 'dialog/openDialog',
        closeDialog: 'dialog/closeDialog',
      }),

      ...mapWaitingActions('registration/user', {
        destroyUser: 'destroying user',
      }),

      async confirm() {
        const success = await this.destroyUser();

        if (success) {
          this.openDialog({
            dialogComponent: 'm-acknowledge-dialog',
            dialogProps: {
              confirmText: this.$pgettext('MRequestInformationDestroyDialog component acknowledge dialog confirm', 'OK'),
              bodyText: `
              ${this.$pgettext('MRequestInformationDestroyDialog component acknowledge dialog content [1/2]', 'Your personal data was deleted successfully.')}
              <br><br>
              ${this.$pgettext('MRequestInformationDestroyDialog component acknowledge dialog content [2/2]', "You will now be redirected to Mestamaster's homepage.")}
            `,
              title: this.$pgettext('MRequestInformationDestroyDialog component acknowledge dialog title', 'Personal data deleted'),
              onConfirm: () => {
                window.location = 'https://mestamaster.fi';
              },
            },
          });
        }
      },
    },
  };
</script>
