import api from './api';

// For now just a wrapper that calls API V3 endpoints.
export default {
  get(url, params = {}) {
    return api.get(url, params, 3)
  },
  post(url, data) {
    return api.post(url, data, 3)
  },
  update(url, data = {}) {
    return api.update(url, data, 3)
  },
  delete(url, data = {}) {
    return api.delete(url, data, 3)
  },
  getFile(url) {
    return api.getFile(url, data, 3)
  },
};
