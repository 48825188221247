<template>
  <v-card>
    <v-card-title
      class="pt-6"
    >
      <span
        v-translate
        translate-context="MUpdateAvailableDialog component title"
      >
        Update available
      </span>
    </v-card-title>

    <v-card-text
      class="d-flex"
    >
      <div>
        <span
          v-translate
          translate-context="MUpdateAvailableDialog component content"
        >
          Update will be installed automatically after a while — or you can do it immediatelly too.
        </span>
      </div>

      <div
        style="width: 50%;"
        class="text-right mt-n8 pl-4"
      >
        <v-progress-circular
          :value="timeToUpdate"
          size="76"
          class="caption"
          color="secondary"
          width="10"
        >
          {{ timeToUpdate / 10 }} s
        </v-progress-circular>
      </div>
    </v-card-text>

    <v-card-actions
      class="px-6 pb-6 pt-0"
    >
      <v-btn
        :disabled="refreshing"
        :loading="refreshing"
        depressed
        block
        large
        style="height: 48px;"
        color="success"
        @click="refresh"
      >
        <span
          v-translate
          translate-context="MUpdateAvailableDialog component refresh button"
        >
          Update now
        </span>
        <v-icon
          right
        >
          refresh
        </v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    data: () => ({
      refreshing: false,
      timeToUpdate: 100,
      timeToUpdateInterval: null,
    }),

    computed: {
      ...mapGetters({
        reg: 'sw/reg',
      }),
    },

    mounted() {
      this.timeToUpdateInterval = setInterval(() => {
        if (this.timeToUpdate > 0) {
          this.timeToUpdate -= 10;
        } else {
          clearInterval(this.timeToUpdateInterval);
          this.refresh();
        }
      }, 1000);
    },

    methods: {
      refresh() {
        this.refreshing = true;

        if (this.reg.waiting && this.reg.waiting.postMessage) {
          /**
           * Reload the page only after the new service worker is activated.
           */
          this.reg.waiting.addEventListener('statechange', function onStateChange() {
            if (this.state === 'activated') window.location.reload();
          });

          /**
           * Starts to install the new service worker.
           */
          this.reg.waiting.postMessage({ action: 'skip-waiting' });
        } else {
          /**
           * If this.reg.waiting is null/undefined, it's probably another
           * tab that has old state. The page can be reloaded safely.
           */
          window.location.reload();
        }
      },
    },
  };
</script>
