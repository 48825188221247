import getConfig from './defaultConfig';

export const log = (msg, ...rest) => { // eslint-disable-line
  const config = getConfig();

  if (!config.debug) return;

  console.log('[gtm]', msg); // eslint-disable-line
  console.log('[gtm]', rest); // eslint-disable-line
};

export const loadScript = (id) => {
  if (!id) return;

  const win = window;
  const doc = document;
  const script = doc.createElement('script');
  const dl = 'dataLayer';

  win[dl] = win[dl] || [];

  win[dl].push({
    event: 'gtm.js',
    'gtm.start': new Date().getTime(),
  });

  script.async = true;
  script.setAttribute('rel', 'preconnect');
  script.src = `https://www.googletagmanager.com/gtm.js?id=${id}`;

  doc.body.appendChild(script);
};
