<template>
  <div
    class="full-height"
  >
    <v-app-bar
      v-if="!$vuetify.breakpoint.mobile"
      app
      dense
      dark
      flat
      color="secondary"
      style="margin-right: -16px;"
      class="no-print"
    >
      <v-app-bar-nav-icon
        class="ml-n2 mr-4"
        @click="toggleMenu"
      >
        <div
          class="d-flex flex-column justify-center align-center"
        >
          <div
            class="d-flex"
          >
            <v-icon
              v-if="!drawerClosed"
              small
            >
              menu_open
            </v-icon>

            <v-icon
              v-if="drawerClosed"
              small
            >
              menu
            </v-icon>
          </div>

          <span
            v-translate
            translate-context="MBaseNavigation menu toggle"
            class="caption-xxs mb-2"
          >
            Menu
          </span>
        </div>
      </v-app-bar-nav-icon>

      <v-spacer />
      <!-- Rigth now help is the user manual, but replace me in the future with a link to the knowledge base -->
      <v-btn plain 
          class="mr-4"
          target="_blank"
          href="https://docs.google.com/presentation/d/1V09_rcP1roJYt3TEhYq6FFKcmQKFHMMSCxVXLducC4s/edit?usp=sharing">
        <span v-translate small translate-context="Top bar help button">
          Need help
        </span>
        <v-icon class="ml-1">
          help_outline
        </v-icon>
      </v-btn>

      <m-notification-fab
        v-if="!$vuetify.breakpoint.mobile"
        icon
        overlap
        class="mr-4"
      />
    </v-app-bar>

    <m-navigation-drawer
      v-if="!$vuetify.breakpoint.mobile"
      :project-id="projectId"
      :projects="projects"
      :nav-items="projectNavItems"
      :drawer="drawer"
      :mini-variant="miniVariant"
    />

    <router-view />

    <m-notification-fab
      v-if="$vuetify.breakpoint.mobile"
      fab
      color="secondary"
      bordered
      fixed
      left
      style="bottom: 72px;"
    />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { mapWaitingActions } from 'vue-wait';
  import MBreadcrumbs from '@/components/MBreadcrumbs';
  import MNavigationDrawer from '@/components/MNavigationDrawer';
  import MNotificationFab from '@/components/MNotificationFab';

  export default {
    components: { MNotificationFab, MNavigationDrawer, MBreadcrumbs },

    props: {
      projectId: {
        type: [Number, String],
        default: null,
      },
    },

    data: () => ({
      drawer: null,
      miniVariant: null,
    }),

    computed: {
      ...mapGetters({
        projects: 'projects/projects',
        projectNavItems: 'nav/projectNavItems'
      }),

      renderCollapseExpand() {
        if (this.miniVariant) {
          return this.$pgettext('Base navigation toggle', 'Expand');
        }

        return this.$pgettext('Base navigation toggle', 'Minimize');
      },

      renderHideOpen() {
        if (this.drawer === null || this.drawer === true) {
          return this.$pgettext('Base navigation toggle', 'Hide');
        }

        return this.$pgettext('Base navigation toggle', 'Open');
      },

      drawerOpened() {
        return (this.drawer === null || this.drawer) && !this.miniVariant;
      },

      drawerMiniVariant() {
        return this.drawer && this.miniVariant;
      },

      drawerClosed() {
        return !this.drawerOpened && !this.drawerMiniVariant;
      },
    },

    created() {
      this.loadProjects();
      this.loadCompanies();
    },

    methods: {
      ...mapWaitingActions('projects', {
        loadProjects: 'loading projects',
      }),

      ...mapWaitingActions('companies', {
        loadCompanies: 'loading companies',
      }),

      toggleMinivariant() {
        this.miniVariant = !this.miniVariant;

        this.$mixpanel.trackEvent('Toggle Minivariant', {
          action: this.miniVariant ? 'Mini' : 'Wide',
        });
      },

      toggleSidebarNavigation() {
        this.drawer = this.drawer === null ? false : !this.drawer;

        this.$mixpanel.trackEvent('Toggle Sidebar Navigation', {
          action: this.drawer ? 'Open' : 'Close',
        });
      },

      toggleMenu() {
        if (this.drawerOpened) {
          this.drawer = true;
          this.miniVariant = true;
          return;
        }

        if (this.drawerMiniVariant) {
          this.drawer = false;
          return;
        }

        this.drawer = true;
        this.miniVariant = false;
      },
    },
  };
</script>
