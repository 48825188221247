import Vue from 'vue';

const initialState = () => ({
  component: null,
  props: {},
  isVisible: false,
});

const getters = {
  component: state => state.component,
  props: state => state.props,
  isVisible: state => state.isVisible,
};

const actions = {
  openSidePanel({ commit }, payload) {
    commit('OPEN_SIDE_PANEL', payload);
  },
  closeSidePanel({ commit }) {
    commit('CLOSE_SIDE_PANEL');
  },
};

const mutations = {
  OPEN_SIDE_PANEL(state, { component, props = {} }) {
    Vue.set(state, 'component', component);
    Vue.set(state, 'props', props);
    Vue.set(state, 'isVisible', true);
  },
  CLOSE_SIDE_PANEL(state) {
    Vue.set(state, 'isVisible', false);
    Vue.set(state, 'component', initialState().component);
  },
};

export default {
  state: initialState(),
  getters,
  actions,
  mutations,
};
