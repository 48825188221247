import Vue from 'vue';
import api from '../../utils/api';

const initialState = () => ({
  projectProgressTotal: {
    todosCompletedCount: 0,
    todosTotalCount: 0,
  },
  projectInterruptions: {
    interruptionsTotal: 0,
    activeInterruptions: 0,
    mostCommonInterruptions: [],
  },
  dueTodosProgress: [],
  todosLeftByTeam: [],
});

const getters = {
  projectProgressTotal: state => state.projectProgressTotal,
  projectInterruptions: state => state.projectInterruptions,
  dueTodosProgress: state => state.dueTodosProgress,
  todosLeftByTeam: state => state.todosLeftByTeam,
};

const actions = {
  async loadProjectProgressTotal({ commit }) {
    try {
      const r = await api.get(`projects/${this.$projectId}/project_progress_total`);

      commit('SET_PROJECT_PROGRESS_TOTAL', r);
    } catch (e) {} // eslint-disable-line
  },

  async loadProjectInterruptions({ commit }) {
    try {
      const r = await api.get(`projects/${this.$projectId}/project_interruptions`);

      commit('SET_PROJECT_INTERRUPTIONS', r);
    } catch (e) {} // eslint-disable-line
  },

  async loadDueTodosProgress({ commit }) {
    try {
      const r = await api.get(`projects/${this.$projectId}/due_todos_progress`);

      commit('SET_DUE_TODOS_PROGRESS', r);
    } catch (e) {} // eslint-disable-line
  },

  async loadTodosLeftByTeam({ commit }) {
    try {
      const r = await api.get(`projects/${this.$projectId}/todos_left_by_team`);

      commit('SET_TODOS_LEFT_BY_TEAM', r);
    } catch (e) {} // eslint-disable-line
  },
};

const mutations = {
  SET_PROJECT_PROGRESS_TOTAL(state, { data }) {
    Vue.set(state, 'projectProgressTotal', data);
  },

  SET_PROJECT_INTERRUPTIONS(state, { data }) {
    Vue.set(state, 'projectInterruptions', data);
  },

  SET_DUE_TODOS_PROGRESS(state, { data }) {
    Vue.set(state, 'dueTodosProgress', data);
  },

  SET_TODOS_LEFT_BY_TEAM(state, { data }) {
    Vue.set(state, 'todosLeftByTeam', data);
  },
};

export default {
  state: initialState(),
  getters,
  actions,
  mutations,
};
