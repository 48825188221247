import Vue from 'vue';
import api from '../../utils/api';

const initialState = () => ({
  user: {},
  registrationHash: null,
});

const getters = {
  user: state => state.user,
  registrationHash: state => state.registrationHash,
  pushSubscriptions: (state, allGetters) => {
    const { user } = allGetters;

    if (!('pushSubscriptions' in user)) return [];

    return user.pushSubscriptions;
  },
};

const actions = {
  async validateRegistrationHash({ commit }, { registrationHash }) {
    try {
      const r = await api.get(`registrations/users/${registrationHash}`);

      commit('SET_USER', r);
      commit('SET_REGISTRATION_HASH', { registrationHash });
    } catch (e) {}
  },

  async updateUser({ commit, getters: allGetters }, { payload }) {
    const { registrationHash } = allGetters;

    try {
      const r = await api.update(`registrations/users/${registrationHash}`, payload);
      commit('SET_USER', r);
      return true;
    } catch (e) {
      return false;
    }
  },

  async destroyUser({ commit, getters: allGetters }) {
    const { registrationHash } = allGetters;

    try {
      await api.delete(`registrations/users/${registrationHash}`);
      commit('UNSET_USER');
      return true;
    } catch (e) {
      return false;
    }
  },

  async loginUser({ commit, getters: allGetters, dispatch }) {
    const { registrationHash: h } = allGetters;

    try {
      const r = await api.post('registrations/sessions', { h });

      await dispatch('currentUser/onSuccessfulLogin', r, { root: true });

      commit('UNSET_USER');
    } catch (e) {} // eslint-disable-line
  },
};

const mutations = {
  SET_USER(state, { data }) {
    Vue.set(state, 'user', data);
  },

  SET_REGISTRATION_HASH(state, { registrationHash }) {
    Vue.set(state, 'registrationHash', registrationHash);
  },

  UNSET_USER(state) {
    Vue.set(state, 'user', initialState().user);
  },
};

export default {
  state: initialState(),
  getters,
  actions,
  mutations,
};
