<template>
  <v-card
    tile
    class="backgroundAccent lighten-4 d-flex flex-column"
  >
    <div
      class="text-center pa-2 white elevation-1"
    >
      <v-icon>
        comment
      </v-icon>

      <p
        v-translate
        translate-context="MTodoCommentDialog component title"
        class="mt-1 mb-0 subtitle-1"
      >
        Task details, observations and comments
      </p>
    </div>

    <v-card-text
      class="pt-4"
    >
      <p
        v-translate="{
          address: todo.address.trim(),
          task: todo.taskName.trim(),
          dotSeparator: '&#x2022;',
          br: '<br>'
        }"
        translate-context="MTodoCommentDialog component content"
        render-html="true"
        class="py-4"
      >
        Add details, observations or comments for task
        <code>%{ address } %{ dotSeparator } %{ task }</code>
        %{ br }
        %{ br }
        These will be visible to all users.
        Doing so will help keep your worksite up to date on the status of its tasks.
      </p>

      <v-textarea
        v-model="comment"
        outlined
        :label="commentPlaceholder"
        persistent-hint
        auto-grow
        rows="1"
        class="mt-4"
        :hint="commentHint"
      />
    </v-card-text>

    <v-spacer />

    <v-card-actions
      class="white elevation-6"
    >
      <v-spacer />

      <v-btn
        :disabled="$wait.is('skipping')"
        text
        class="px-10"
        @click="closeDialog"
      >
        <span
          v-translate
          translate-context="MTodoCommentDialog component close button"
        >
          Close
        </span>
      </v-btn>

      <v-btn
        :disabled="$wait.is('skipping')"
        :loading="$wait.is('skipping')"
        color="primary"
        class="px-10"
        @click="confirm"
      >
        <span
          v-translate
          translate-context="MTodoCommentDialog component save button"
        >
          Save
        </span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';

  export default {
    data: () => ({
      comment: '',
    }),

    computed: {
      ...mapGetters({
        dialogProps: 'dialog/dialogProps',
      }),

      todo() {
        return this.dialogProps.todo;
      },

      onClose() {
        return this.dialogProps.onClose;
      },

      commentPlaceholder() {
        return this.$pgettext('MTodoCommentDialog component comment placeholder', 'Leave a comment');
      },

      commentHint() {
        return this.$pgettext('MTodoCommentDialog component comment hint', 'A comment will help other users understand the contents of this task');
      },
    },

    mounted() {
      this.comment = this.todo.comment;
    },

    methods: {
      ...mapActions({
        closeDialog: 'dialog/closeDialog',
        updateTodo: 'project/todos/updateTodo',
      }),

      async confirm() {
        this.$wait.start('skipping');

        await this.updateTodo({
          id: this.todo.id,
          payload: {
            todo: {
              comment: this.comment,
            },
          },
        });

        if (this.onClose) await this.onClose();

        this.closeDialog();
        this.$wait.end('skipping');
      },
    },
  };
</script>
