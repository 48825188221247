import Vue from 'vue';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import updateLocale from 'dayjs/plugin/updateLocale';
import 'dayjs/locale/en-gb';
var duration = require('dayjs/plugin/duration')
import 'dayjs/locale/fi';
import { currentLanguage } from '@/plugins/i18n';


dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);
dayjs.extend(weekOfYear);
dayjs.extend(updateLocale);
dayjs.extend(duration)

// import weekday from 'dayjs/plugin/weekday';
// dayjs.extend(weekday);

const { locale } = currentLanguage();

let dayJsLocale = locale 
if (locale === 'en') {
  dayJsLocale = 'en-gb'
}

dayjs.locale(dayJsLocale);

/**
 * This (should) fix issues years that has 53 weeks.
 */
dayjs.updateLocale(locale, {
  yearStart: 4,
});

Vue.prototype.$dayjs = dayjs;
