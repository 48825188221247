<template>
  <v-navigation-drawer
    v-hotkey="{ 'esc': closeSidePanel }"
    :value="isVisible"
    fixed
    touchless
    right
    temporary
    width="500"
    class="backgroundAccent lighten-3 m-base-side-panel"
    style="z-index: 10;"
    @input="onInputChange"
  >
    <component :is="component" />
  </v-navigation-drawer>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';

  export default {
    components: {
      'm-passage-editor': () => import('@/components/sidePanels/MPassageEditor'),
      'm-team-editor': () => import('@/components/sidePanels/MTeamEditor'),
      'm-space-editor': () => import('@/components/sidePanels/MSpaceEditor'),
      'm-work-package-editor': () => import('@/components/sidePanels/MWorkPackageEditor'),
      'm-task-editor': () => import('@/components/sidePanels/MTaskEditor'),
    },

    computed: {
      ...mapGetters({
        component: 'sidePanel/component',
        isVisible: 'sidePanel/isVisible',
      }),
    },

    watch: {
      component(component) {
        if (!component) return;

        /**
         * Attempts to hack an autofocus logic so that the client
         * code does not need to setup it again and again.
         */
        this.$nextTick(() => {
          setTimeout(() => {
            const firstInput = document
              .querySelector('.m-base-side-panel input:first-of-type, .m-base-side-panel textarea:first-of-type');
            if (firstInput) firstInput.focus();
          }, 300);
        });
      },
    },

    methods: {
      ...mapActions({
        closeSidePanel: 'sidePanel/closeSidePanel',
      }),

      onInputChange(isVisible) {
        if (!isVisible) {
          this.closeSidePanel();
        }
      },
    },
  };
</script>
