import Vue from 'vue';
import Vuex from 'vuex';
import cloneDeep from 'lodash.clonedeep';
import { translate } from 'vue-gettext';
import api from '../../utils/api';
import { urlWithParams } from '@/vuex/utils';
import * as Sentry from '@sentry/vue';

const { pgettext: $pgettext } = translate;

const initialState = () => ({
  project: {},
  spaceTimeSchedule: {},
});

const getters = {
  project: state => state.project,
  isResearchProject: (_, allGetters) => allGetters.project.type === 'research',
  spaceTimeSchedule: s => s.spaceTimeSchedule,
};

const actions = {
  setCurrentProjectId({ commit }, { projectId = null }) {
    commit('SET_CURRENT_PROJECT_ID', { projectId });
    Sentry.setContext("project", {
      id: projectId
    });
  },

  async loadProject({ commit }, { id = null } = { id: null }) {
    try {
      const r = await api.get(`projects/${id || this.$projectId}`);

      commit('SET_PROJECT', r);
    } catch (e) {}
  },

  async createProject({ commit, dispatch }, { companyId, payload }) {
    try {
      const r = await api.post(`companies/${companyId}/projects`, payload);

      commit('SET_PROJECT', r);

      await dispatch('snackbar/addNotification', {
        msg: $pgettext('Snackbar notification', 'Project saved'),
        type: 'success',
      }, { root: true });

      return r.data;
    } catch (e) {
      await dispatch('snackbar/addNotification', {
        msg: $pgettext('Snackbar notification', 'Saving failed'),
        type: 'error',
      }, { root: true });

      return null;
    }
  },

  async updateProject({ commit, dispatch }, payload) {
    try {
      const r = await api.update(`projects/${this.$projectId}`, payload);
      commit('SET_PROJECT', r);

      await dispatch('snackbar/addNotification', {
        msg: $pgettext('Snackbar notification', 'Project saved'),
        type: 'success',
      }, { root: true });
    } catch (e) {
      await dispatch('snackbar/addNotification', {
        msg: $pgettext('Snackbar notification', 'Saving failed'),
        type: 'error',
      }, { root: true });
    }
  },

  async loadSpaceTimeSchedule({ commit }) {
    try {
      const r = await api.get(`projects/${this.$projectId}/space_time_schedule`);

      commit('SET_SPACE_TIME_SCHEDULE', r);
    } catch (e) {}
  },

  async updateSpaceTimeSchedule({ commit }, params) {
    try {
      const url = urlWithParams(`projects/${this.$projectId}/space_time_schedule`, params);
      const r = await api.get(url);

      commit('UPDATE_SPACE_TIME_SCHEDULE', r);
    } catch (e) {}
  },
};

const mutations = {
  SET_CURRENT_PROJECT_ID(state, { projectId }) {
    Vue.prototype.$projectId = parseInt(projectId, 10); // Used inside Vue components
    Vuex.Store.prototype.$projectId = parseInt(projectId, 10); // Used inside Vuex (store)
  },

  SET_PROJECT(state, { data }) {
    Vue.set(state, 'project', data);
  },

  SET_SPACE_TIME_SCHEDULE(state, { data }) {
    Vue.set(state, 'spaceTimeSchedule', data);
  },

  /**
   * The data may include only partial set so this
   * mutation replaces only what's in the data.
   *
   * @param state
   * @param data
   * @constructor
   */
  UPDATE_SPACE_TIME_SCHEDULE(state, { data }) {
    const spaceTimeSchedule = Object
      .keys(data)
      .reduce((acc, spaceId) => {
        acc[spaceId] = data[spaceId];
        return acc;
      }, cloneDeep(state.spaceTimeSchedule));

    Vue.set(state, 'spaceTimeSchedule', spaceTimeSchedule);
  },
};

export default {
  state: initialState(),
  getters,
  actions,
  mutations,
};
