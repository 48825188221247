import Vuex from 'vuex';
import Gtm from './gtm';
import {
  mergeConfig,
} from './defaultConfig';
import { loadScript } from './utils';

const {
  default: directives,
} = require('./directives');

const install = (Vue, initConfig = {}) => {
  const config = mergeConfig(initConfig);

  const gtm = new Gtm();

  Vuex.Store.prototype.$gtm   = gtm; // eslint-disable-line
  Vue.prototype.$gtm          = gtm; // eslint-disable-line

  Object.keys(directives).forEach((key) => {
    Vue.directive(key, directives[key]);
  });

  if (config.enabled) {
    loadScript(config.id);
  }
};

export default {
  install,
};
