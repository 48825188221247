import Vue from 'vue';
import VeeValidate, { Validator } from 'vee-validate';
import { currentLanguage } from '@/plugins/i18n';
import fi from './fi';
import en from './en';
import rules from './rules';

const messages = {
  fi: fi.messages,
  en: en.messages,
};

const { locale } = currentLanguage();
Validator.localize(locale, messages[locale]);

Object.keys(rules).forEach((rule) => {
  Validator.extend(rule, rules[rule]);
});

const VeeConfig = {
  inject: false,
  locale,
};

Vue.use(VeeValidate, VeeConfig);
