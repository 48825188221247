import Vue from 'vue';
import { translate } from 'vue-gettext';
import {
  appendItemToArraySortByKey,
  updateItemsInArraySortByKey,
} from '@/vuex/utils/helpers';
import api from '../../utils/api';

const { pgettext: $pgettext } = translate;

const initialState = () => ({
  passages: [],
});

const getters = {
  passages: state => state.passages,
  groupedPassages: (_, allGetters) => allGetters.passages.reduce((acc, passage) => {
      acc[passage.id] = passage;

      return acc;
    }, {}),
  passageById: (_, allGetters) => id => allGetters.groupedPassages[id],
};

const actions = {
  async loadPassages(
    { commit },
    {
      includes = [],
    } = {
      includes: [],
    },
  ) {
    let endpoint = `projects/${this.$projectId}/passages?`;

    if (includes.length > 0) {
      const arr = includes.join(',');
      endpoint += `includes=${arr}&`;
    }

    try {
      const r = await api.get(endpoint);

      commit('SET_PASSAGES', r);
    } catch (e) {}
  },

  async createPassage({ commit, dispatch }, { payload }) {
    try {
      const r = await api.post(`projects/${this.$projectId}/passages`, payload);

      commit('ADD_TO_PASSAGES', r);

      dispatch('snackbar/addNotification', {
        msg: $pgettext('Snackbar notification', 'Passage saved'),
        type: 'success',
      }, { root: true });

      return r.data;
    } catch (e) {
      await dispatch('snackbar/addNotification', {
        msg: $pgettext('Snackbar notification', 'Saving failed'),
        type: 'error',
      }, { root: true });

      return false;
    }
  },

  async updatePassage({ commit, dispatch }, { id, payload }) {
    try {
      const r = await api.update(`projects/${this.$projectId}/passages/${id}`, payload);

      commit('UPDATE_PASSAGES', r);

      await dispatch('snackbar/addNotification', {
        msg: $pgettext('Snackbar notification', 'Passage saved'),
        type: 'success',
      }, { root: true });

      return r.data;
    } catch (e) {
      await dispatch('snackbar/addNotification', {
        msg: $pgettext('Snackbar notification', 'Saving failed'),
        type: 'error',
      }, { root: true });

      return false;
    }
  },

  async destroyPassage({ commit, dispatch }, { id }) {
    try {
      const r = await api.delete(`projects/${this.$projectId}/passages/${id}`);

      commit('DESTROY_PASSAGE', r);

      await dispatch('snackbar/addNotification', {
        msg: $pgettext('Snackbar notification', 'Passage deleted'),
        type: 'success',
      }, { root: true });
    } catch (e) {}
  },
};

const mutations = {
  SET_PASSAGES(state, { data }) {
    Vue.set(state, 'passages', data);
  },

  ADD_TO_PASSAGES(state, { data }) {
    const passages = appendItemToArraySortByKey({
      inputArray: state.passages,
      item: data,
      key: 'order',
    });

    Vue.set(state, 'passages', passages);
  },

  UPDATE_PASSAGES(state, { data }) {
    let updatedItems = data;

    /**
     * Allows passing either object or array
     */
    if (!Array.isArray(data)) {
      updatedItems = [data];
    }

    const passages = updateItemsInArraySortByKey({
      inputArray: state.passages,
      updatedItems,
      key: 'order',
    });

    Vue.set(state, 'passages', passages);
  },

  DESTROY_PASSAGE(state, { data }) {
    const index = state.passages.findIndex(p => p.id === data.id);

    if (index < 0) return;

    state.passages.splice(index, 1);
  },
};

export default {
  state: initialState(),
  getters,
  actions,
  mutations,
};
