import { getEnv } from '@/utils/environment';
import { getAccessToken, urlWithParams } from './index';
import { cachedLocale } from './localStorage';
import store from '..';
import { translate } from 'vue-gettext';
const { pgettext: $pgettext } = translate;

const BASE_URL = (version=2) => `${getEnv('VUE_APP_API_URL')}/api/v${version}`;

class ApiError extends Error {
  /**
   * @param {string} message
   * @param {number} httpStatus
   * @param {Response} response
   */
  constructor(message, httpStatus, response) {
    super();

    /**
     * @type {string}
     * @readonly
     */

    this.message = message;

    /**
     * @type {number}
     * @readonly
     */
    this.httpStatus = httpStatus;

    /**
     * @type {Response}
     * @readonly
     */
    this.response = response;

    /**
     * @type {string}
     * @readonly
     */
    this.name = this.constructor.name;

    /**
     * @type {string}
     * @readonly
     */
    this.stack = ApiError.createStack(this);
  }

  /**
   * @return {string}
   */
  toString() {
    return this.prettyPrint();
  }

  /**
   * @return {string}
   */
  prettyPrint() {
    return `HTTP status: ${this.httpStatus}; Message: ${this.message}`;
  }

  /**
   * @param {ApiError} error
   * @return {string}
   * @private
   */
  static createStack(error) {
    return typeof Error.captureStackTrace === 'function'
      ? Error.captureStackTrace(error, error.constructor)
      : (new Error()).stack;
  }
}

const getHeaders = (args = {}) => {
  const defaults = {
    Accept: 'application/json',
    // Ensure we're not using the old non standard locale
    HTTP_ACCEPT_LANGUAGE: cachedLocale().replace('_', '-'),
  };
  const accessToken = getAccessToken();

  if (accessToken) {
    defaults.Authorization = `Token token=${accessToken}`;
  }

  return Object.assign(defaults, args);
};

const parseResponse = async (response) => {
  if (response.ok) {
    if (response.status === 204) return true; // No body so no need to parse

    try {
      return await response.clone().json();
    } catch (e) {
      /**
       * Handles empty body. We could also response.text()
       * but it's easier to check for boolean than empty
       * string.
       */
      return true;
    }
  }

  if (response.status === 500) {
    await store.dispatch('snackbar/showGeneralError', null, { root: true });
  }

  if (response.status === 403) {
    await store.dispatch('snackbar/showForbiddenError', null, { root: true });
  }
  
  let error;

  try {
    error = await response.json();
  } catch (e) {
    /**
     * Handles empty body.
     */
    error = { msg: '' };
  }

  if (process.env.NODE_ENV === 'development') {
    console.error('Response:', response); // eslint-disable-line
    console.error('Error:', error); // eslint-disable-line
  }

  throw new ApiError(error.msg, response.status, response);
};

const parseStreamResponse = (response) => {
  if (response.status >= 200 && response.status < 300) {
    const disposition = response.headers.get('content-disposition');

    let filename;

    if (disposition && disposition.indexOf('attachment') !== -1) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(disposition);

      if (matches && matches[1]) {
        filename = matches[1].replace(/['"]/g, '');
      }
    } else {
      filename = 'filename.xlsx';
    }

    return new Promise(async (resolve) => {
      const blob = await response.blob();

      return resolve({
        filename,
        blob,
      });
    });
  }

  return response.json().then(async (err) => {
    if (process.env.NODE_ENV === 'development') {
      console.error(err); // eslint-disable-line
    }

    throw err;
  });
};

export default {
  get(url, params = {}, version=2) {
    const options = {
      method: 'GET',
      headers: getHeaders(),
    };

    let baseUrl;

    if (typeof url === 'string') {
      baseUrl = `${BASE_URL(version)}/${url}`;
    } else if (typeof url === 'object') {
      baseUrl = `${BASE_URL(version)}/${url.url}`;
    }

    const fullUrl = urlWithParams(baseUrl, params);

    return fetch(fullUrl, options).then(parseResponse);
  },
  post(url, data, version=2) {
    const options = {
      method: 'POST',
      headers: getHeaders({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(data),
    };

    return fetch(`${BASE_URL(version)}/${url}`, options).then(parseResponse);
  },
  update(url, data = {}, version=2) {
    const options = {
      method: 'PATCH',
      headers: getHeaders({
        'Content-Type': 'application/json',
      }),
    };

    if (Object.keys(data).length > 0) {
      Object.assign(options, {
        body: JSON.stringify(data),
      });
    }

    return fetch(`${BASE_URL(version)}/${url}`, options).then(parseResponse);
  },
  delete(url, data = {}, version=2) {
    const options = {
      method: 'DELETE',
      headers: getHeaders({
        'Content-Type': 'application/json',
      }),
    };

    if (Object.keys(data).length > 0) {
      Object.assign(options, {
        body: JSON.stringify(data),
      });
    }

    return fetch(`${BASE_URL(version)}/${url}`, options).then(parseResponse);
  },
  getFile(url, version=2) {
    const options = {
      method: 'GET',
      headers: getHeaders(),
    };

    return fetch(`${BASE_URL(version)}/${url}`, options).then(parseStreamResponse);
  },
};
