import Vue from 'vue';
import api from '../utils/api';

const initialState = () => ({
  projectSuccessMetrics: {},
});

const getters = {
  projectSuccessMetrics: state => projectId => state.projectSuccessMetrics[projectId],
};

const actions = {
  async loadProjectSuccessMetrics({ commit }, projectId) {
    try {
      const r = await api.get(`projects/${projectId}/project_success_metrics`);
      commit('SET_PROJECT_SUCCESS_METRICS', { projectId, data: r.data });
    } catch (e) {}
  },
};

const mutations = {
  SET_PROJECT_SUCCESS_METRICS(state, { projectId, data }) {
    Vue.set(state.projectSuccessMetrics, projectId, data);
  },
};

export default {
  state: initialState(),
  getters,
  actions,
  mutations,
};
