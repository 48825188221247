<template>
  <div>
    <span class="subheading my-2 body-2">
      <span
        v-translate
        translate-context="MWarningProjectDeleteDialog component content [1/2]"
      >
        You are about to delete project
      </span>
      <span class="font-weight-bold">{{ projectPid }}</span>.
      <br>
      <span
        v-translate
        translate-context="MWarningProjectDeleteDialog component content [2/2]"
      >
        All project information will be deleted and cannot be restored.
      </span>
    </span>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    computed: {
      ...mapGetters({
        dialogProps: 'dialog/dialogProps',
      }),

      projectPid() {
        return this.dialogProps.project.pid;
      },
    },
  };
</script>
