import Vue from 'vue';
import { translate } from 'vue-gettext';

const { pgettext: $pgettext } = translate;

const initialState = () => ({});

const getters = {
  projectNavItems: (a, b, c, d) => {
    const {
      $currentUser,
      $projectId,
    } = Vue.prototype;

    return [
      {
        title: $pgettext('Main navigation section', 'Overview'),
        children: () => ([
          {
            icon: 'show_chart',
            title: $pgettext('Main navigation title', 'Dashboard'),
            to: {
              name: 'project-dashboard',
              projectId: $projectId,
              exact: true,
            },
          }
        ]),
      },
      {
        title: $pgettext('Main navigation section title', 'Preparation'),
        children: () => {
          const children = [];

          if ($currentUser.permissions.includes('plans_management')) {
            children.push({
              icon: 'edit',
              title: $pgettext('Main navigation title', 'Planning & schedule'),
              to: {
                name: 'edit-project',
                projectId: $projectId,
                exact: false,
              },
            });

            if (d['currentUser/enabledFeatures']?.projectPreconditions) {
              children.push({
                icon: 'done_all',
                title: $pgettext('Main navigation title', 'Preconditions'),
                to: {
                  name: 'project-preconditions',
                  projectId: $projectId,
                },
              });
            }
          }

          if ($currentUser.permissions.includes('users_management')) {
            children.push({
              icon: 'group',
              title: $pgettext('Main navigation title', 'Users'),
              to: {
                name: 'project-users',
                projectId: $projectId,
                exact: true,
              },
            });
          }

          return children;
        },
      },
      {
        title: $pgettext('Main navigation section title', 'Task lists'),
        children: () => ([
          {
            icon: 'list_alt',
            title: $pgettext('Main navigation title', 'My tasks'),
            shortTitle: $pgettext('Main navigation title short', 'My'),
            shortcut: true,
            to: {
              name: 'own-todos',
              projectId: $projectId,
              exact: true,
            },
          },
          {
            icon: 'view_list',
            title: $pgettext('Main navigation title', 'All tasks'),
            shortTitle: $pgettext('Main navigation title short', 'All'),
            shortcut: true,
            to: {
              name: 'all-todos',
              projectId: $projectId,
              exact: true,
            },
          },
        ]),
      },
      {
        title: $pgettext('Main navigation section title', 'Status views'),
        children: () => {
          const children = [
            {
              icon: 'grid_on',
              title: $pgettext('Main navigation title', 'Per takt'),
              shortTitle: $pgettext('Main navigation title short', 'Status'),
              shortcut: true,
              to: {
                name: 'takt-status',
                projectId: $projectId,
                exact: true,
              },
            },
          ];

          if ($currentUser.permissions.includes('spaces_status')) {
            children.push({
              icon: 'view_headline',
              title: $pgettext('Main navigation title', 'Per space'),
              to: {
                name: 'spaces-status',
                projectId: $projectId,
                exact: true,
              },
            });
          }

          if ($currentUser.permissions.includes('teams_status')) {
            children.push({
              icon: 'trending_up',
              title: $pgettext('Main navigation title', 'Per team'),
              to: {
                name: 'team-trends',
                projectId: $projectId,
                exact: true,
              },
            });
          }

          if (d['currentUser/enabledFeatures']?.spaceTimeView) {
            children.push(
              {
                icon: 'clear_all',
                title: $pgettext('Main navigation title', 'Time and Space (BETA)'),
                to: {
                  name: 'project-time-and-space',
                  projectId: $projectId,
                  exact: true,
                },
              },
            );
          }

          return children;
        },
      },
    ];
  },
};

const actions = {};

const mutations = {};

export default {
  state: initialState(),
  getters,
  actions,
  mutations,
};
