import Vue from 'vue';
import api from '../../utils/api';

const initialState = () => ({
  interruptionReasons: [],
});

const getters = {
  interruptionReasons: state => state.interruptionReasons,
};

const actions = {
  async loadInterruptionReasons({ commit }) {
    try {
      const r = await api.get('interruption_reasons');

      commit('SET_INTERRUPTION_REASONS', r);
    } catch (e) {}
  },
};

const mutations = {
  SET_INTERRUPTION_REASONS(state, { data }) {
    Vue.set(state, 'interruptionReasons', data);
  },
};

export default {
  state: initialState(),
  getters,
  actions,
  mutations,
};
