const USER_LOCALE = 'USER_LOCALE';

const getCurrentPage = () => localStorage.getItem('currentPage');

const saveCurrentPage = (page) => {
  localStorage.setItem('currentPage', page);
};

const getTaktLimits = () => (
  JSON.parse(localStorage.getItem('taktLimits')) || {}
);

const setTaktLimits = (payload) => {
  // Merge to the previous data
  const data = {
    ...getTaktLimits(),
    ...payload,
  };

  localStorage.setItem('taktLimits', JSON.stringify(data));
};

const cacheGridCardSize = (payload) => {
  localStorage.setItem('PASSAGES_GRID_CARD_SIZE', JSON.stringify(payload));
};

const readCachedGridCardSize = () => JSON.parse(localStorage.getItem('PASSAGES_GRID_CARD_SIZE')) || {};

const getCachedPassageIds = () => JSON.parse(localStorage.getItem('SELECTED_PASSAGE_IDS')) || {};

const cacheSelectedPassageId = (payload) => {
  const data = {
    ...getCachedPassageIds(),
    ...payload,
  };

  localStorage.setItem('SELECTED_PASSAGE_IDS', JSON.stringify(data));
};

const cachedLocale = () => {
  let localeString = localStorage.getItem(USER_LOCALE) || '';
  // Ensure we're not using the old non standard locale
  return localeString.replace('_', '-')
}

const cacheLocale = (locale) => {
  // Ensure we're not using the old non standard locale
  window.localStorage.setItem(USER_LOCALE, locale.replace('_', '-'));
};

const cacheCompany = (companyId) => {
  localStorage.setItem('SELECTED_COMPANY_ID', companyId);
};

const cachedCompany = () => JSON.parse(localStorage.getItem('SELECTED_COMPANY_ID'));


export {
  getCurrentPage,
  saveCurrentPage,
  setTaktLimits,
  getTaktLimits,
  cacheGridCardSize,
  readCachedGridCardSize,
  getCachedPassageIds,
  cacheSelectedPassageId,
  cacheLocale,
  cachedLocale,
  cacheCompany,
  cachedCompany,
};
