<template>
  <v-breadcrumbs
    :items="breadcrumbs"
  >
    <template v-slot:divider>
      <v-icon>keyboard_arrow_right</v-icon>
    </template>

    <template v-slot:item="{ item }">
      <v-breadcrumbs-item
        :path="item.path"
        :to="item.to"
        :exact="item.exact"
        :disabled="item.disabled"
        ripple
      >
        <span
          :class="{
            'white--text': !item.disabled,
          }"
          class="px-2 py-1"
        >
          {{ item.text() }}
        </span>
      </v-breadcrumbs-item>
    </template>
  </v-breadcrumbs>
</template>

<script>
  export default {
    computed: {
      breadcrumbs() {
        const pathArray = this
          .$route
          .path
          .split('/')
          .filter(path => path !== '' && path !== '/');

        const matchedRoutes = this
          .$route
          .matched
          .slice()
          .filter(({ path }) => path !== '' && path !== '/');

        const breadcrumbs = matchedRoutes.reduce((acc, matchedPath, idx) => {
          const path = pathArray[idx];

          if (!path) return acc;

          const prev = acc[idx - 1];

          const breadcrumb = {
            path,
            to: prev ? `${prev.to}/${path}` : `/${path}`,
            text: () => {
              if (!matchedPath.meta.documentTitle) {
                return path;
              }
              if (typeof matchedPath.meta.documentTitle === 'function') {
                return matchedPath.meta.documentTitle();
              }

              return matchedPath.meta.documentTitle;
            },
            exact: true,
            disabled: idx >= matchedRoutes.length - 1,
          };

          return [
            ...acc,
            breadcrumb,
          ];
        }, []);

        return breadcrumbs;
      },
    },
  };
</script>
