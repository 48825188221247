const vuetifyTheme = {
  options: {
    customProperties: true,
  },
  dark: false,
  themes: {
    dark: {
      backgroundAccent: '#25292E',
      primary: '#2B7393',
      
      secondary: '#CC745C',
      accent: '#BB3163',
      success: '#75B42D',
      white: '#FBFCFC',
      black: '#131B23',
      error: '#CC3D0E',
      info: '#2B7393',
      warning: '#ECA400',

      infoBackground: '#112933',
      errorBackground: '#4F1D0C',
      warningBackground: '#7B5A0D',
      successBackground: '#2F4C0D',
    },
    light: {
      backgroundAccent: '#E0E7EB',
      primary: '#25434F',
      secondary: '#2B7393',
      accent: '#BB3163',
      success: '#008732',
      white: '#FBFCFC',
      black: '#000000',
      error: '#CC3D0E',
      info: '#2B7393',
      warning: '#ECA400',

      infoBackground: '#8DB8CB',
      errorBackground: '#FFC9B7',
      warningBackground: '#E9D6A9',
      successBackground: '#D4F7AD',
    },
  },
};

module.exports = vuetifyTheme;
