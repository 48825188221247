var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"tile":""}},[_c('v-card-title',{staticClass:"d-flex justify-space-between backgroundAccent"},[_c('div',{directives:[{name:"translate",rawName:"v-translate"}],attrs:{"translate-context":"MCheckupDialog component content"}},[_vm._v(" Check up on task ")]),_c('v-btn',{attrs:{"icon":"","disabled":_vm.$wait.is('updating todo')},on:{"click":_vm.closeDialog}},[_c('v-icon',[_vm._v(" close ")])],1)],1),_c('v-card-text',{staticClass:"pt-4 px-0"},[_c('v-subheader',[_c('span',{directives:[{name:"translate",rawName:"v-translate",value:({
          address: _vm.todo.address,
          task: _vm.todo.taskName,
          dotSeparator: '&#x2022;'
        }),expression:"{\n          address: todo.address,\n          task: todo.taskName,\n          dotSeparator: '&#x2022;'\n        }"}],attrs:{"translate-context":"MCheckupDialog component content","render-html":"true"}},[_vm._v(" You are about to check up on task %{ address } %{ dotSeparator } %{ task } ")])]),(_vm.$wait.is('loading checkups'))?_c('div',{staticClass:"d-flex justify-center mt-4"},[_c('v-progress-circular',{attrs:{"indeterminate":"","width":2,"size":20}})],1):_c('div',[_c('v-list',{staticClass:"pb-0",attrs:{"dense":"","two-line":""}},_vm._l((_vm.checkups),function(checkup,i){return _c('v-list-item',{key:i,staticStyle:{"border-bottom":"1px solid rgba(0, 0, 0, 0.1)"},style:({
            pointerEvents: _vm.$wait.is(("updating checkup - " + (checkup.checkupItemId)))
              ? 'none' : 'auto',
            opacity: _vm.$wait.is(("updating checkup - " + (checkup.checkupItemId))) ? 0.5 : 1
          }),on:{"click":function () { return _vm.onToggle(checkup); }}},[(_vm.$wait.is(("updating checkup - " + (checkup.checkupItemId))))?_c('div',{staticClass:"d-flex align-center justify-end mr-2",style:({
              position: 'absolute',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            })},[_c('v-progress-circular',{attrs:{"indeterminate":"","width":2,"size":16}})],1):_vm._e(),_c('v-list-item-action',{staticClass:"my-0 py-0 mr-4"},[_c('v-checkbox',{attrs:{"input-value":!!checkup.completedAt,"disabled":_vm.$wait.is(("updating checkup - " + (checkup.checkupItemId)))}})],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"d-flex justify-space-between align-center",staticStyle:{"white-space":"normal"}},[_c('div',[_vm._v(" "+_vm._s(checkup.title)+" ")]),(checkup.completedAt)?_c('div',{staticClass:"caption-xs text-right",staticStyle:{"min-width":"100px"}},[_c('div',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"text-uppercase",attrs:{"translate-context":"MCheckupDialog component content"}},[_vm._v(" Checked up by ")]),_c('div',{staticClass:"text--secondary"},[_vm._v(" "+_vm._s(_vm.authorFullName(checkup.authorId))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.$dayjs(checkup.completedAt).format('DD.MM.YYYY @ HH:mm'))+" ")])]):_vm._e()])],1)],1)}),1)],1)],1),_c('v-card-actions',{staticClass:"backgroundAccent lighten-2"},[_c('v-spacer'),_c('v-btn',{staticClass:"px-8",attrs:{"loading":_vm.$wait.is('updating todo'),"disabled":_vm.readyButtonDisabled,"dark":_vm.darkReadyButton,"color":"secondary"},on:{"click":_vm.onComplete}},[_vm._v(" "+_vm._s(_vm.readyButtonText)+" "),(_vm.checkupsReady)?_c('v-icon',{attrs:{"right":"","small":""}},[_vm._v(" thumb_up ")]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }