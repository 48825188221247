<template>
  <v-app id="app">
    <v-main>
      <div :style="{'position': 'fixed', 'bottom': $vuetify.breakpoint.mobile? '65px' : '10px', 'right': '10px', zIndex: 1337}"
      v-if="updateAvailable"
      >
        <m-update-available-indicator/>
      </div>
      <m-offline
        v-if="isOffline"
      />

      <router-view
        v-else
      />
    </v-main>
    <m-snackbar />
    <m-base-dialog />
    <m-base-side-panel />
  </v-app>
</template>

<script>
  import { mapGetters } from 'vuex';
  import MOffline from '@/components/MOffline';
  import MBaseDialog from '@/components/dialogs/MBaseDialog';
  import MBaseSidePanel from '@/components/sidePanels/MBaseSidePanel';
  import MUpdateAvailableIndicator from '@/components/MUpdateAvailableIndicator';

  export default {
    components: {
      MOffline,
      MBaseDialog,
      MBaseSidePanel,
      MUpdateAvailableIndicator
    },

    computed: {
      ...mapGetters({
        isOffline: 'sw/isOffline',
        updateAvailable: 'sw/updateAvailable'
      }),
    },
  };
</script>
