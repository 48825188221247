// Source partly copied from https://github.com/mib200/vue-gtm

import Vue from 'vue';
import GtmPlugin from './gtmPlugin';

Vue.use(GtmPlugin, {
  id: 'GTM-MZ2MZLB',
  enabled: process.env.NODE_ENV === 'production',
  debug: process.env.NODE_ENV !== 'production',
  vDirectives: {
    click(id) {
      const params = {
        event: 'buttonClick',
        value: id,
      };

      this.$gtm.trackEvent(params);
    },
  },
});
