import Vue from 'vue';
import api from '../../utils/api';
import { getTaktLimits, setTaktLimits } from '@/vuex/utils/localStorage';

const initialState = () => ({
  currentTact: {},
  tacts: [],
  tactStart: 1,
  tactEnd: 1,
  selectedTeams: [],
});

const workDateParser = (takt) => {
  const {
    startsAt,
    workdayEndsAt,
    taktIndex,
  } = takt;
  const dayjs = Vue.prototype.$dayjs;

  const parsedStartsAt = dayjs(startsAt);
  const parsedEndsAt = dayjs(workdayEndsAt);

  const dateFormat = 'DD.MM.';
  const timeFormat = 'HH:mm';

  const weekdayFrom = parsedStartsAt.format('ddd');
  const weekdayTo = parsedEndsAt.format('ddd');

  const dayAndTimeFormatted = `${weekdayFrom} ${parsedStartsAt.format(timeFormat)} - ${weekdayTo} ${parsedEndsAt.format(timeFormat)}`;
  const datesFormatted = `${parsedStartsAt.format(dateFormat)} - ${parsedEndsAt.format(dateFormat)}`;

  const r = {
    taktIndex,
    dayAndTimeFormatted,
    datesFormatted,
  };

  return r;
};

const getters = {
  currentTact: state => state.currentTact,
  tacts: state => state.tacts,
  tactStart: state => Number.parseInt(state.tactStart, 10),
  tactEnd: state => Number.parseInt(state.tactEnd, 10),
  minTact: (_, a) => {
    if (a.tacts.length <= 0) return 0;
    return a.tacts[0].taktIndex;
  },
  maxTact: (_, a) => {
    if (a.tacts.length <= 0) return 0;
    return a.tacts[a.tacts.length - 1].taktIndex;
  },
  tactRange: (_, a) => [a.tactStart, a.tactEnd],
  selectedTeams: state => state.selectedTeams,
  datesByTaktOrder: (_, allGetters) => {
    const {
      tacts,
    } = allGetters;

    const dates = tacts.reduce((acc, tact) => {
      if (!acc[tact.taktIndex]) acc[tact.taktIndex] = [];

      const parsedDates = workDateParser(tact);
      acc[tact.taktIndex] = parsedDates;

      return acc;
    }, {});

    return dates;
  },
  currentTakt: (_, allGetters) => {
    const {
      currentTact,
    } = allGetters;

    return workDateParser(currentTact);
  },
};

const actions = {
  async loadCurrentTact(
    { commit },
    { tactOrder = null } = { tactOrder: null },
  ) {
    if (!this.$projectId) return;

    let url = `projects/${this.$projectId}/current_takt`;

    if (tactOrder !== null) {
      url += `?takt_order=${tactOrder}`;
    }

    try {
      const r = await api.get(url);

      commit('SET_CURRENT_TACT', r);
    } catch (e) {}
  },

  setTactStart({ commit }, payload) {
    commit('SET_TACT_START', payload);
  },

  setTactEnd({ commit }, payload) {
    commit('SET_TACT_END', payload);
  },

  async loadTacts({ commit }) {
    try {
      const r = await api.get(`projects/${this.$projectId}/takts`);
      commit('SET_TACTS', r);
    } catch (e) {}
  },

  resetCurrentTact({ commit }) {
    commit('RESET_CURRENT_TACT');
  },

  setSelectedTeams({ commit }, payload) {
    commit('SET_SELECTED_TEAMS', payload);
  },

  async regenerateTacts({
    commit,
    getters: allGetters,
    rootGetters,
    dispatch,
  }) {
    await dispatch('wait/start', 'regenerating tacts', { root: true });
    try {
      const r = await api.update(`projects/${this.$projectId}/takts/regenerate`);
      commit('SET_TACTS', r);
    } catch (e) {}

    await dispatch('wait/end', 'regenerating tacts', { root: true });
  },
};

const mutations = {
  SET_CURRENT_TACT(state, { data }) {
    Vue.set(state, 'currentTact', data);
  },

  SET_TACTS(state, { data }) {
    Vue.set(state, 'tacts', data);
  },

  RESET_CURRENT_TACT(state) {
    Vue.set(state, 'currentTact', initialState().currentTact);
  },

  SET_SELECTED_TEAMS(state, payload) {
    Vue.set(state, 'selectedTeams', payload);
  },

  SET_TACT_START(state, payload) {
    let lowerLimit = null;

    /**
     * This logic caches the lower takt order limit.
     *
     * lowerLimit is set by order of importance:
     * 1) payload
     * 2) localStorage
     * 3) order of the current tact
     * 4) defaults to 1
     */
    if (payload) {
      lowerLimit = payload;
    } else if (getTaktLimits()[this.$projectId]) {
      ({
        lowerLimit, // Can be undefined
      } = getTaktLimits()[this.$projectId]);
    }

    if (!lowerLimit) {
      lowerLimit = state.currentTact.taktIndex;
    }

    if (!lowerLimit) {
      lowerLimit = 1;
    }

    const storedData = {
      [this.$projectId]: {
        ...getTaktLimits()[this.$projectId],
        lowerLimit: parseInt(lowerLimit, 10),
      },
    };

    setTaktLimits(storedData);
    Vue.set(state, 'tactStart', lowerLimit);
  },

  SET_TACT_END(state, payload) {
    let upperLimit = null;

    /**
     * This logic caches the upper takt order limit.
     *
     * upperLimit is set by order of importance:
     * 1) payload
     * 2) localStorage
     * 3) order of the current tact
     * 4) defaults to 1
     */
    if (payload) {
      upperLimit = payload;
    } else if (getTaktLimits()[this.$projectId]) {
      ({
        upperLimit, // Can be undefined
      } = getTaktLimits()[this.$projectId]);
    }

    if (!upperLimit) {
      upperLimit = state.currentTact.taktIndex;
    }

    if (!upperLimit) {
      upperLimit = 1;
    }

    const storedData = {
      [this.$projectId]: {
        ...getTaktLimits()[this.$projectId],
        upperLimit: parseInt(upperLimit, 10),
      },
    };

    setTaktLimits(storedData);
    Vue.set(state, 'tactEnd', upperLimit);
  },
};

export default {
  state: initialState(),
  getters,
  actions,
  mutations,
};
