import Vue from 'vue';

const initialState = () => ({
  hideCompleted: false,
});

const getters = {
  hideCompleted: state => state.hideCompleted,
};

const actions = {
  toggleHideCompleted({ commit }) {
    commit('TOGGLE_HIDE_COMPLETED');
  },
};

const mutations = {
  TOGGLE_HIDE_COMPLETED(state) {
    Vue.set(state, 'hideCompleted', !state.hideCompleted);
  },
};

export default {
  state: initialState(),
  getters,
  actions,
  mutations,
};
