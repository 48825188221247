import getConfig from './defaultConfig';
import { log } from './utils';

const gtm = {
  inserted: (el, binding, vnode) => {
    const config = getConfig();

    if (!config.enabled) return;

    const {
      value,
      modifiers,
    } = binding;
    const actions = Object.keys(modifiers);

    // Defaults to click
    if (actions.length === 0) actions.push('click');

    actions.forEach((action) => {
      el.addEventListener(action, () => {
        const fn = config.vDirectives[action];

        if (!fn) {
          log('The argument passed to the v-gtm directive (i.e. v-gtm:[some-argument]) is not defined in the vDirectives list.');
          return;
        }

        fn.apply(vnode.context, [value]);
      });
    });
  },
};

export default {
  gtm,
};
