import Vue from 'vue';
import api from '../utils/api';

const initialState = () => ({
  activeSubscription: null,
  permission: null,
  reg: null,
  isOffline: false,
  updateAvailable: false
});

const supportsNotifications = () => 'Notification' in window;
const supportsWebPush = () => 'PushManager' in window;

const getters = {
  activeSubscription: s => s.activeSubscription,
  hasActiveSubscription: s => !!s.activeSubscription,
  permission: s => s.permission,
  reg: s => s.reg,
  supportsWebPush: s => supportsWebPush() && !!s.reg,
  isOffline: s => s.isOffline,
  updateAvailable: s => s.updateAvailable
};

const actions = {
  /**
   * @returns {Promise<PushSubscription | null>}
   */
  async getActiveSubscription({ getters: allGetters }) {
    const { reg } = allGetters;
    const subscription = await reg.pushManager.getSubscription();
    return subscription;
  },

  async registered({ commit, dispatch }, { reg }) {
    commit('SET_REG', { reg });
    await dispatch('registerWebPush', { reg });
  },

  /**
   * @param commit
   * @param dispatch
   * @param reg
   * @returns {Promise<void>}
   */
  async registerWebPush({ commit, dispatch }, { reg }) {
    if (!supportsWebPush()) return;

    const permission = await reg.pushManager.permissionState({ userVisibleOnly: true });
    commit('SET_PERMISSION', permission);

    const subscription = await reg.pushManager.getSubscription();
    if (!subscription) return;

    const isValid = await dispatch('validateSubscription', { subscription });
    if (!isValid) return;

    commit('SET_ACTIVE_SUBSCRIPTION', subscription);
  },

  /**
   * @param ctx
   * @param subscription
   * @returns {Promise<boolean>}
   */
  async validateSubscription(ctx, { subscription }) {
    try {
      await api.get(`validate_push_subscription?endpoint=${subscription.endpoint}`);
      return true;
    } catch (e) {
      if (e.httpStatus === 404) {
        /**
         * If not found, this subscription has been deleted from
         * another device. Hence, we need to unsubscribe from it.
         */
        try { await subscription.unsubscribe(); } catch (k) {}
      }

      return false;
    }
  },

  async ready({ commit, dispatch }, { reg }) {
    // Worker is ready
    commit('SET_REG', { reg });
  },

  async updated({ commit, dispatch }, { reg }) {
    commit('SET_UPDATE_AVAILABLE', { reg });
  },

  /**
   * @returns {Promise<boolean>}
   */
  async subscribeToPushNotifications({ dispatch }) {
    await dispatch('wait/start', 'subscribing to push notifications', { root: true });

    const permission = await dispatch('requestPermission');
    if (permission !== 'granted') return false;

    let success = false;

    try {
      const subscription = await dispatch('subscribe');
      success = await dispatch('createUserSubscription', { subscription });
    } catch (e) {}

    await dispatch('wait/end', 'subscribing to push notifications', { root: true });

    return success;
  },

  /**
   * @returns {Promise<boolean>}
   */
  async unsubscribePushNotification({ dispatch }) {
    await dispatch('wait/start', 'unsubscribing from push notifications', { root: true });

    const subscription = await dispatch('getActiveSubscription');
    if (!subscription) return false;

    let success = false;

    try {
      success = await dispatch('destroyUserSubscription', { subscription });
      await dispatch('unsubscribe', { subscription });
    } catch (e) {}

    await dispatch('wait/end', 'unsubscribing from push notifications', { root: true });

    return success;
  },

  /**
   * @param commit
   * @returns {Promise<null|"default"|"denied"|"granted">}
   */
  async requestPermission({ commit }) {
    if (!supportsNotifications) return null;

    const permission = await Notification.requestPermission();
    commit('SET_PERMISSION', permission);
    return permission;
  },

  /**
   * @returns {Promise<PushSubscriptionJSON | null>}
   * @throws Exception
   */
  async subscribe({ getters: allGetters, commit, dispatch }) {
    const { reg } = allGetters;
    const publicVapidKey = await dispatch('getPublicVapidKey');

    const pushSubscription = await reg
      .pushManager
      .subscribe({
        userVisibleOnly: true,
        applicationServerKey: new Uint8Array(publicVapidKey),
      });

    commit('SET_ACTIVE_SUBSCRIPTION', pushSubscription);
    const subscription = pushSubscription.toJSON();

    this.$mixpanel.trackEvent('Subscribe to Web Push Notifications');

    return subscription;
  },

  /**
   * @returns {Promise<void>}
   * @throws Exception
   */
  async unsubscribe({ commit }, { subscription }) {
    await subscription.unsubscribe();
    commit('SET_ACTIVE_SUBSCRIPTION', null);
    this.$mixpanel.trackEvent('Unsubscribe from Web Push Notifications');
  },

  async getPublicVapidKey({ rootGetters }) {
    const registrationHash = rootGetters['registration/user/registrationHash'];

    try {
      let params = {};

      if (registrationHash) {
        params = {
          ...params,
          h: registrationHash,
        };
      }

      const r = await api.get('public_vapid_key', params);

      return r.data;
    } catch (e) {
      return null;
    }
  },

  /**
   * @returns {Promise<boolean>}
   */
  async createUserSubscription({ dispatch, rootGetters }, { subscription }) {
    const params = {
      payload: {
        user: {
          pushSubscriptionsAttributes: [
            {
              endpoint: subscription.endpoint,
              expirationTime: subscription.expirationTime,
              auth: subscription.keys.auth,
              p256dh: subscription.keys.p256dh,
              userAgent: navigator.userAgent,
            },
          ],
        },
      },
    };

    const registrationHash = rootGetters['registration/user/registrationHash'];
    let success = false;

    try {
      if (registrationHash) { // User is registering...
        success = await dispatch('registration/user/updateUser', params, { root: true });
      } else { // Regular currentUser update
        success = await dispatch('currentUser/updateUser', params.payload, { root: true });
      }
    } catch (e) {}

    return success;
  },

  /**
   * @returns {Promise<boolean>}
   */
  async destroyUserSubscription({ dispatch }, { subscription }) {
    const userSubscription = this
      .$currentUser
      .pushSubscriptions
      .find(p => p.endpoint === subscription.endpoint);

    /**
     * We can safely return true even though the
     * subscription is not present on the $currentUser.
     */
    if (!userSubscription) return true;

    const payload = {
      user: {
        pushSubscriptionsAttributes: [
          {
            id: userSubscription.id,
            _destroy: true,
          },
        ],
      },
    };

    const success = await dispatch('currentUser/updateUser', payload, { root: true });

    return success;
  },

  setOffline({ commit }, params) {
    commit('SET_OFFLINE', params);
  },
};

const mutations = {
  SET_ACTIVE_SUBSCRIPTION(state, subscription) {
    Vue.set(state, 'activeSubscription', subscription);
  },

  SET_PERMISSION(state, permission) {
    Vue.set(state, 'permission', permission);
  },

  SET_UPDATE_AVAILABLE(state, { reg }) {
    Vue.set(state, 'reg', reg);
    Vue.set(state, 'updateAvailable', true);
  },

  SET_REG(state, { reg }) {
    Vue.set(state, 'reg', reg);
    // Update update available in case there are waiting
    // service workers.
    Vue.set(state, 'updateAvailable', !!reg.waiting);
  },

  SET_OFFLINE(state, { isOffline }) {
    Vue.set(state, 'isOffline', isOffline);
  },
};

export default {
  state: initialState(),
  getters,
  actions,
  mutations,
};
