<template>
  <v-card
    tile
    class="backgroundAccent lighten-4 d-flex flex-column"
  >
    <v-card-title
      class="title lighten-4 white"
    >
      <span
        v-translate
        translate-context="Dialog title"
      >
        Sort spaces
      </span>

      <v-spacer />

      <v-btn
        :disabled="$wait.is('batch saving spaces')"
        icon
        @click="closeDialog"
      >
        <v-icon>
          close
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-subtitle
      v-translate
      translate-context="Dialog subtitle"
      class="white pr-16 pt-4"
    >
      Change space order by dragging them to a new position. Saving
      may take some time when there are many spaces.
    </v-card-subtitle>

    <v-divider />

    <v-card-text
      class="pt-4"
    >
      <v-list
        dense
        tile
        class="py-0 mx-auto spaces relative"
        :max-width="`${WIDTH}px`"
        :height="HEIGHT * spaces.length"
      >
        <v-list-item
          v-for="(space, i) in spaces"
          :ref="`space-${space.id}`"
          :key="i"
          class="space-list-item draggable"
          :style="{
            borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
            position: 'absolute',
            left: 0,
            right: 0,
            top: `${space.order * HEIGHT}px`,
          }"
          :data-coord-y="space.order"
        >
          <v-list-item-avatar
            class="drag-handle"
          >
            <v-icon>
              drag_handle
            </v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>
              {{ space.address }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>

    <v-spacer />

    <v-card-actions
      class="white elevation-6"
    >
      <v-btn
        :disabled="$wait.is('batch saving spaces')"
        text
        color="error"
        @click="closeDialog"
      >
        <span v-translate>
          Cancel
        </span>
      </v-btn>

      <v-spacer />

      <v-btn
        :loading="$wait.is('batch saving spaces')"
        :disabled="$wait.is('batch saving spaces')"
        color="success"
        class="px-10"
        @click="confirm"
      >
        <span v-translate>
          Save
        </span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import interact from 'interactjs';
  import { mapGetters, mapActions } from 'vuex';
  import { mapWaitingActions } from 'vue-wait';

  export default {
    data: () => ({
      WIDTH: 300,
      HEIGHT: 57,
      TARGET_CLASS: '.space-list-item',
    }),

    computed: {
      ...mapGetters({
        dialogProps: 'dialog/dialogProps',
      }),

      spaces() {
        return this.dialogProps.spaces;
      },
    },

    mounted() {
      this.init();
    },

    beforeDestroy() {
      this.unset();
    },

    methods: {
      ...mapActions({
        closeDialog: 'dialog/closeDialog',
      }),

      ...mapWaitingActions('projectEditor/spaces', {
        batchUpdateSpaces: 'batch saving spaces',
      }),

      unset() {
        interact(this.TARGET_CLASS).unset();
      },

      init() {
        this.unset();

        interact(this.TARGET_CLASS)
          .draggable({
            allowFrom: '.drag-handle',
            modifiers: [
              interact.modifiers.snap({
                targets: [
                  interact.createSnapGrid({ x: this.WIDTH, y: this.HEIGHT }),
                ],
                range: Infinity,
                offset: 'startCoords',
              }),
              interact.modifiers.restrict({
                restriction: 'parent',
                elementRect: {
                  top: 0, left: 0, bottom: 1, right: 1,
                },
              }),
            ],
            listeners: {
              move: (event) => {
                const {
                  dy,
                  target: draggedTarget,
                } = event;

                const coordY = parseFloat(draggedTarget.dataset.coordY);
                const newCoordY = coordY + (dy / this.HEIGHT);

                const draggedEl = document.querySelector(`.spaces [data-coord-y="${coordY}"]`);
                const affectedEl = document.querySelector(`.spaces [data-coord-y="${newCoordY}"]`);

                const draggedTop = parseInt(draggedEl.style.top.replace('px', ''), 10);
                const affectedTop = parseInt(affectedEl.style.top.replace('px', ''), 10);

                Object.assign(draggedEl.style, {
                  top: `${affectedTop}px`,
                });

                Object.assign(affectedEl.style, {
                  top: `${draggedTop}px`,
                });

                draggedEl.setAttribute('data-coord-y', newCoordY);
                affectedEl.setAttribute('data-coord-y', coordY);
              },
            },
          });
      },

      async confirm() {
        const payload = this.spaces.reduce((acc, space) => {
          const target = this.$refs[`space-${space.id}`][0].$el;

          const {
            coordY,
          } = target.dataset;

          // Update only the changed records
          if (space.order !== parseInt(coordY, 10)) {
            acc.push({
              id: space.id,
              order: coordY,
            });
          }

          return acc;
        }, []);

        await Promise.all([
          this.batchUpdateSpaces({
            spaces: payload,
          }),
        ]);

        this.$mixpanel.trackEvent('Space Reordering Dialog', {
          step: 'Save Changes',
        });

        this.closeDialog();
      },
    },
  };
</script>
