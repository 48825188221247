<template>
  <v-menu
    offset-y
  >
    <template v-slot:activator="{ on: menu, attrs }">
      <v-tooltip
        bottom
        :disabled="!showTooltip"
      >
        <template v-slot:activator="{ on: tooltip }">
          <v-list-item
            v-bind="attrs"
            two-line
            class="mb-0"
            v-on="{ ...tooltip, ...menu }"
          >
            <v-list-item-content
              v-if="currentProject.pid"
            >
              <v-list-item-title
                style="margin-top: 3px;"
              >
                {{ currentProject.pid }}
              </v-list-item-title>
              <v-list-item-subtitle
                class="caption-xs text-uppercase"
              >
                {{ currentProject.address }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>{{ companyName }}</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-content
              v-else
            >
              <v-list-item-title>{{ text }}</v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <v-icon>
                keyboard_arrow_down
              </v-icon>
            </v-list-item-action>
          </v-list-item>
        </template>

        <span>{{ text }}</span>
      </v-tooltip>
    </template>

    <v-list
      dense
      subheader
      max-height="80vh"
      class="overflow-y-auto"
    >
      <v-list-item
        :to="{ name: 'projects-list' }"
        exact
      >
        <v-list-item-content>
          <v-list-item-title v-translate>
            All projects
          </v-list-item-title>
        </v-list-item-content>

        <v-list-item-action>
          <v-icon>keyboard_arrow_right</v-icon>
        </v-list-item-action>
      </v-list-item>

      <v-divider />

      <template
        v-for="(company, i) in companies"
      >
        <v-divider
          v-if="i > 0"
          :key="`divider-${company.id}`"
          class="mt-4"
        />

        <v-subheader
          :key="company.id"
          class="pl-4 mt-4"
        >
          {{ company.name }}
        </v-subheader>

        <v-list-item
          v-for="project in projectsByCompanyId[company.id].slice(0, 5)"
          :key="`company-${company.id}-${project.id}`"
          :to="{
            name: 'single-project',
            params: {
              projectId: project.id,
            }
          }"
        >
          <v-list-item-content>
            <v-list-item-title>
              {{ project.pid }}
            </v-list-item-title>

            <v-list-item-subtitle>
              {{ project.address }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-menu>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    props: {
      projects: {
        type: Array,
        required: true,
      },

      projectId: {
        type: [Number, String],
        default: null,
      },

      showTooltip: {
        required: true,
        validator: prop => typeof prop === 'boolean' || prop === null,
      },
    },

    computed: {
      ...mapGetters({
        companies: 'companies/companies',
        projectsByCompanyId: 'projects/projectsByCompanyId',
      }),

      currentProject() {
        const projectId = Number.parseInt(this.projectId, 10);
        const p = this.projects.find(({ id }) => id === projectId);

        return p || {};
      },

      text() {
        if (this.currentProject.pid) return this.$pgettext('Project selector', 'Switch project');

        return this.$pgettext('Project selector', 'Choose a project');
      },

      companyName() {
        const company = this.companies.find(c => c.id === this.currentProject.companyId);
        if (!company) return '';
        return company.name;
      },
    },
  };
</script>
