import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import router from '@/router';
import { getEnv } from '@/utils/environment';

export default {
  init: () => {
    // Initialize error monitoring for frontend
    Sentry.init({
      Vue,
      dsn: 'https://559b75b89f374583aa27fcfe2d9d58c2@o1010520.ingest.sentry.io/5990907',
      environment: getEnv('VUE_APP_ENV'),
      integrations: [
        new Integrations.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracingOrigins: ['localhost', 'app.mestamaster.fi', 'demo.mestamaster.fi', /^\//],
        }),
      ],
      // Set tracesSampleRate to 1.0 to capture 100% transactions
      // We're now capturing 10% in all monitored environments
      tracesSampleRate: ['production', 'demo'].includes(getEnv('VUE_APP_ENV')) ? 0.1 : 0,

      // Track the release through the SHA of the build
      // Process env is on purpose so that even if the config has a new version and the client is using 
      // the old version (ie, not yet updated), we correctly see which version is actually running - the version
      // in which the frontend was built with
      release: process.env.VUE_APP_VERSION,
      /**
       * If you enable the SDK, Vue will not call its logError internally. As a result, errors
       * occurring in the Vue renderer will not display in the developer console. To preserve
       * this functionality, pass the logErrors: true option.
       */
      logErrors: true,

      beforeSend(event) {
        // Modify the event here
        if (!(['production', 'demo'].includes(event.environment))) {
          // Don't send events on non monitored environments
          console.log(`Skipped sentry report - ${event.environment} environment is not monitored`); // eslint-disable-line
          return null;
        }
        return event;
      },
    });
  },
};
