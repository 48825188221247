import Vue from 'vue';
import GetTextPlugin from 'vue-gettext';
import translations from './i18n/translations.json';
import { cachedLocale, cacheLocale } from '@/vuex/utils/localStorage';

const languages = () => ([
  {
    label: 'Suomi',
    locale: 'fi',
    localeLong: 'fi-FI',
    default: true,
  },
  {
    label: 'English (UK)',
    locale: 'en',
    localeLong: 'en-GB',
  },
  {
    label: 'Russian',
    locale: 'ru',
    localeLong: 'ru',
  },
]);

const availableLanguages = languages()
  .reduce((acc, { localeLong, label }) => ({
    ...acc,
    [localeLong]: label,
  }), {});

const findLang = (l) => {
  let selectedLang = l
  if (selectedLang == "en-UK") {
    // Legacy patch because en-UK is not valid locale
    selectedLang = "en";
  }
  return languages().find(lang => lang.localeLong === selectedLang || lang.locale === selectedLang);
};

const currentLanguage = () => {
  let lang;
  if (cachedLocale()) {
    lang = findLang(cachedLocale());
  } else {
    lang = languages().find(l => l.default);
  }
  return lang;
};

Vue.use(GetTextPlugin, {
  availableLanguages,
  languageVmMixin: {
    computed: {
      currentLocale() {
        return findLang(this.current).locale;
      },

      currentLocaleLong() {
        return findLang(this.current).localeLong;
      },
    },

    created() {
      if (!cachedLocale()) {
        cacheLocale(findLang(this.current).locale);
      }
    },

    watch: {
      async current(newLocale) {
        cacheLocale(findLang(newLocale).locale);
      },
    },
  },
  defaultLanguage: currentLanguage().localeLong,
  translations,
  silent: true,
  autoAddKeyAttributes: true,
});

export {
  findLang,
  currentLanguage,
};
