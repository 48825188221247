import Vue from 'vue';
import Vuex from 'vuex';
import api from '../utils/api';
import apiV3 from '../utils/apiV3';

import {
  setAccessToken,
  removeAccessToken,
  getAccessToken,
} from '../utils';
import { findLang } from '@/plugins/i18n';
import * as Sentry from '@sentry/vue';
import { translate } from 'vue-gettext';
const { pgettext: $pgettext } = translate;


const initialState = () => ({
  data: {
    permissions: [],
    unreadMessages: [],
    pushSubscriptions: [],
  },
  features: {},
  accessToken: null
});

const getters = {
  authenticated: state => !!state.accessToken,
  user: state => state.data,
  unreadMessages: (_, allGetters) => allGetters.user.unreadMessages,
  enabledFeatures: (_, allGetters) => allGetters.user.enabledFeatures,
};

const actions = {
  async onSuccessfulLogin({ commit, dispatch, rootGetters }, response) {
    commit('SET_USER', response);
    Sentry.setUser({ id: response.data.id });
    this.$gtm.trackUser();

    if (!response.data.gdprConsent) {
      const isMobile = rootGetters['utils/isMobile'];

      dispatch('dialog/openDialog', {
        dialogComponent: 'm-accept-gdpr-dialog',
        config: {
          fullscreen: isMobile,
        },
      }, { root: true });
    }
  },

  async register({ commit, dispatch }, params) {
    try {
      const r = await apiV3.post('user', params);
      commit('SET_AUTH_TOKEN', {accessToken: r.meta.accessToken});
      await dispatch('onSuccessfulLogin', r);
      return true;
    } catch (e) {
      return false;
    }
  },

  async login({ dispatch }, params) {
    try {
      const r = await api.post('login', params);

      await dispatch('onSuccessfulLogin', r);

      return true;
    } catch (e) {
      if (e.httpStatus === 401) {
        await dispatch('snackbar/addNotification', {
          header: $pgettext('User messages incorrect login header', 'Incorrect login credentials'),
          msg: $pgettext('User messages incorrect login message', 'The credentials you provided are not valid. Please try again. If you forgot your password you can reset it by clicking "Password Lost?"'),
          type: 'warning',
        }, { root: true });
      }
      return false;
    }
  },

  async logout({ commit }) {
    if (!getAccessToken()) {
      commit('LOGOUT');
      return true;
    }

    try {
      await api.delete('logout');
    } catch {} finally {
      commit('LOGOUT');
    }

    return true;
  },

  async autoLogin({ dispatch }, { token }) {
    setAccessToken(token);
    const success = await dispatch('auth');
    return success;
  },

  async auth({ dispatch, commit }) {
    if (!getAccessToken()) {
      commit('LOGOUT');
      return false;
    }

    try {
      const r = await api.get('auth');

      await dispatch('onSuccessfulLogin', r);

      return true;
    } catch (e) {
      commit('LOGOUT');
      return false;
    }
  },

  async authWithToken({ commit }, token) {
    try {
      const r = await api.get('auth_token', token);

      commit('SET_USER', {
        data: r.data,
        saveAccessToken: false,
      });
    } catch (e) {}
  },

  async updateUser({ commit }, payload) {
    try {
      const r = await api.update('update_current_user', payload);

      commit('SET_USER', r);

      return true;
    } catch (e) {
      return false;
    }
  },

  async resetPassword({ dispatch }, { payload }) {
    try {
      const r = await api.update('reset_password', payload);

      await dispatch('onSuccessfulLogin', r);

      return true;
    } catch (e) {
      return false;
    }
  },

  async messagesSeen({ commit }, payload) {
    try {
      const r = await api.update('messages_seen', payload);
      commit('SET_USER', r);
    } catch (e) {}
  },

  async sendPasswordReset(ctx, payload) {
    try {
      await api.post('send_password_reset', payload);
      return true;
    } catch (e) {
      return false;
    }
  },
};

const mutations = {

  SET_AUTH_TOKEN(state, {accessToken}) {
    Vue.set(state, 'accessToken', accessToken);
    setAccessToken(accessToken);
  },

  SET_USER(state, { data, saveAccessToken = true }) {
    if (saveAccessToken && data.accessToken) {
      Vue.set(state, 'accessToken', data.accessToken);
      setAccessToken(data.accessToken);
    }

    if (data.lang) {
      const lang = findLang(data.lang).localeLong;
      Vue.config.language = lang;
    }

    Vue.prototype.$currentUser = data;
    Vuex.Store.prototype.$currentUser = data;

    Vue.set(state, 'data', data);
  },

  LOGOUT(state) {
    removeAccessToken();
    Vue.set(state, 'data', initialState().data);
    Vue.set(state, 'accessToken', initialState().accessToken);

    Vue.prototype.$currentUser = initialState().data;
    Vuex.Store.prototype.$currentUser = initialState().data;
  },
};

export default {
  state: initialState(),
  getters,
  actions,
  mutations,
};
