import Vue from 'vue';
import api from '../utils/api';

const initialState = () => ({
  logs: [],
});

const getters = {
  logs: state => state.logs,
  unseenLogs: (_, allGetters) => allGetters.logs.filter(l => !l.acknowledged),
};

const actions = {
  async loadNotificationLogs({ commit }) {
    try {
      const r = await api.get(`users/${this.$currentUser.id}/notification_logs`);

      commit('SET_LOGS', r);
    } catch (e) {}
  },

  async acknowledgeLog({ commit }, { id }) {
    try {
      const params = {
        notificationLog: {
          state: 'acknowledged',
        },
      };

      const r = await api.update(`users/${this.$currentUser.id}/notification_logs/${id}`, params);

      commit('UPDATE_LOG', r);
    } catch (e) {}
  },
};

const mutations = {
  SET_LOGS(state, { data }) {
    Vue.set(state, 'logs', data);
  },

  UPDATE_LOG(state, { data }) {
    const logs = state.logs.map((log) => {
      if (log.id === data.id) return data;
      return log;
    });
    Vue.set(state, 'logs', logs);
  },
};

export default {
  state: initialState(),
  getters,
  actions,
  mutations,
};
