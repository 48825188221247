import Vue from 'vue';
import { translate } from 'vue-gettext';
import {
  appendItemToArraySortByKey,
} from '@/vuex/utils/helpers';
import api from '../../utils/api';

const { pgettext: $pgettext } = translate;

const initialState = () => ({
  workPackages: [],
});

const getters = {
  workPackages: state => state.workPackages,
  workPackageById: (_, allGetters) => workPackageId => (
    allGetters.workPackages.find(wp => wp.id === workPackageId)
  ),
  workPackagesByPassageId: (_, allGetters) => passageId => (
    allGetters.workPackages.filter(wp => wp.passageId === passageId)
  ),
  previousWorkPackageInOrder: (_, allGetters) => (passageId, order) => {
      const wps = allGetters.workPackagesByPassageId(passageId).filter(wp => wp.order < order);
      if (wps.length <= 0) return null;
      return wps[wps.length - 1];
    },
  nextWorkPackageInOrder: (_, allGetters) => (passageId, order) => {
      const wps = allGetters.workPackagesByPassageId(passageId).filter(wp => wp.order > order);
      if (wps.length <= 0) return null;
      return wps[0];
    },
};

const actions = {
  async loadWorkPackages({ commit }, { passageId } = { passageId: null }) {
    try {
      let url = `projects/${this.$projectId}/work_packages?`;
      if (passageId) url += `passage_id=${passageId}`;
      const r = await api.get(url);

      commit('SET_WORK_PACKAGES', r);
    } catch (e) {}
  },

  async createWorkPackage({ commit, getters: allGetters, dispatch }, payload) {
    const {
      passageId,
    } = payload.workPackage;

    let nextOrder = 0;
    const workPackages = allGetters.workPackagesByPassageId(passageId);

    if (workPackages.length > 0) {
      nextOrder = allGetters.workPackagesByPassageId(passageId)
        .reduce((maxOrder, workPackage) => Math.max(maxOrder, workPackage.order), 0) + 1;
    }

    const params = {
      workPackage: {
        ...payload.workPackage,
        order: nextOrder,
      },
    };

    try {
      const r = await api.post(`projects/${this.$projectId}/work_packages`, params);

      commit('ADD_TO_WORK_PACKAGES', r);

      // The takt structure might have changed, so let's reload it
      dispatch('project/tacts/loadTacts', null, { root: true });

      await dispatch('snackbar/addNotification', {
        msg: $pgettext('Snackbar notification', 'Work package saved'),
        type: 'success',
      }, { root: true });
    } catch (e) {
      await dispatch('snackbar/addNotification', {
        msg: $pgettext('Snackbar notification', 'Saving failed'),
        type: 'error',
      }, { root: true });
    }
  },

  async updateWorkPackage({ commit, dispatch }, { id, payload, notify = true }) {
    try {
      const r = await api.update(`projects/${this.$projectId}/work_packages/${id}`, payload);

      commit('UPDATE_WORK_PACKAGE', r);

      if (notify) {
        await dispatch('snackbar/addNotification', {
          msg: $pgettext('Snackbar notification', 'Work package saved'),
          type: 'success',
        }, { root: true });
      }
    } catch (e) {
      await dispatch('snackbar/addNotification', {
        msg: $pgettext('Snackbar notification', 'Saving failed'),
        type: 'error',
      }, { root: true });
    }
  },

  async batchUpdateWorkPackages({ commit, dispatch }, payload) {
    try {
      const r = await api.post(`projects/${this.$projectId}/work_packages/batch_update`, payload);
      commit('UPDATE_WORK_PACKAGES', r);
    } catch (e) {
      await dispatch('snackbar/addNotification', {
        msg: $pgettext('Snackbar notification', 'Saving failed'),
        type: 'error',
      }, { root: true });
    }
  },

  async destroyWorkPackage({ dispatch }, { id }) {
    try {
      await api.delete(`projects/${this.$projectId}/work_packages/${id}`);

      // The takt structure might have changed, so let's reload it
      dispatch('project/tacts/loadTacts', null, { root: true });

      await dispatch('snackbar/addNotification', {
        msg: $pgettext('Snackbar notification', 'Work package deleted'),
        type: 'success',
      }, { root: true });
    } catch (e) {}
  },
};

const mutations = {
  SET_WORK_PACKAGES(state, { data }) {
    Vue.set(state, 'workPackages', data);
  },

  ADD_TO_WORK_PACKAGES(state, { data }) {
    const wps = appendItemToArraySortByKey({
      inputArray: state.workPackages,
      item: data,
      key: 'order',
    });

    Vue.set(state, 'workPackages', wps);
  },

  UPDATE_WORK_PACKAGE(state, { data }) {
    const wps = state.workPackages.map(t => (
      t.id === data.id ? data : t
    ));

    Vue.set(state, 'workPackages', wps);
  },

  UPDATE_WORK_PACKAGES(state, { data: newWps }) {
    newWps.forEach((newWp) => {
      const i = state.workPackages.findIndex(t => t.id === newWp.id);

      if (i < 0) return;

      Vue.set(state.workPackages, i, newWp);
    });
  },
};

export default {
  state: initialState(),
  getters,
  actions,
  mutations,
};
