<template>
  <v-card>
    <v-card-title class="title lighten-4 white">
      <span v-translate translate-context="MReorderWorkPackagesAndTeams Dialog title">
        Sort work packages and teams
      </span>

      <v-spacer />

      <v-btn
        :disabled="$wait.is(['batch saving workPackages', 'batch saving teams'])"
        icon
        @click="closeDialog"
      >
        <v-icon> close </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-subtitle
      v-translate
      translate-context="MReorderWorkPackagesAndTeams Dialog subtitle"
      class="white pr-16 pt-4"
    >
      Change the order by dragging an item to a new position.
    </v-card-subtitle>
    <v-card-text>
      <v-tabs grow background-color="primary lighten-1" dark v-model="tab">
        <v-tab v-translate key="wp-sort">Work packages </v-tab>
        <v-tab v-translate key="team-sort">Teams</v-tab>
        <v-tabs-items v-model="tab">
          <v-tab-item key="wp-sort">
            <v-card color="backgroundAccent lighten-3">
              <v-card-text>
                <v-list
                  dense
                  tile
                  class="py-0 mx-auto teams relative"
                  :max-width="`${WIDTH}px`"
                  :height="HEIGHT * wpData.length"
                >
                  <draggable
                    v-model="wpData"
                    group="teams"
                    @start="drag = true"
                    @end="drag = false"
                    ghost-class="ghost"
                  >
                    <v-list-item
                      v-for="(wp, i) in wpData"
                      :ref="`wp-${wp.id}`"
                      :key="i"
                      class="wp-list-item draggable"
                      :style="{
                        borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                      }"
                      :data-coord-y="wp.order"
                    >
                      <v-list-item-avatar class="drag-handle">
                        <v-icon> drag_handle </v-icon>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title>
                          {{ wp.name }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </draggable>
                </v-list>
              </v-card-text>

              <v-card-actions class="white">
                <v-btn
                  :disabled="$wait.is('batch saving workPackages')"
                  text
                  color="error"
                  @click="closeDialog"
                >
                  <span v-translate> Cancel </span>
                </v-btn>

                <v-spacer />

                <v-btn
                  :loading="$wait.is('batch saving workPackages')"
                  :disabled="$wait.is('batch saving workPackages')"
                  color="success"
                  class="px-10"
                  @click="saveWorkPackages"
                >
                  <span v-translate> Save </span>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-tab-item>
          <v-tab-item key="team-sort">
            <v-card color="backgroundAccent lighten-3">
              <v-card-subtitle>
                <v-alert text type="info" class="caption mb-0" v-translate>
                  Note that the order here (top-to-bottom) is the same as left-to-right behind this
                  window.
                </v-alert>
              </v-card-subtitle>
              <v-card-text>
                <v-list
                  dense
                  tile
                  class="py-0 mx-auto teams relative"
                  :max-width="`${WIDTH}px`"
                  :height="HEIGHT * teamsData.length"
                >
                  <draggable
                    v-model="teamsData"
                    group="teams"
                    @start="drag = true"
                    @end="drag = false"
                    ghost-class="ghost"
                  >
                    <v-list-item
                      v-for="(team, i) in teamsData"
                      :ref="`team-${team.id}`"
                      :key="i"
                      class="team-list-item draggable"
                      :style="{
                        borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                      }"
                      :data-coord-y="team.order"
                    >
                      <v-list-item-avatar class="drag-handle">
                        <v-icon> drag_handle </v-icon>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title>
                          {{ team.name }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </draggable>
                </v-list>
              </v-card-text>
              <v-card-actions class="white">
                <v-btn
                  :disabled="$wait.is('batch saving teams')"
                  text
                  color="error"
                  @click="closeDialog"
                >
                  <span v-translate> Cancel </span>
                </v-btn>

                <v-spacer />

                <v-btn
                  :loading="$wait.is('batch saving teams')"
                  :disabled="$wait.is('batch saving teams')"
                  color="success"
                  class="px-10"
                  @click="saveTeams"
                >
                  <span v-translate> Save </span>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </v-card-text>
  </v-card>
</template>

<script>
import interact from "interactjs";
import { mapGetters, mapActions } from "vuex";
import { mapWaitingActions } from "vue-wait";
import draggable from "vuedraggable";

export default {
  data: () => ({
    WIDTH: 300,
    HEIGHT: 57,
    TARGET_CLASS_WP: ".wp-list-item",
    TARGET_CLASS_TEAM: ".team-list-item",
    tab: 0,
    dragging: false,
    teamsData: [],
    wpData: [],
  }),

  components: {
    draggable,
  },

  computed: {
    ...mapGetters({
      dialogProps: "dialog/dialogProps",
    }),

    workPackages() {
      return this.dialogProps.workPackages;
    },

    teams() {
      return this.dialogProps.teams;
    },

    onAfterTeamsSave() {
      return this.dialogProps.onAfterTeamsSave;
    },
  },

  mounted() {
    this.init();
  },

  beforeDestroy() {
    this.unset();
  },

  methods: {
    ...mapActions({
      closeDialog: "dialog/closeDialog",
    }),

    ...mapWaitingActions("projectEditor/workPackages", {
      batchUpdateWorkPackages: "batch saving workPackages",
    }),

    ...mapWaitingActions("projectEditor/tasks", {
      recalculateTasksYPositions: "batch saving workPackages",
    }),

    ...mapWaitingActions("projectEditor/teams", {
      batchUpdateTeams: "batch saving teams",
    }),

    unset() {
      interact(this.TARGET_CLASS_WP).unset();
      interact(this.TARGET_CLASS_TEAM).unset();
    },

    init() {
      this.unset();
      this.teamsData = this.teams.map((team) => ({
        id: team.id,
        order: team.order,
        name: team.name,
      }));
      this.wpData = this.workPackages.map((wp) => ({
        id: wp.id,
        order: wp.order,
        name: wp.name,
      }));
    },

    async saveWorkPackages() {
      const payload = this.wpData.map((wp, index) => {
        if (wp.order === index) {
          return null;
        } else {
          return {
            id: wp.id,
            order: index,
          };
        }
      });

      if (payload.length === 0) {
        this.closeDialog();
        return;
      }
      await Promise.all([
        this.batchUpdateWorkPackages({
          workPackages: payload.filter((wp) => wp !== null),
        }),
      ]);

      await this.recalculateTasksYPositions();

      this.$mixpanel.trackEvent("Workpackage Reordering Dialog", {
        step: "Save Changes",
      });

      this.closeDialog();
    },

    async saveTeams() {
      const teamsToUpdate = this.teamsData.map((team, index) => {
        if (team.order === index) {
          return null;
        } else {
          return {
            id: team.id,
            order: index,
          };
        }
      });
      if (teamsToUpdate.length === 0) {
        this.closeDialog();
        return;
      }
      await Promise.all([
        this.batchUpdateTeams({
          teams: teamsToUpdate.filter((team) => team !== null),
        }),
      ]);

      this.$mixpanel.trackEvent("Teams Reordering Dialog", {
        step: "Save Changes",
      });

      await this.onAfterTeamsSave();

      this.closeDialog();
    },
  },
};
</script>

<style scoped>

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>