import cloneDeep from 'lodash.clonedeep';

/*
  appendItemToArraySortyByKey appends an item into an array
  and sorts the array based on an item property (key).

  params:
    - inputArray
      - Array of objects to be updated with new items
      - Must consist of objects that have property 'key'
    - item
      - Object to append to 'inputArray'
      - Must be of the same type as items in 'inputArray'
    - key (optional)
      - Property on which the output array should be sorted with

  execution:
    - Creates output array 'items' by cloning 'inputArray' with cloneDeep function
    - Appends 'item' to output array 'items' with push function
    - Sorts output array 'items' based on param 'key'
    - Returns sorted output array 'items'

  return value:
    - 'inputArray' appended with 'item' sorted by property 'key'
*/
export const appendItemToArraySortByKey = ({
  inputArray, item, key = null,
}) => {
  const items = cloneDeep(inputArray);

  items.push(item);

  if (key === null) return items;

  items.sort((a, b) => a[key] - b[key]);

  return items;
};

/*
  updateItemsInArraySortByKey updates items of an array in place
  and sorts the array based on an item property (key).

  params:
    - inputArray
      - Array of objects to be updated with new items
      - Must consist of objects that have property 'key'
    - updatedItems
      - Array of updated objects
      - Must consist of objects that are the same type as 'inputArray'
    - key (optional)
      - Property on which the output array should be sorted with

  execution:
    - Creates output array 'items' by iterating over 'inputArray' with map function
    - Checks whether item in 'inputArray' is present in 'updatedItems' with findIndex function
    - If not found, keeps item from 'inputArray'
    - If found, uses item from 'updatedItems'
    - Sorts output array 'items' based on param 'key'
    - Returns sorted output array 'items'

  return value:
    - 'inputArray' updated based on 'updatedItems' sorted by property 'key'
*/
export const updateItemsInArraySortByKey = ({
  inputArray, updatedItems, key = null,
}) => {
  const items = inputArray.map((item) => {
    const index = updatedItems.findIndex(updatedItem => updatedItem.id === item.id);
    if (index < 0) return item;

    return updatedItems[index];
  });

  if (key === null) return items;

  items.sort((a, b) => a[key] - b[key]);

  return items;
};

export const toSnakeCase = str => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
