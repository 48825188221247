<template>
  <div>
    <div
      v-if="isDemoEnv"
      class="demo-env-notification"
    >
      {{ env }}
    </div>

    <router-view />
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';

  export default {
    computed: {
      ...mapGetters({
        isProductionEnv: 'utils/isProductionEnv',
        isDemoEnv: 'utils/isDemoEnv',
      }),

      env() {
        if (this.isDemoEnv) {
          return 'Demo';
        }

        return '';
      },
    },

    watch: {
      '$vuetify.breakpoint.xsOnly'(newVal) { // eslint-disable-line
        this.toggleIsMobile(newVal);
      },
    },

    created() {
      this.toggleIsMobile(this.$vuetify.breakpoint.xsOnly);
    },

    methods: {
      ...mapActions({
        toggleIsMobile: 'utils/toggleIsMobile',
      }),
    },
  };
</script>

<style lang="scss">
  @import './assets/styles/app.scss';
</style>

<style lang="scss" scoped>
  .demo-env-notification {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    font-size: 6px;
    background: rgba(red, 0.5);
    text-align: center;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
  }
</style>
