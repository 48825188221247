<template>
  <v-card
    tile
    class="d-flex flex-column"
    :class="{
      'pb-12': isMobile,
    }"
  >
    <v-card-title
      class="backgroundAccent"
    >
      Mestamaster <v-icon
        class="mx-3"
        color="error"
      >
        favorite
      </v-icon> Tietosuoja
    </v-card-title>

    <v-card-text
      class="pt-4"
      style="max-height: 80vh; overflow-y: auto;"
    >
      <p
        class="mb-0"
      >
        <b>
          Tehostimme tietosuojaasi.
        </b>
        <br><br>
        Olemme tehostaneet tietosuojaasi, jotta voit myös jatkossa käyttää
        Mestamasteria yhtä huoletta.
        <br><br>
        <b>
          Mestamaster on nyt EU:n tietosuoja-asetuksen mukainen.
        </b>
      </p>

      <v-divider
        class="my-4"
      />

      <p>
        Jotta voit jatkaa Mestamasterin käyttöä, <b>
          tarvitsemme hyväksyntäsi
          henkilötietojen käsittelyyn.
        </b>
      </p>

      <div>
        <a
          target="_blank"
          href="https://mestamaster.fi/tietosuojaseloste"
        >
          Avaa tietosuojaseloste
        </a>

        <v-icon
          class="ml-1"
          x-small
          style="vertical-align: text-top;"
        >
          open_in_new
        </v-icon>

        <v-checkbox
          v-model="gdprConsent"
          hint="Henkilötietoja käsitellään tietosuojaselosteen mukaisesti"
          persistent-hint
        >
          <template v-slot:label>
            <span
              class="caption"
            >
              Hyväksyn henkilötietojen käsittelyn
            </span>
          </template>
        </v-checkbox>
      </div>
    </v-card-text>

    <v-spacer />

    <v-card-text>
      <v-expansion-panels
        v-model="panel"
        :flat="panel === undefined"
      >
        <v-expansion-panel>
          <v-expansion-panel-header
            class="px-2 py-0 caption-xs"
            style="min-height: 32px;"
          >
            En hyväksy henkilötietojen käsittelyä
          </v-expansion-panel-header>

          <v-expansion-panel-content
            class="pa-2"
          >
            <div
              class="caption-xs"
              style="line-height: 16px;"
            >
              EU:n yleisen tietosuoja-asetuksen nojalla sinulla on oikeus pyytää sinua koskevien
              henkilötietojen poistamista.
              <br><br>
              Jos päätät pyytää henkilötietojesi poistamista, keskustele työnjohtajasi kanssa
              vaihtoehtoisista tavoista ohjata työtä.
              <br><br>
              Henkilötietojen poistamista varten pyytäisimme olemaan yhteydessä Mestamasterin
              asiakaspalveluun.
            </div>

            <v-btn
              outlined
              x-small
              class="ml-0 mt-4 mb-2"
              href="tel:+358 40 700 8296"
              color="primary"
            >
              Soita

              <v-icon
                right
                x-small
              >
                phone
              </v-icon>
            </v-btn>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>

    <v-card-actions
      class="backgroundAccent lighten-4 elevation-12"
      :style="{
        position: isMobile ? 'fixed' : 'relative',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 10,
      }"
    >
      <v-btn
        :disabled="$wait.is('logging out') || $wait.is('updating user')"
        :loading="$wait.is('logging out')"
        text
        color="warning"
        @click="onLogout"
      >
        Kirjaudu ulos
      </v-btn>

      <v-spacer />

      <v-btn
        :disabled="$wait.is('logging out') || $wait.is('updating user') || !gdprConsent"
        :loading="$wait.is('updating user')"
        color="primary"
        width="140px"
        @click="onSubmit"
      >
        Hyväksy
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import { mapWaitingActions } from 'vue-wait';

  export default {
    data: () => ({
      gdprConsent: false,
      panel: undefined,
    }),

    computed: {
      ...mapGetters({
        isMobile: 'utils/isMobile',
      }),
    },

    methods: {
      ...mapActions({
        closeDialog: 'dialog/closeDialog',
      }),

      ...mapWaitingActions('currentUser', {
        logout: 'logging out',
        updateUser: 'updating user',
      }),

      async onLogout() {
        await this.logout();

        this.$router.replace('/kirjaudu');
        this.closeDialog();
      },

      async onSubmit() {
        const success = await this.updateUser({
          user: {
            gdprConsent: this.gdprConsent,
          },
        });

        if (success) {
          this.closeDialog();
        }
      },
    },
  };
</script>
