import Vue from 'vue';
import { translate } from 'vue-gettext';
import api from '../utils/api';

const { pgettext: $pgettext } = translate;

const initialState = () => ({
  projects: [],
  userProjects: [],
});

const getters = {
  projects: state => state.projects,
  userProjects: state => state.userProjects,
  projectsByCompanyId: (a, allGetters, c, rootGetters) => { // eslint-disable-line
    const { projects } = allGetters;
    const companies = rootGetters['companies/companies'];

    const projectsByCompanyId = companies
      .reduce((acc, company) => {
        if (!acc[company.id]) acc[company.id] = [];

        const companyProjects = projects.filter(p => p.companyId === company.id);
        acc[company.id].push(...companyProjects);

        return acc;
      }, {});

    return projectsByCompanyId;
  },
};

const actions = {
  async loadProjects({ commit }) {
    try {
      const r = await api.get('projects');
      commit('SET_PROJECTS', r);
    } catch (e) {}
  },

  async loadUserProjects({ commit }, { userId }) {
    try {
      const r = await api.get(`projects?user_id=${userId}`);
      commit('SET_USER_PROJECTS', r);
    } catch (e) {}
  },

  async destroyProject({ commit, dispatch }) {
    try {
      const r = await api.delete(`projects/${this.$projectId}`);

      commit('DESTROY_PROJECT', r);

      dispatch(
        'snackbar/addSnackbarNotification',
        {
          msg: $pgettext('Snackbar notification', 'Project deleted'),
          type: 'success',
        }, {
          root: true,
        },
      );
    } catch (e) {}
  },
};

const mutations = {
  SET_PROJECTS(state, { data }) {
    Vue.set(state, 'projects', data);
  },

  SET_USER_PROJECTS(state, { data }) {
    Vue.set(state, 'userProjects', data);
  },

  DESTROY_PROJECT(state, { data }) {
    const projects = state.projects.filter(p => p.id !== data.id);
    Vue.set(state, 'projects', projects);
  },
};

export default {
  state: initialState(),
  getters,
  actions,
  mutations,
};
