<template>
  <v-snackbar
    :value="showNotification"
    :color="currentNotification.type"
    :timeout="5000"
    :top="isMobile"
    :bottom="!isMobile"
    right
    @input="onValueChange"
  >
    <div class="strong" :v-if="currentNotification.header">
      <h4>{{ currentNotification.header }}</h4>
    </div>

    <!-- To prevent xsx, we need to manually declare a notification htmlSafe -->
    <div v-if="currentNotification.htmlSafe" v-html="currentNotification.msg">
    </div>
    <div v-else>
      {{ currentNotification.msg }}
    </div>

    <template v-slot:action="{ attrs }">
      <v-btn
        text
        v-bind="attrs"
        @click="removeCurrentNotification"
      >
        <span v-translate>
          Close
        </span>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';

  export default {
    computed: {
      ...mapGetters({
        showNotification: 'snackbar/showNotification',
        currentNotification: 'snackbar/currentNotification',
        isMobile: 'utils/isMobile',
      }),
    },

    methods: {
      ...mapActions({
        removeCurrentNotification: 'snackbar/removeCurrentNotification',
      }),

      onValueChange(opening) {
        if (opening) return;

        this.removeCurrentNotification();
      },
    },
  };
</script>
