import { translate } from 'vue-gettext';

import Vue from 'vue';
import Router from 'vue-router';
import MRootLayout from '@/layouts/MRootLayout';
import MBaseNavigation from '@/layouts/MBaseNavigation';
import MBasicLayout from '@/layouts/MBasicLayout';

const {
  pgettext: $pgettext,
} = translate;

Vue.use(Router);

const routes = [
  {
    path: '/',
    meta: {
      requiresAuth: false,
    },
    component: MRootLayout,
    children: [
      {
        path: '/',
        alias: '/login',
        props: ({ query }) => ({
          redirect: query.redirect,
          token: query.token,
        }),
        name: 'login',
        meta: {
          documentTitle: $pgettext('Document title', 'Login'),
          requiresAuth: false,
          isLoginPage: true,
        },
        component: () => import(/* webpackChunkName: "login-page" */ '@/pages/MLoginPage'),
      },

      {
        path: '/register',
        props: ({ query }) => ({
          redirect: query.redirect,
          token: query.token,
        }),
        name: 'register',
        meta: {
          documentTitle: $pgettext('Register page title', 'Register'),
          requiresAuth: false,
        },
        component: () => import(/* webpackChunkName: "register-page" */ '@/pages/onboarding/MRegisterPage'),
      },

      // WARN - THIS IS THE IVITATION FLOW, SHOULD BE RENAMED IN THE FUTURE
      {
        path: '/registration',
        name: 'registration',
        alias: '/accept-invite',
        meta: {
          documentTitle: $pgettext('Document title', 'Registration'),
          requiresAuth: false,
        },
        component: () => import(/* webpackChunkName: "registration-page" */ '@/pages/MRegistrationPage'),
      },

      {
        path: '/reset-password',
        name: 'reset-password',
        props: ({ query }) => ({
          token: query.token,
        }),
        meta: {
          documentTitle: $pgettext('Document title', 'Reset password'),
          requiresAuth: false,
        },
        component: () => import(/* webpackChunkName: "password-reset" */ '@/pages/MPasswordResetPage'),
      },

      {
        path: '/',
        component: MBaseNavigation,
        props: true,
        children: [
          {
            path: '/',
            component: () => import('@/layouts/MTopNavigation'),
            children: [
              {
                path: '/',
                component: () => import('@/layouts/MTopNavigation'),
                children: [
                  {
                    path: 'account',
                    name: 'user-settings',
                    component: () => import(/* webpackChunkName: "user-settings-page" */ '@/pages/MUserSettingsPage'),
                    meta: {
                      documentTitle: $pgettext('Document title', 'Account'),
                    },
                  },

                  {
                    path: 'own-notifications',
                    name: 'own-notifications',
                    component: () => import(/* webpackChunkName: "user-notifications" */ '@/pages/MUserNotificationsPage'),
                    meta: {
                      documentTitle: $pgettext('Document title', 'Own notifications'),
                    },
                  },

                  {
                    path: 'projects',
                    name: 'projects-list',
                    component: () => import('@/pages/MProjectsListPage'),
                    meta: {
                      rememberLocation: true,
                      documentTitle: $pgettext('Document title', 'Projects'),
                    },
                  },
                ],
              },
            ],
          },

          {
            path: 'kayton-seuranta',
            name: 'customer-success',
            meta: {
              documentTitle: $pgettext('Document title', 'Customer success'),
            },
            component: () => import(/* webpackChunkName: "customer-success" */ '@/pages/MCustomerSuccessPage'),
          },
          {
            path: 'admin-companies',
            name: 'admin-companies',
            meta: {
              documentTitle: $pgettext('Document title', 'Admin Companies'),
            },
            component: () => import(/* webpackChunkName: "admin-companies-page" */ '@/pages/admin/MAdminCompaniesPage'),
          },

          {
            path: 'projects/:projectId',
            name: 'single-project',
            redirect: {
              name: 'project-dashboard',
            },
            meta: {
              documentTitle: $pgettext('Document title', 'Projects'),
            },
            props: true,
            component: () => import('@/layouts/MBasicLayoutWithProject'),
            children: [
              {
                path: 'dashboard',
                name: 'project-dashboard',
                meta: {
                  rememberLocation: true,
                  documentTitle: $pgettext('Document title', 'Dashboard'),
                },
                component: () => import('@/pages/project/MProjectDashboardPage'),
              },
              {
                path: 'time-and-space',
                name: 'project-time-and-space',
                meta: {
                  rememberLocation: true,
                  documentTitle: $pgettext('Document title', 'Time and Space'),
                },
                component: () => import('@/views/MSpaceTimeView'),
              },
              {
                path: 'plans',
                meta: {
                  documentTitle: $pgettext('Document title', 'Plans'),
                },
                component: MBasicLayout,
                children: [
                  {
                    path: '/',
                    props: true,
                    name: 'edit-project',
                    redirect: ({ params }) => ({
                      name: 'project-info',
                      params: {
                        versionId: 1,
                        ...params,
                      },
                    }),
                  },

                  {
                    path: ':versionId',
                    redirect: ':versionId/basics',
                    component: () => import(/* webpackChunkName: "project-editor" */ '@/layouts/MProjectEditorLayout'),
                    children: [
                      {
                        path: 'basics',
                        name: 'project-info',
                        meta: {
                          rememberLocation: true,
                          documentTitle: $pgettext('Document title', 'Project basics'),
                        },
                        component: () => import(/* webpackChunkName: "project-editor" */ '@/pages/project/MProjectInfoPage'),
                      },

                      {
                        path: 'passages',
                        name: 'project-passages',
                        meta: {
                          rememberLocation: true,
                          documentTitle: $pgettext('Document title', 'Passages'),
                        },
                        component: () => import(/* webpackChunkName: "project-editor" */ '@/pages/project/MProjectPassagesPage'),
                      },

                      {
                        path: 'time-and-space',
                        name: 'project-space-time',
                        meta: {
                          rememberLocation: true,
                          documentTitle: $pgettext('Document title', 'Time & space'),
                        },
                        component: () => import(/* webpackChunkName: "project-editor" */ '@/pages/project/MProjectSpaceTimeSchedule'),
                      },

                      {
                        path: 'resource-demand',
                        name: 'project-workload',
                        meta: {
                          rememberLocation: true,
                          documentTitle: $pgettext('Document title', 'Resource demand'),
                        },
                        component: () => import(/* webpackChunkName: "project-editor" */ '@/pages/project/MProjectWorkloadSchedule'),
                      },
                    ],
                  },
                ],
              },

              {
                path: 'preconditions',
                props: true,
                name: 'project-preconditions',
                meta: {
                  rememberLocation: true,
                  documentTitle: $pgettext('Document title', 'Preconditions'),
                },
                component: () => import(/* webpackChunkName: "project-preconditions" */ '@/pages/project/MProjectPreconditionsPage'),
              },

              {
                path: 'users',
                name: 'project-users',
                props: route => ({
                  projectId: parseInt(route.params.projectId, 10) || null,
                }),
                meta: {
                  rememberLocation: true,
                  documentTitle: $pgettext('Document title', 'Users'),
                },
                component: () => import(/* webpackChunkName: "users-page" */ '@/pages/MUsersPage'),
              },

              {
                path: 'invite-user/:userId?',
                name: 'invite-user',
                props: route => ({
                  userId: parseInt(route.params.userId, 10) || null,
                }),
                meta: {
                  documentTitle: $pgettext('Document title', 'Invite user'),
                },
                component: () => import(/* webpackChunkName: "invite-user-page" */ '@/components/MInviteUser'),
              },

              {
                path: 'todos',
                redirect: 'todos/own',
                component: MBasicLayout,
                children: [
                  {
                    path: 'own',
                    name: 'own-todos',
                    meta: {
                      rememberLocation: true,
                      documentTitle: $pgettext('Document title', 'My tasks'),
                    },
                    component: () => import('@/pages/todosLists/MOwnTodosPage'),
                  },

                  {
                    path: 'all',
                    name: 'all-todos',
                    meta: {
                      rememberLocation: true,
                      documentTitle: $pgettext('Document title', 'All tasks'),
                    },
                    component: () => import('@/pages/todosLists/MAllTodosPage'),
                  },
                ],
              },

              {
                path: 'status-views',
                redirect: 'status-views/per-takt',
                meta: {
                  rememberLocation: true,
                  documentTitle: $pgettext('Document title', 'Status views'),
                },
                component: MBasicLayout,
                children: [
                  {
                    path: 'per-takt',
                    name: 'takt-status',
                    meta: {
                      rememberLocation: true,
                      documentTitle: $pgettext('Document title', 'Per takt'),
                    },
                    component: () => import(/* webpackChunkName: "takt-status-page" */ '@/pages/statusViews/MTaktStatus'),
                  },

                  {
                    path: 'per-space',
                    name: 'spaces-status',
                    meta: {
                      rememberLocation: true,
                      documentTitle: $pgettext('Document title', 'Per space'),
                    },
                    component: () => import(/* webpackChunkName: "spaces-status-page" */ '@/pages/statusViews/MSpacesStatus'),
                  },

                  {
                    path: 'per-team',
                    name: 'team-trends',
                    meta: {
                      rememberLocation: true,
                      documentTitle: $pgettext('Document title', 'Per team'),
                    },
                    component: () => import(/* webpackChunkName: "team-trends-page" */ '@/pages/statusViews/MTeamTrends'),
                  },
                ],
              },
            ],
          },
        ],
      },

      {
        path: 'projects/:projectId/status-views/per-takt/print',
        name: 'takt-status-print',
        props: route => ({
          _reduceColors: route.query.reduceColors === 'true',
          _showDueTodos: route.query.showDueTodos === 'true',
          _selectedTeamIds: route.query.selectedTeamIds
            ? route.query.selectedTeamIds
              .split(',')
              .map(t => parseInt(t, 10))
            : [],
        }),
        meta: {
          documentTitle: $pgettext('Document title', 'Per takt'),
        },
        component: () => import(/* webpackChunkName: "takt-status-page" */ '@/pages/statusViews/MTaktStatus'),
      },

      {
        path: 'projects/:projectId/confirm-schedule/:cid',
        name: 'confirm-schedule',
        props: true,
        meta: {
          documentTitle: $pgettext('Document title', 'Confirm schedule'),
        },
        component: () => import(/* webpackChunkName: "confirm-schedule-page" */ '@/pages/project/MConfirmSchedule'),
      },

      /**
       * ==== DO NOT REMOVE THESE BEFORE BACKEND HAS BEEN REFACTORED ====
       */
      {
        path: '/rekisteroidy',
        redirect: {
          name: 'registration',
        },
      },
      {
        path: '/nollaa-salasana',
        redirect: {
          name: 'reset-password',
        },
      },
      /**
       * ==== DO NOT REMOVE THESE BEFORE BACKEND HAS BEEN REFACTORED ====
       */

      // PAGE NOT FOUND
      {
        path: '*',
        name: 'not-found',
        meta: {
          documentTitle: $pgettext('Document title', 'Page not found'),
          requiresAuth: false,
        },
        component: () => import(/* webpackChunkName: "not-found-page" */ '@/pages/MNotFoundPage'),
      },
    ],
  },
];

export default new Router({
  mode: 'history',
  routes,
});
