var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":"","disabled":!_vm.showTooltip},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({staticClass:"mb-0",attrs:{"two-line":""}},'v-list-item',attrs,false),Object.assign({}, tooltip, menu)),[(_vm.currentProject.pid)?_c('v-list-item-content',[_c('v-list-item-title',{staticStyle:{"margin-top":"3px"}},[_vm._v(" "+_vm._s(_vm.currentProject.pid)+" ")]),_c('v-list-item-subtitle',{staticClass:"caption-xs text-uppercase"},[_vm._v(" "+_vm._s(_vm.currentProject.address)+" ")]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.companyName))])],1):_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.text))])],1),_c('v-list-item-action',[_c('v-icon',[_vm._v(" keyboard_arrow_down ")])],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.text))])])]}}])},[_c('v-list',{staticClass:"overflow-y-auto",attrs:{"dense":"","subheader":"","max-height":"80vh"}},[_c('v-list-item',{attrs:{"to":{ name: 'projects-list' },"exact":""}},[_c('v-list-item-content',[_c('v-list-item-title',{directives:[{name:"translate",rawName:"v-translate"}]},[_vm._v(" All projects ")])],1),_c('v-list-item-action',[_c('v-icon',[_vm._v("keyboard_arrow_right")])],1)],1),_c('v-divider'),_vm._l((_vm.companies),function(company,i){return [(i > 0)?_c('v-divider',{key:("divider-" + (company.id)),staticClass:"mt-4"}):_vm._e(),_c('v-subheader',{key:company.id,staticClass:"pl-4 mt-4"},[_vm._v(" "+_vm._s(company.name)+" ")]),_vm._l((_vm.projectsByCompanyId[company.id].slice(0, 5)),function(project){return _c('v-list-item',{key:("company-" + (company.id) + "-" + (project.id)),attrs:{"to":{
          name: 'single-project',
          params: {
            projectId: project.id,
          }
        }}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(project.pid)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(project.address)+" ")])],1)],1)})]})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }