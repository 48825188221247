import Vue from 'vue';
import api from '../../utils/api';

const initialState = () => ({
  teamsUsers: [],
});

const getters = {
  teamsUsers: state => state.teamsUsers,
  teamUser: state => ({ team, user }) => state.teamsUsers.find(ta => ta.teamId === team.id
    && ta.userId === user.id),
  isAssignedToTeam: state => ({ team, user }) => state.teamsUsers.some(tu => tu.teamId === team.id
    && tu.userId === user.id),
  assignedToProject: state => userId => state.teamsUsers.some(tu => tu.userId === userId),
  teamsUsersByUser: s => userId => s.teamsUsers.filter(tu => tu.userId === userId),
};

const actions = {
  async loadTeamsUsers({ commit }, { projectId } = { projectId: this.$projectId }) {
    try {
      const r = await api.get(`projects/${(projectId)}/teams_users`);
      commit('SET_TEAMS_USERS', r);
    } catch (e) {}
  },

  async createTeamUser({ commit }, { projectId, payload }) {
    try {
      const r = await api.post(`projects/${projectId}/teams_users`, payload);
      commit('ADD_TO_TEAMS_USERS', r);
    } catch (e) {}
  },

  async destroyTeamUser({ commit }, { projectId, id }) {
    try {
      const r = await api.delete(`projects/${projectId}/teams_users/${id}`);
      commit('DESTROY_TEAM_USER', r);
    } catch (e) {}
  },
};

const mutations = {
  SET_TEAMS_USERS(state, { data }) {
    Vue.set(state, 'teamsUsers', data);
  },

  ADD_TO_TEAMS_USERS(state, { data }) {
    state.teamsUsers.push(data);
  },

  DESTROY_TEAM_USER(state, { data }) {
    const teamsUsers = state.teamsUsers.filter(ta => ta.id !== data.id);
    Vue.set(state, 'teamsUsers', teamsUsers);
  },
};

export default {
  state: initialState(),
  getters,
  actions,
  mutations,
};
