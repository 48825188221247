import Vue from 'vue';
import api from '../../utils/api';
import { urlWithParams } from '@/vuex/utils';

const initialState = () => ({
  checkups: [],
});

const getters = {
  checkups: s => s.checkups,
};

const actions = {
  async loadCheckups({ commit }, { todoId, taskId }) {
    try {
      const url = urlWithParams(`projects/${this.$projectId}/checkups`, { todoId, taskId });
      const r = await api.get(url);
      commit('SET_CHECKUPS', r);
    } catch (e) {}
  },

  async completeCheckup({ commit }, { checkupItemId, todoId }) {
    try {
      const url = urlWithParams(
        `projects/${this.$projectId}/checkups/${checkupItemId}/complete`,
        { todoId },
      );
      const r = await api.post(url);
      commit('UPDATE_CHECKUP', r);
    } catch (e) {}
  },

  async uncompleteCheckup({ commit }, { checkupEntryId }) {
    try {
      const r = await api.delete(`projects/${this.$projectId}/checkups/${checkupEntryId}/uncomplete`);
      commit('UPDATE_CHECKUP', r);
    } catch (e) {}
  },
};

const mutations = {
  SET_CHECKUPS(state, { data }) {
    Vue.set(state, 'checkups', data);
  },

  UPDATE_CHECKUP(state, { data }) {
    const i = state.checkups.findIndex(t => t.checkupItemId === data.checkupItemId);
    if (i < 0) return;
    Vue.set(state.checkups, i, data);
  },
};

export default {
  state: initialState(),
  getters,
  actions,
  mutations,
};
