<template>
  <p
    v-translate="{ projectPid: projectPid }"
    translate-context="MWarningProjectAssignmentsReset component content"
    class="subheading body-2"
  >
    You are about to reset all task assignments in project
    <strong>%{ projectPid }</strong> to state "begin".
    Doing so can significantly affect analytics among other things.
    Are you sure you want to do this?
  </p>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    computed: {
      ...mapGetters({
        dialogProps: 'dialog/dialogProps',
      }),

      projectPid() {
        return this.dialogProps.project.pid;
      },
    },
  };
</script>
