<template>
  <v-btn
    v-if="unreadMessages.length > 0"
    class="shake"
    v-bind="$attrs"
    v-on="$listeners"
    @click="open"
  >
    <v-badge
      :content="unreadMessages.length"
      color="accent"
      :bordered="bordered"
      :overlap="overlap"
    >
      <div
        class="d-flex flex-column justify-center align-center caption-xxs"
      >
        <v-icon
          size="18"
          class="mb-1"
        >
          sms
        </v-icon>

        <span
          v-translate
          translate-context="MNotificationFab component badge"
        >
          Message
        </span>
      </div>
    </v-badge>
  </v-btn>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';

  export default {
    props: {
      overlap: {
        type: Boolean,
        default: false,
      },

      bordered: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      ...mapGetters({
        unreadMessages: 'currentUser/unreadMessages',
        isMobile: 'utils/isMobile',
      }),
    },

    methods: {
      ...mapActions({
        openDialog: 'dialog/openDialog',
      }),

      open() {
        this.openDialog({
          dialogComponent: this.unreadMessages[0],
          dialogProps: {
            messageKey: this.unreadMessages[0],
          },
          config: {
            scrollable: true,
            fullscreen: this.isMobile,
          },
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
@-webkit-keyframes shakes {
  0% { transform: translate(0, 0) rotate(0deg); }
  29% { transform: translate(0, 0) rotate(0deg); }
  30% { transform: translate(2px, 1px) rotate(0deg); }
  31% { transform: translate(-1px, -2px) rotate(-1deg); }
  32% { transform: translate(-3px, 0px) rotate(1deg); }
  33% { transform: translate(0px, 2px) rotate(0deg); }
  34% { transform: translate(1px, -1px) rotate(1deg); }
  35% { transform: translate(-1px, 2px) rotate(-1deg); }
  36% { transform: translate(-3px, 1px) rotate(0deg); }
  37% { transform: translate(2px, 1px) rotate(-1deg); }
  38% { transform: translate(-1px, -1px) rotate(1deg); }
  39% { transform: translate(2px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -2px) rotate(-1deg); }
  41%, 100% { transform: translate(0, 0) rotate(0deg); }
}

.shake {
  animation-name: shakes;
  animation-duration: 4s;
  transform-origin: 50% 50%;
  animation-iteration-count: 5;
  animation-timing-function: linear;
}
</style>
