import Vue from 'vue';
import { translate } from 'vue-gettext';
import api from '../utils/api';
import { urlWithParams } from '@/vuex/utils';

const {
  pgettext: $pgettext,
  gettextInterpolate: $gettextInterpolate,
} = translate;

const initialState = () => ({
  user: {},
  users: [],
  managers: [],
  submanagers: [],
  noses: [],
});

const getters = {
  user: state => state.user,
  users: state => state.users,
  managers: state => state.managers,
  submanagers: state => state.submanagers,
  noses: state => state.noses,
  userById: state => key => state.users[key],
  userFullName: (_, allGetters) => (userId) => {
    if (!userId) {
      return $pgettext("users.js return user's full name", 'Unknown');
    }

    const user = allGetters.userById(userId);

    /**
     * There are a few reasons when a user is not found:
     *
     *    1) User is external to the company (admin, most likely)
     *    2) User is external to the project (they have been removed from the project)
     *    3) User is deleted
     *
     * Technically even deleted users are "external workers" so let's go with that.
     */
    if (!user) {
      return $pgettext("users.js return user's full name", 'External worker');
    }

    return user.fullName;
  },
};

const actions = {
  async loadUser({ commit }, { userId }) {
    try {
      const r = await api.get(`users/${userId}`);
      commit('SET_USER', r);
    } catch (e) {}
  },

  async loadUsers({ commit }, params = {}) {
    const url = urlWithParams('users', params);

    try {
      const r = await api.get(url);

      if (params.scope === 'managers') {
        commit('SET_MANAGERS', r);
      } else if (params.scope === 'submanagers') {
        commit('SET_SUBMANAGERS', r);
      } else if (params.scope === 'noses') {
        commit('SET_NOSES', r);
      } else {
        commit('SET_USERS', r);
      }
    } catch (e) {}
  },

  async createUser({ commit, dispatch }, { companyId, params }) {
    try {
      const r = await api.post(`companies/${companyId}/users`, params);
      commit('ADD_TO_USERS', r);

      return r.data;
    } catch (e) {
      let notification;

      if (e.message && e.message.email) {
        const msg = $gettextInterpolate(
          $pgettext(
            'Snackbar notification',
            '%{ email } already exists',
            ),
          { email: params.user.email },
        );

        notification = { msg, type: 'error' };
      } else {
        notification = {
          msg: $pgettext('Snackbar notification', 'Something is not right'),
          type: 'error',
        };
      }

      await dispatch('snackbar/addNotification', notification, { root: true });
    }

    return false;
  },

  async updateUser({ commit, dispatch }, { id, payload }) {
    try {
      const r = await api.update(`users/${id}`, payload);

      commit('UPDATE_USER', r);

      const notification = {
        msg: $pgettext('Snackbar notification', 'User saved'),
        type: 'success',
      };

      await dispatch('snackbar/addNotification', notification, { root: true });

      return r.data;
    } catch (e) {
      const notification = {
        msg: $pgettext('Snackbar notification', 'Something is not right'),
        type: 'error',
      };

      await dispatch('snackbar/addNotification', notification, { root: true });
    }

    return false;
  },

  async destroyUser({ commit, dispatch }, { id }) {
    try {
      const r = await api.delete(`users/${id}`);

      commit('DESTROY_USER', r);

      const notification = {
        msg: $pgettext('Snackbar notification', 'User deleted'),
        type: 'success',
      };

      await dispatch('snackbar/addNotification', notification, { root: true });
    } catch (e) {}
  },

  async sendInvitationLinks(context, { userId, payload }) {
    try {
      await api.update(`users/${userId}/send_registration_notification`, payload);
    } catch (e) {}
  },
};

const mutations = {
  SET_USER(state, { data }) {
    Vue.set(state, 'user', data);
  },

  SET_USERS(state, { data }) {
    Vue.set(state, 'users', data);
  },

  SET_MANAGERS(state, { data }) {
    Vue.set(state, 'managers', data);
  },

  SET_SUBMANAGERS(state, { data }) {
    Vue.set(state, 'submanagers', data);
  },

  SET_NOSES(state, { data }) {
    Vue.set(state, 'noses', data);
  },

  ADD_TO_USERS(state, { data }) {
    state.users.push(data);
  },

  UPDATE_USER(state, { data }) {
    const users = state.users.map((u) => { // eslint-disable-line
      return u.id === data.id ? data : u;
    });

    Vue.set(state, 'users', users);
  },

  DESTROY_USER(state, { data }) {
    const users = state.users.filter(u => u.id !== data.id);
    Vue.set(state, 'users', users);
  },
};

export default {
  state: initialState(),
  getters,
  actions,
  mutations,
};
