<template>
  <v-dialog
    v-model="showDialog"
    content-class="dialog-container"
    :max-width="maxWidth"
    :scrollable="scrollable"
    :fullscreen="fullscreen"
    persistent
    @keydown.esc="closeDialog"
  >
    <component :is="dialogComponent" />
  </v-dialog>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';

  export default {
    computed: {
      ...mapGetters({
        dialogComponent: 'dialog/dialogComponent',
        showDialog: 'dialog/showDialog',
        maxWidth: 'dialog/maxWidth',
        scrollable: 'dialog/scrollable',
        fullscreen: 'dialog/fullscreen',
      }),
    },

    watch: {
      dialogComponent(dialogComponent) {
        if (!dialogComponent) return;

        /**
         * Attempts to hack an autofocus logic so that the client
         * code does not need to setup it again and again.
         */
        this.$nextTick(() => {
          setTimeout(() => {
            const firstInput = document
              .querySelector('.dialog-container input:first-of-type, .dialog-container textarea:first-of-type');
            if (firstInput) firstInput.focus();
          });
        });
      },
    },

    methods: {
      ...mapActions({
        closeDialog: 'dialog/closeDialog',
      }),
    },
  };
</script>
