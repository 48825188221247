import Vue from 'vue';
import { translate } from 'vue-gettext';
import api from '@/vuex/utils/api';

const { pgettext: $pgettext } = translate;

const initialState = () => ({
  scheduleConfirms: [],
  scheduleConfirm: null,
  scheduleConfirmHistory: [],
});

const getters = {
  scheduleConfirms: state => state.scheduleConfirms,
  scheduleConfirm: s => s.scheduleConfirm,
  scheduleConfirmHistory: s => s.scheduleConfirmHistory,
};

const actions = {
  async loadScheduleConfirms({ commit }) {
    try {
      const r = await api.get(`projects/${this.$projectId}/schedule_confirms`);
      commit('SET_SCHEDULE_CONFIRMS', r);
    } catch (e) {}
  },

  async loadScheduleConfirmHistoryByTeam({ commit }, { teamId }) {
    try {
      const r = await api.get(`projects/${this.$projectId}/schedule_confirms/history_by_team?team_id=${teamId}`);
      commit('SET_SCHEDULE_CONFIRM_HISTORY', r);
    } catch (e) {}
  },

  async createScheduleConfirm({ commit }, payload) {
    try {
      const r = await api.post(`projects/${this.$projectId}/schedule_confirms`, payload);
      commit('ADD_TO_SCHEDULE_CONFIRMS', r);
    } catch (e) {}
  },

  // async batchCreateScheduleConfirms() {
  //   try {
  //     await api.post(`projects/${this.$projectId}/schedule_confirms/batch_create`);
  //   } catch (e) {}
  // },

  async updateScheduleConfirm({ commit }, { id, payload }) {
    try {
      const r = await api.update(`projects/${this.$projectId}/schedule_confirms/${id}`, payload);
      commit('UPDATE_SCHEDULE_CONFIRM', r);
    } catch (e) {}
  },

  async destroyScheduleConfirm({ commit }, { id }) {
    try {
      const r = await api.delete(`projects/${this.$projectId}/schedule_confirms/${id}`);
      commit('DESTROY_SCHEDULE_CONFIRMS', r);
    } catch (e) {}
  },

  async confirmSchedule({ commit }, { cid }) {
    const url = `projects/${this.$projectId}/schedule_confirms/confirm_schedule/${cid}`;
    try {
      const r = await api.post(url);
      commit('SET_SCHEDULE_CONFIRM', r);
      return true;
    } catch (e) {
      return false;
    }
  },

  async mailScheduleToManager({ dispatch }, { scheduleConfirmId }) {
    const url = `projects/${this.$projectId}/schedule_confirms/${scheduleConfirmId}/mail_schedule_to_subcontractor`;
    try {
      await api.get(url);
      await dispatch(
        'snackbar/addNotification',
        {
          msg: $pgettext('Snackbar notification', 'Schedule sent'),
          type: 'success',
        },
        { root: true },
      );
    } catch (e) {
      await dispatch(
        'snackbar/addNotification',
        {
          msg: $pgettext('Snackbar notification', 'Error sending the schedule'),
          type: 'error',
        },
        { root: true },
      );
    }
  },
};

const mutations = {
  SET_SCHEDULE_CONFIRMS(state, { data }) {
    Vue.set(state, 'scheduleConfirms', data);
  },

  SET_SCHEDULE_CONFIRM(state, { data }) {
    Vue.set(state, 'scheduleConfirm', data);
  },

  SET_SCHEDULE_CONFIRM_HISTORY(state, { data }) {
    Vue.set(state, 'scheduleConfirmHistory', data);
  },

  UPDATE_SCHEDULE_CONFIRM(state, { data }) {
    const scheduleConfirms = state.scheduleConfirms.map(sc => (
      sc.id === data.id ? data : sc
    ));

    Vue.set(state, 'scheduleConfirms', scheduleConfirms);
  },

  ADD_TO_SCHEDULE_CONFIRMS(state, { data }) {
    state.scheduleConfirms.push(data);
  },

  DESTROY_SCHEDULE_CONFIRMS(state, { data }) {
    const index = state.scheduleConfirms.findIndex(sc => sc.id === data.id);

    if (index < 0) return;

    state.scheduleConfirms.splice(index, 1);
  },
};

export default {
  state: initialState(),
  getters,
  actions,
  mutations,
};
