import Vue from 'vue';
import api from '../../utils/api';
import { urlWithParams } from '../../utils';

const initialState = () => ({
  avgTaskCompletionTimes: [],
  teamsPerformance: {
    meta: {},
    teams: [],
  },
  activityLogs: {
    userActivities: [],
    totalActivities: [],
    dayActivityByUser: {},
    companyWeeklyAktivityPerDay: {},
    totalWeeklyActivityPerDay: [],
  },
});

const getters = {
  avgTaskCompletionTimes: state => state.avgTaskCompletionTimes,
  teamsPerformance: state => state.teamsPerformance,
  userActivities: state => state.activityLogs.userActivities,
  totalActivities: state => state.activityLogs.totalActivities,
  dayActivityByUser: state => state.activityLogs.dayActivityByUser,
  companyWeeklyAktivityPerDay: state => state.activityLogs.companyWeeklyAktivityPerDay,
  totalWeeklyActivityPerDay: state => state.activityLogs.totalWeeklyActivityPerDay,
};

const actions = {
  async loadAvgTaskCompletionTimes({ commit }) {
    try {
      const r = await api.get(`projects/${this.$projectId}/avg_task_completion_times`);

      commit('SET_AVG_TASK_COMPLETION_TIMES', r);
    } catch (e) {} // eslint-disable-line
  },

  async loadTeamsPerformance({ commit }, params = {}) {
    try {
      const url = urlWithParams(`projects/${this.$projectId}/teams_performance`, params);

      const r = await api.get(url);

      commit('SET_TEAMS_PERFORMANCE', r);
    } catch (e) {} // eslint-disable-line}
  },

  async loadActivityLogs({ commit }) {
    try {
      const r = await api.get('activity_logs');

      commit('SET_ACTIVITY_LOGS', r);
    } catch (e) {} // eslint-disable-line}
  },
};

const mutations = {
  SET_AVG_TASK_COMPLETION_TIMES(state, { data }) {
    Vue.set(state, 'avgTaskCompletionTimes', data);
  },

  SET_TEAMS_PERFORMANCE(state, { data }) {
    Vue.set(state, 'teamsPerformance', data);
  },

  SET_ACTIVITY_LOGS(state, { data }) {
    Vue.set(state, 'activityLogs', data);
  },
};

export default {
  state: initialState(),
  getters,
  actions,
  mutations,
};
