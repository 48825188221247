/**
 * This snackbar module is FIFO-based (first-in — first-out).
 */

import Vue from 'vue';
import { translate } from 'vue-gettext';

const { pgettext: $pgettext } = translate;

const initialState = () => ({
  queue: [],
  currentNotification: {},
});

const getters = {
  currentNotification: state => state.currentNotification,
  queue: state => state.queue,
  showNotification: (_, allGetters) => Object.keys(allGetters.currentNotification).length > 0,
  hasNotificationsInQueue: (_, allGetters) => allGetters.queue.length > 0,
};

const actions = {
  addNotification({ dispatch }, payload) {
    dispatch('addSnackbarNotification', payload);
  },

  removeCurrentNotification({ dispatch }) {
    dispatch('removeSnackbarNotification');
  },

  async addSnackbarNotification({ commit, dispatch, getters: allGetters }, payload) {
    if (allGetters.hasNotificationsInQueue) {
      await dispatch('removeSnackbarNotification');
    }

    commit('ADD_NOTIFICATION_TO_QUEUE', payload);
    commit('SHOW_NEXT_NOTIFICATION');
  },

  showGeneralError({ dispatch }) {
    dispatch(
      'addSnackbarNotification',
      {
        header: $pgettext('Snackbar notification', 'Something went wrong'),
        msg: $pgettext(
          'General error notification',
          'Please try again. If the error persists you can check the service status at <a href="status.mestamaster.com">status.mestamaster.com</a> or contact support at: <a href="mailto:support@mestamaster.fi">support@mestamaster.fi</a>'
        ),
        type: 'error',
        htmlSafe: 'true'
      },
    );
  },

  showForbiddenError({ dispatch }) {
    dispatch(
      'addSnackbarNotification',
      {
        header: $pgettext('Forbidden', 'You do not have permission to do that'),
        msg: $pgettext('Forbidden error message', 'You do not have the necessary permissions to do this action. If you think this is a mistake please contact your supervisor.'),
        type: 'error',
      },
    );
  },

  removeSnackbarNotification({ commit }) {
    commit('REMOVE_CURRENT_NOTIFICATION');
  },
};

const mutations = {
  SHOW_NEXT_NOTIFICATION(state) {
    Vue.set(state, 'currentNotification', state.queue[0]);
  },

  ADD_NOTIFICATION_TO_QUEUE(state, payload) {
    state.queue.push(payload);
  },

  REMOVE_CURRENT_NOTIFICATION(state) {
    Vue.set(state, 'currentNotification', initialState().currentNotification);
    state.queue.splice(0, 1);
  },
};

export default {
  state: initialState(),
  getters,
  actions,
  mutations,
};
