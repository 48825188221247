<template>
  <v-navigation-drawer
    :mini-variant="miniVariant"
    :value="drawer"
    app
    touchless
    v-bind="$attrs"
    v-on="$listeners"
    color="backgroundAccent"
  >
    <v-list
      dense
      class="py-0"
    >
      <m-project-selector-menu
        :project-id="projectId"
        :projects="projects"
        :show-tooltip="miniVariant"
      />
      <template
        v-if="projectId"
      >
        <span
          v-for="(navItem, i) in navItems"
          :key="i"
        >
          <template
            v-if="navItem.children().length > 0"
          >
            <v-subheader
              v-if="navItem.title"
            >
              {{ navItem.title }}
            </v-subheader>

            <template
              v-for="(item, k) in navItem.children()"
            >
              <v-tooltip
                :key="`${i}-${k}`"
                top
                :disabled="!miniVariant"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item
                    :to="item.to"
                    :exact="item.to.exact"
                    v-bind="attrs"
                    :disabled="item.disabled"
                    v-on="on"
                  >
                    <v-list-item-icon>
                      <v-icon>
                        {{ item.icon }}
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.title }}
                      </v-list-item-title>

                      <v-list-item-subtitle
                        v-if="item.subtitle"
                      >
                        {{ item.subtitle }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </template>

                <span>
                  {{ item.title }}
                </span>
              </v-tooltip>
            </template>
          </template>
        </span>
      </template>

      <div
        v-else
      >
        <v-subheader
          class="mt-2"
        >
          <span
            v-translate
            translate-context="MNavigationDrawer subtitle"
          >
            Management
          </span>
        </v-subheader>

        <v-list-item
          exact
          :to="{
            name: 'projects-list',
          }"
        >
          <v-list-item-icon>
            <v-icon>
              face
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>
              <span
                v-translate
                translate-context="MNavigationDrawer item title"
              >
                Projects
              </span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="$currentUser.isAdmin"
          exact
          :to="{
            name: 'admin-companies',
          }"
        >
          <v-list-item-icon>
            <v-icon>
              business
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>
              <span
                v-translate
                translate-context="MNavigationDrawer item title"
              >
                Companies
              </span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <template
          v-if="$currentUser.enabledFeatures.projectSuccess"
        >
          <v-subheader
            class="mt-2"
          >
            <span
              v-translate
              translate-context="MNavigationDrawer subtitle"
            >
              Analytics
            </span>
          </v-subheader>

          <v-list-item
            exact
            two-line
            :to="{
              name: 'customer-success',
            }"
          >
            <v-list-item-icon>
              <v-icon>
                insights
              </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>
                <span
                  v-translate
                  translate-context="MNavigationDrawer link title"
                >
                  Projects' analytics
                </span>
              </v-list-item-title>
              <v-list-item-subtitle>
                <span
                  v-translate
                  translate-context="MNavigationDrawer link subtitle"
                >
                  How projects are doing?
                </span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
      </div>
    </v-list>

    <template slot="append">
      <v-divider />

      <v-list-item
            exact
            target="_blank"
            :href="$pgettext('MNavigationDrawer Google Forms feedback link', 'https://docs.google.com/forms/d/e/1FAIpQLSduaE3QUw7-pc8woREZ1Am3u69ncf69CeLosaft4mZmE3iZvw/viewform')"

          >
            <v-list-item-icon>
              <v-icon>
                feedback
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                <span
                v-translate
                translate-context="MNavigationDrawer link title to feedback button"
                >
                Send feedback
                </span>
              </v-list-item-title>
              <v-list-item-subtitle>
                <span
                  v-translate
                  translate-context="MNavigationDrawer link subtitle to feedback button"
                >
                  Tell us how to improve!
                </span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
      <div>
        <v-divider />

        <v-menu
          offset-y
          top
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              width="100%"
              height="100%"
              class="py-2"
              text
              tile
              v-on="on"
            >
              <v-badge
                :value="unseenLogs.length > 0
                && currentUser.permissions.includes('write_notification_logs')"
                top
                right
                color="deep-orange"
                :content="unseenLogs.length"
                offset-x="12"
                offset-y="12"
              >
                <v-icon>
                  face
                </v-icon>
              </v-badge>

              <div
                class="ml-4 mr-3 text-left"
              >
                <div>
                  {{ currentUser.firstName }}
                </div>

                <div
                  class="caption-xs backgroundAccent--text"
                >
                  {{ currentUser.humanReadableRole }}
                </div>
              </div>

              <v-icon>
                keyboard_arrow_up
              </v-icon>
            </v-btn>
          </template>

          <v-list
            dense
            class="py-4"
          >
            <v-list-item
              :to="{
              name: 'user-settings',
            }"
            >
              <v-list-item-icon>
                <v-icon>
                  face
                </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title
                  v-translate
                  translate-context="Navigation drawer item title"
                >
                  Account
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              v-if="currentUser.permissions.includes('write_notification_logs')"
              :to="{
              name: 'own-notifications',
            }"
            >
              <v-list-item-icon>
                <v-badge
                  :value="unseenLogs.length > 0"
                  top
                  right
                  color="deep-orange"
                  :content="unseenLogs.length"
                  offset-x="12"
                  offset-y="12"
                >
                  <v-icon>
                    announcement
                  </v-icon>
                </v-badge>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title
                  v-translate
                  translate-context="Navigation drawer item title"
                >
                  My notifications
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-divider
              class="my-4"
            />

            <v-list-item
              :disabled="$wait.is('logging out')"
              color="error"
              @click="onLogout"
            >
              <v-list-item-icon>
                <v-icon
                  color="error"
                >
                  logout
                </v-icon>
              </v-list-item-icon>

              <v-list-item-content
                class="error--text"
              >
                <v-list-item-title
                  v-translate
                  translate-context="Navigation drawer item title"
                >
                  Sign out
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapWaitingActions } from 'vue-wait';
import MProjectSelectorMenu from '@/components/MProjectSelectorMenu';

export default {
  components: {
    MProjectSelectorMenu,
  },

  props: {
    projectId: {
      type: [Number, String],
      default: null,
    },

    projects: {
      type: Array,
      required: true,
    },

    navItems: {
      type: Array,
      required: true,
    },

    drawer: {
      type: Boolean,
      default: null,
    },

    miniVariant: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters({
      unseenLogs: 'notificationLogs/unseenLogs',
      currentUser: 'currentUser/user',
    }),
  },

  created() {
    this.loadNotificationLogs();
  },

  methods: {
    ...mapWaitingActions('currentUser', {
      logout: 'logging out',
    }),

    ...mapWaitingActions('notificationLogs', {
      loadNotificationLogs: 'loading logs',
    }),

    async onLogout() {
      await this.logout();
      await this.$router.replace({
          name: 'login',
      });
    },
  },
};
</script>
