<template>
  <v-card
    tile
    class="backgroundAccent lighten-4 d-flex flex-column"
  >
    <v-card-title
      class="title lighten-4 white"
    >
      <span
        v-translate
        translate-context="MWarningPreviousTodoNotCompleted component title"
      >
        Preceding tasks not completed
      </span>

      <v-spacer />

      <v-btn
        :disabled="$wait.is('processing')"
        icon
        @click="closeDialog"
      >
        <v-icon>
          close
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-divider />

    <v-card-subtitle
      class="white pr-16 pt-4"
    >
      <span
        v-translate="{ address: spaceAddress }"
        translate-context="MWarningPreviousTodoNotCompleted component content"
      >
        According to Mestamaster, there are preceding tasks in <code>%{ address }</code>
        that have not yet been done.
      </span>
    </v-card-subtitle>

    <v-divider />

    <v-card-text>
      <v-subheader>
        <span
          v-translate
          translate-context="MWarningPreviousTodoNotCompleted component content"
        >
          Incomplete preceding tasks
        </span>
      </v-subheader>

      <m-loader-circular
        v-if="$wait.is('loading data')"
        style="margin: 12px 0 !important;"
        text=""
        :size="24"
        :width="2"
      />

      <v-list
        v-else
        dense
        class="py-0"
        elevation="1"
        two-line
      >
        <template
          v-for="(earlierTodo, i) in earlierTodos"
        >
          <v-list-item
            :key="i"
          >
            <v-list-item-content>
              <v-list-item-title>
                {{ earlierTodo.taskName }}
              </v-list-item-title>

              <v-list-item-subtitle>
                {{ earlierTodo.teamName }}

                &#x2022;

                {{ earlierTodo.asssignmentHumanReadableState }}

                <template
                  v-if="earlierTodo.assigneeFullName"
                >
                  <span>
                    &#x2022;
                    {{ earlierTodo.assigneeFullName }}
                  </span>
                </template>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-divider
            v-if="i < earlierTodos.length - 1"
            :key="`${i}-divider`"
          />
        </template>
      </v-list>
    </v-card-text>

    <v-spacer />

    <v-card-actions
      class="white elevation-6 flex-column"
      style="z-index: 1;"
    >
      <v-alert
        type="warning"
        dense
        text
        class="mb-0 mt-4"
      >
        <span
          v-translate
          translate-context="MWarningPreviousTodoNotCompleted component content"
        >
          Ask your foreman if you can begin your task.
        </span>
      </v-alert>

      <v-checkbox
        v-model="confirmed"
        :disabled="$wait.is('processing')"
        :label="checkboxLabel"
        :hint="checkboxHint"
        persistent-hint
      />

      <div
        class="mt-6 mb-5"
      >
        <v-btn
          :disabled="$wait.is('processing')"
          text
          color="error"
          class="mr-4 ml-n4"
          @click="closeDialog"
        >
          <span
            v-translate
            translate-context="MWarningPreviousTodoNotCompleted component cancel button"
          >
            Cancel
          </span>
        </v-btn>

        <v-btn
          :disabled="!confirmed || $wait.is('processing')"
          :loading="$wait.is('processing')"
          color="success"
          class="px-10"
          @click="confirm"
        >
          <span
            v-translate
            translate-context="MWarningPreviousTodoNotCompleted component begin task button"
          >
            Begin task
          </span>
        </v-btn>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import api from '@/vuex/utils/api';
  import { toSnakeCase } from '@/vuex/utils/helpers';

  export default {
    data: () => ({
      earlierTodos: [],
      confirmed: false,
    }),

    computed: {
      ...mapGetters({
        dialogProps: 'dialog/dialogProps',
      }),

      /**
       * This dialog opens only if earlierTodos has at least
       * one item so we can safely assume that the [0] works.
       */
      spaceAddress() {
        return this.todo.spaceAddress;
      },

      todo() {
        return this.dialogProps.todo;
      },

      onConfirm() {
        return this.dialogProps.onConfirm;
      },

      checkboxLabel() {
        return this.$pgettext('MWarningPreviousTodoNotCompleted component checkbox label', 'I can begin my task');
      },

      checkboxHint() {
        return this.$pgettext('MWarningPreviousTodoNotCompleted component checkbox hint', 'Confirm that you have been granted permission to begin');
      },
    },

    mounted() {
      this.loadEarlierTodos();
    },

    methods: {
      ...mapActions({
        closeDialog: 'dialog/closeDialog',
      }),

      /**
       * Loads todos directly since Vuex's loadTodos() would replace existing data that is behind
       * this dialog.
       */
      async loadEarlierTodos() {
        this.$wait.start('loading data');

        const params = {
          projectId: this.$projectId,
          spaceId: this.todo.spaceId,
          assignmentNotInState: 'completed',
        };

        const args = Object.keys(params).reduce((acc, key) => (
          `${acc}&${toSnakeCase(key)}=${params[key]}`
        ), '');

        const response = await api.get(`projects/${this.$projectId}/get_todos?${args}`);
        const { data: earlierTodos } = response;

        /**
         * Finds todos that are to be done BEFORE this todo. Since todos are in the right order,
         * we can take a subset [0, <this.todo.assignmentId>].
         */
        const i = earlierTodos.findIndex(todo => todo.assignmentId === this.todo.assignmentId);
        this.earlierTodos = earlierTodos.slice(0, i);

        this.$wait.end('loading data');
      },

      async confirm() {
        this.$wait.start('processing');

        await this.onConfirm();

        this.$wait.end('processing');
        this.closeDialog();
      },
    },
  };
</script>
