<template>
  <div
    class="d-flex align-center"
  >
    <div>
      <v-chip
        color="secondary darken-1"
        small
        :class="chipClasses"
      >
        {{ computedChipText }}
      </v-chip>
    </div>

    <p
      class="caption mb-0 ml-2 font-italic"
      :class="textClasses"
    >
      {{ tip }}
    </p>
  </div>
</template>

<script>
  export default {
    props: {
      tip: {
        type: String,
        required: true,
      },

      chipText: {
        type: String,
        default: null,
      },

      chipClasses: {
        type: String,
        default: 'font-weight-bold',
      },

      textClasses: {
        type: String,
        default: 'backgroundAccent--text text--darken-2',
      },
    },

    computed: {
      computedChipText() {
        if (!this.chipText) {
          return this.$pgettext('MTip component default chip text', 'Tip');
        }

        return this.chipText;
      },
    },
  };
</script>
