<template>
  <div
    class="full-width d-flex flex-column justify-center align-center pt-12 caption"
  >
    <span
      class="d-block subtitle-1"
    >
      {{ title }}
    </span>

    <m-tip
      class="mt-4"
      :tip="tip"
    />
  </div>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        required: true,
      },

      tip: {
        type: String,
        required: true,
      },
    },
  };
</script>
