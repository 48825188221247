<template>
  <v-tooltip
    content-class="pa-0"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        small
        v-bind="attrs"
        :class="iconClass"
        v-on="on"
      >
        {{ icon }}
      </v-icon>
    </template>

    <v-card
      dark
      :max-width="maxWidth"
    >
      <slot />
    </v-card>
  </v-tooltip>
</template>

<script>
  export default {
    props: {
      icon: {
        type: String,
        default: 'help',
      },

      iconClass: {
        type: String,
        default: '',
      },

      maxWidth: {
        type: String,
        default: '300px',
      },
    },
  };
</script>
