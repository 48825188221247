import Vue from 'vue';
import { translate } from 'vue-gettext';
import api from '../utils/api';

const {
  pgettext: $pgettext,
  gettextInterpolate: $gettextInterpolate,
} = translate;

const initialState = () => ({
  companies: [],
});

const getters = {
  companies: s => s.companies,
  companyById: (_, allGetters) => {
    const { companies } = allGetters;
    const companiesById = companies.reduce((acc, company) => ({
      ...acc,
      [company.id]: company,
    }), {});
    return companiesById;
  },
  companiesWithUsers: (s, g, allGetters) => {
    const { users } = allGetters.users;
    const { companies } = g;

    const companiesWithUsers = companies.map((company) => {
      const companyUsers = users.reduce((acc, user) => {
        const belongs = user.employments.some(e => e.companyId === company.id);
        if (belongs) acc.push(user);
        return acc;
      }, []);

      return {
        ...company,
        usersCount: companyUsers.length,
        users: companyUsers,
      };
    });

    return companiesWithUsers;
  },
};

const actions = {
  async loadCompanies({ commit }) {
    try {
      const r = await api.get('companies');
      commit('SET_COMPANIES', r);
    } catch (e) {}
  },

  async createCompany({ commit, dispatch }, { payload }) {
    try {
      const r = await api.post('companies', payload);

      commit('ADD_TO_COMPANIES', r);

      const notification = {
        msg: $pgettext('Snackbar notification', 'Company created'),
        type: 'success',
      };
      await dispatch('snackbar/addNotification', notification, { root: true });
    } catch (e) {
      let msg;

      if (e.message && e.message.name) {
        msg = $gettextInterpolate(
          $pgettext(
            'Snackbar notification',
            '%{ companyName } already exists',
          ),
          { companyName: payload.company.name },
        );
      } else {
        msg = $pgettext('Snackbar notification', 'Something is not right');
      }

      const notification = { msg, type: 'error' };
      await dispatch('snackbar/addNotification', notification, { root: true });
    }

    return false;
  },

  async updateCompany({ commit, dispatch }, { companyId, payload }) {
    try {
      const r = await api.update(`companies/${companyId}`, payload);

      commit('UPDATE_COMPANY', r);

      const notification = {
        msg: $pgettext('Snackbar notification', 'Company saved'),
        type: 'success',
      };

      await dispatch('snackbar/addNotification', notification, { root: true });
    } catch (e) {
      const notification = {
        msg: $pgettext('Snackbar notification', 'Something is not right'),
        type: 'error',
      };

      await dispatch('snackbar/addNotification', notification, { root: true });
    }
  },

  // async destroyCompany({ commit, dispatch }, { companyId }) {
  //   try {
  //     const r = await api.delete(`companies/${companyId}`);
  //
  //     commit('DESTROY_COMPANY', r);
  //
  //     const notification = {
  //       msg: $pgettext('Snackbar notification', 'Company deleted'),
  //       type: 'success',
  //     };
  //
  //     await dispatch('snackbar/addNotification', notification, { root: true });
  //   } catch (e) {
  //     const notification = {
  //       msg: $pgettext('Snackbar notification', 'Something is not right'),
  //       type: 'error',
  //     };
  //
  //     await dispatch('snackbar/addNotification', notification, { root: true });
  //   }
  // },

  async addEmployees(context, { companyId, payload }) {
    try {
      await api.post(`companies/${companyId}/add_employees`, payload);
      return true;
    } catch (e) {
      return false;
    }
  },

  async dropEmployees(context, { companyId, payload }) {
    try {
      await api.delete(`companies/${companyId}/drop_employees`, payload);
      return true;
    } catch (e) {
      return false;
    }
  },
};

const mutations = {
  SET_COMPANIES(state, { data }) {
    Vue.set(state, 'companies', data);
  },

  ADD_TO_COMPANIES(state, { data }) {
    state.companies.push(data);
  },

  UPDATE_COMPANY(state, { data }) {
    const companies = state.companies.map((c) => { // eslint-disable-line
      return c.id === data.id ? data : c;
    });

    Vue.set(state, 'companies', companies);
  },

  // DESTROY_COMPANY(state, { data }) {
  //   const companies = state.companies.filter(c => c.id !== data.id);
  //   Vue.set(state, 'companies', companies);
  // },
};

export default {
  state: initialState(),
  getters,
  actions,
  mutations,
};
