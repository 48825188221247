import { getCookie, removeCookie, setCookie } from './cookie';
import { toSnakeCase } from '@/vuex/utils/helpers';

const ACCESS_TOKEN = 'accessToken';

export function getAccessToken() {
  return getCookie(ACCESS_TOKEN);
}

export function setAccessToken(token) {
  return setCookie(ACCESS_TOKEN, token);
}

export function removeAccessToken() {
  return removeCookie(ACCESS_TOKEN);
}

export function urlWithParams(url, params = {}) {
  const args = Object.keys(params).reduce((acc, key) => (
    `${acc}&${toSnakeCase(key)}=${params[key]}`
  ), '');

  if (args.length <= 0) return url;

  return `${url}?${args}`;
}
