import Vue from 'vue';
import api from '../../utils/api';

const initialState = () => ({
  spaces: [],
  selectedSpace: {},
});

const getters = {
  spaces: state => state.spaces,
  selectedSpace: state => state.selectedSpace,
  spacesById: (state) => {
    const grouped = state.spaces.reduce((acc, space) => {
      acc[space.id] = space;
      return acc;
    }, {});
    return grouped;
  },
};

const actions = {
  loadSpaces({ commit }, projectId = null) {
    const id = projectId || this.$projectId;

    return api.get(`projects/${id}/spaces`)
      .then(response => commit('SET_SPACES', response))
      .catch(() => {});
  },

  selectSpace({ commit }, { space }) {
    commit('SELECT_SPACE', { space });
  },
};

const mutations = {
  SET_SPACES(state, { data }) {
    Vue.set(state, 'spaces', data);
  },

  SELECT_SPACE(state, { space }) {
    const { selectedSpace } = state;

    // Acts like a toggle
    if (selectedSpace.id === space.id) {
      Vue.set(state, 'selectedSpace', initialState().selectedSpace);

      return;
    }

    Vue.set(state, 'selectedSpace', space);
  },
};

export default {
  state: initialState(),
  getters,
  actions,
  mutations,
};
